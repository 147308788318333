import PropTypes from 'prop-types'

export const SeasonTagType = PropTypes.objectOf(PropTypes.string)
export const CurrentType = PropTypes.shape({
  season: PropTypes.string.isRequired,
  episode: PropTypes.string.isRequired
})
const EpisodeType = PropTypes.shape({
  episodeNumber: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  seasonNumber: PropTypes.string.isRequired,
  summary: PropTypes.string,
  images: PropTypes.shape({
    uri: PropTypes.string.isRequired
  }).isRequired
})

export const SeasonType = PropTypes.objectOf(EpisodeType)

export const SeasonsType = PropTypes.shape({
  current: CurrentType.isRequired,
  seasonTag: SeasonTagType.isRequired,
  seasons: PropTypes.objectOf(SeasonType).isRequired
})
