import React from 'react'
import PropTypes from 'prop-types'

import { ControlConsumer } from '../../../control'
import TextTrackToggleUi from './text-track-toggle-ui'
import CheckIfTextTrackExists from './check-if-text-track-exists'

const TextTrackToggle = ({
  isTextTrackShown,
  saveCloseCaption
}) => {
  return (
    <ControlConsumer>
      {({ getTextTrackFromPlayer }) => {
        return (
          <CheckIfTextTrackExists
            getTextTrackFromPlayer={getTextTrackFromPlayer}
          >
            {textTracks => {
              return (
                <TextTrackToggleUi
                  getTextTrackFromPlayer={getTextTrackFromPlayer}
                  isTextTrackShown={isTextTrackShown}
                  saveCloseCaption={saveCloseCaption}
                  textTracks={textTracks}
                />
              )
            }}
          </CheckIfTextTrackExists>
        )
      }}
    </ControlConsumer>
  )
}

TextTrackToggle.propTypes = {
  isTextTrackShown: PropTypes.bool.isRequired,
  saveCloseCaption: PropTypes.func.isRequired
}

export default TextTrackToggle
