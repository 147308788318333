import React from 'react'
import withStyles from 'react-jss'

import { StateConsumer } from '../state'
import { ControlConsumer } from '../control'
import { shouldShowSkipIntro } from './utils/should-show-skip-intro'
import { getSkipIntroEndTime } from './utils/get-skip-intro-end-time'

const styles = theme => {
  return {
    wrapper: {
      bottom: 85,
      left: 0,
      marginLeft: 25,
      padding: [10, 20],
      position: 'absolute',
      flexDirection: 'column',
      color: theme.color.black,
      backgroundColor: theme.color.white,
      borderRadius: 4,
      cursor: 'pointer',
      zIndex: theme.zIndex.overlay,

      '&:hover': {
        backgroundColor: theme.color.primary
      }
    }
  }
}

class SkipIntro extends React.PureComponent {
  render() {
    const { classes } = this.props

    return (
      <StateConsumer>
        {({
          isPlayingAd,
          currentTime,
          getMetaData,
          getSetters,
          isUiHidden,
          isInitialLoadingDone,
          isAdOnPauseShown,
          isEpisodeSelectorOpen
        }) => {
          // render nothing
          // when initial loading is not done or ad is playing or AoP is showing or episode selector opens
          if (!isInitialLoadingDone || isPlayingAd || isAdOnPauseShown || isEpisodeSelectorOpen) return null

          const shouldRenderSkipIntro = shouldShowSkipIntro(
            getMetaData,
            currentTime,
            isUiHidden
          )

          if (!shouldRenderSkipIntro) return null

          return (
            <ControlConsumer>
              {({ seekTo }) => {
                const { setCurrentTime } = getSetters()
                const skipIntroEndTime = getSkipIntroEndTime(getMetaData)

                return (
                  <div
                    className={classes.wrapper}
                    onClick={() => {
                      seekTo(skipIntroEndTime)
                      setCurrentTime(skipIntroEndTime)
                    }}
                  >
                    SKIP INTRO
                  </div>
                )
              }}
            </ControlConsumer>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(SkipIntro)
