import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'react-jss'

import { TilePropTypes } from '../prop-types'
import TileBadge from '../../../../../shared/badge'
import Imgix from '../../../../../imgix'
import { getOverlayComponent } from './utils/get-overlay-component'

const styles = {
  wrapper: {
    overflow: 'hidden',
    margin: ['0', '4px', '0', '5px'],
    position: 'relative'
  }
}

class Tile extends React.PureComponent {
  constructor(props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)

    this.state = {
      isMouseHover: false
    }
  }

  onMouseEnter() {
    this.setState({
      isMouseHover: true
    })
  }

  onMouseLeave() {
    this.setState({
      isMouseHover: false
    })
  }

  render() {
    const {
      tile, recipe, className, classes, header, position
    } = this.props
    const { isMouseHover } = this.state

    const Overlay = getOverlayComponent(tile)
    const badgeTitle = tile.badge
    return (
      <div
        className={classnames(classes.wrapper, className)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        {badgeTitle && <TileBadge title={badgeTitle} hide={isMouseHover} />}
        <Imgix src={tile.image} recipe={recipe} />
        <Overlay tile={tile} header={header} position={position} />
      </div>
    )
  }
}

Tile.propTypes = {
  tile: TilePropTypes.isRequired,
  recipe: PropTypes.shape({
    ratio: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
  }).isRequired,
  className: PropTypes.string,
  header: PropTypes.string,
  position: PropTypes.number
}

Tile.defaultProps = {
  className: '',
  header: '',
  position: 0
}

export default withStyles(styles)(Tile)
