import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../shared/button'
import SkipNextIcon from './skip-next-icon'

const NextEpisodeButton = ({ disabled }) => {
  return <Button disabled={disabled} icon={<SkipNextIcon />} />
}

NextEpisodeButton.propTypes = {
  disabled: PropTypes.bool
}

NextEpisodeButton.defaultProps = {
  disabled: false
}

export default NextEpisodeButton
