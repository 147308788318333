const getClosePayload = () => {
  return {
    event: 'trackEvent',
    'eventDetails.category': 'video watch',
    'eventDetails.action': 'back',
    'eventDetails.label': location.href,
    timestampPlayStop: new Date().getTime()
  }
}

export default getClosePayload
