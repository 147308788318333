import React from 'react'
import PropTypes from 'prop-types'
import { keys } from 'ramda'
import classnames from 'classnames'
import withStyles from 'react-jss'
import { timer } from 'rxjs'

import Episode from './episode'
import { SeasonType } from '../prop-types'
import { EpisodeSelectorUiConsumer } from '../episode-selector-ui'

import SliderCarousel from '../utils/slider-carousel'

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    opacity: 0,
    transform: 'translateX(50px)',
    transition: [
      ['transform', '200ms', 'ease-out'],
      ['opacity', '200ms', 'ease-out']
    ],
    marginTop: 22
  },
  loaded: {
    opacity: 1,
    transform: 'translateX(0)'
  }
}

class SelectorCarousel extends React.PureComponent {
  constructor(props) {
    super(props)

    this.delayUpdateSeason = this.delayUpdateSeason.bind(this)

    this.state = {
      season: props.season,
      isShown: true,
      focusIndex: keys(props.season).indexOf(props.activeEpisode)
    }
  }

  componentDidUpdate(prevProps, _, season) {
    if (prevProps.season !== this.props.season) {
      this.delayUpdateSeason(season)
    }
  }

  componentWillUnmount() {
    if (this.delayUpdateSeasonSubscription) {
      this.delayUpdateSeasonSubscription.unsubscribe()
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.season !== this.props.season) {
      return this.props.season
    }
    return null
  }

  // We need to delay the update of seasons
  // by set prop.season to state.season and delay 300ms.
  // Because the new season will show before the transition finish
  // Instead of seeing it AFTER the transition
  delayUpdateSeason(season) {
    this.setState({ isShown: false }, () => {
      this.delayUpdateSeasonSubscription = timer(300).subscribe(() => {
        this.setState({ isShown: true, season })
      })
    })
  }

  render() {
    const { activeEpisode, classes } = this.props
    const { season } = this.state

    const episodeNumberArray = keys(season)
    const focusIndex = episodeNumberArray.indexOf(activeEpisode)

    return (
      <EpisodeSelectorUiConsumer>
        {({ loadedImagery }) => {
          const isImageAllLoaded = loadedImagery === episodeNumberArray.length
          const isCarouselShown = isImageAllLoaded && this.state.isShown
          return (
            <SliderCarousel
              isDisabled={!isCarouselShown}
              focusIndex={focusIndex}
              count={episodeNumberArray.length}
              currentSeason={season}
              wrapperProps={{
                className: classnames(classes.wrapper, {
                  [classes.loaded]: isCarouselShown
                })
              }}
            >
              {episodeNumberArray.map(episodeNumber => {
                return (
                  <Episode
                    key={season[episodeNumber].id}
                    episode={season[episodeNumber]}
                    active={activeEpisode === episodeNumber}
                  />
                )
              })}
            </SliderCarousel>
          )
        }}
      </EpisodeSelectorUiConsumer>
    )
  }
}

SelectorCarousel.propTypes = {
  season: SeasonType.isRequired,
  activeEpisode: PropTypes.string.isRequired
}

export default withStyles(styles)(SelectorCarousel)
