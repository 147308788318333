import React from 'react'
import withStyles from 'react-jss'
import classnames from 'classnames'

import { StateConsumer } from '../../../state'
import convertTime from '../../utils/convert-time'

const styles = theme => {
  return {
    wrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        pointerEvents: 'none',
        userSelect: 'none'
      }
    },
    currentTime: {
      width: 60,
      textAlign: 'right'
    },
    divider: {
      margin: [[0, 6]]
    },
    time: {
      textShadow: theme.shadow.textShadow(theme.color.black).primary
    }
  }
}

function TimeDisplay({ classes }) {
  return (
    <StateConsumer>
      {({ currentTime, duration }) => {
        return (
          <div className={classnames(classes.time, classes.wrapper)}>
            <span className={classes.currentTime}>
              {convertTime(currentTime)}
            </span>
            <span className={classes.divider}>/</span>
            <span className={classes.time}>{convertTime(duration)}</span>
          </div>
        )
      }}
    </StateConsumer>
  )
}

export default withStyles(styles)(TimeDisplay)
