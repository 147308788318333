import React from 'react'
import withStyles from 'react-jss'
import {
  keys, sort, head, compose
} from 'ramda'

import { SeasonsType } from './prop-types'
import Tabs from './tabs'
import SelectorCarousel from './selector-carousel'
import Synopsis from './synopsis'

const { Provider, Consumer: EpisodeSelectorUiConsumer } = React.createContext()

const styles = theme => ({
  wrapper: {
    color: theme.color.white,
    marginTop: 80,
    fontFamily: '"GothamRounded"',
    userSelect: 'none'
  }
})

const smallToBig = (a, b) => a - b
const getSmallestValueInList = compose(
  head,
  sort(smallToBig)
)

class EpisodeSelectorUi extends React.PureComponent {
  constructor(props) {
    super(props)
    this.setActiveSeason = this.setActiveSeason.bind(this)
    this.onImageryLoaded = this.onImageryLoaded.bind(this)
    this.onImageryError = this.onImageryError.bind(this)
    this.setActiveEpisode = this.setActiveEpisode.bind(this)

    const currentSeason = props.seasons.current.season.toString()
    const currentEpisode = props.seasons.current.episode.toString()
    const episodeCount = keys(props.seasons.seasons[currentSeason]).length
    const episode = props.seasons.seasons[currentSeason][currentEpisode]

    this.state = {
      currentSeason,
      activeSeason: currentSeason,
      setActiveSeason: this.setActiveSeason,
      onImageryLoaded: this.onImageryLoaded,
      onImageryError: this.onImageryError,
      loadedImagery: 0,
      episodeCount,
      activeEpisode: currentEpisode,
      setActiveEpisode: this.setActiveEpisode,
      synopsis: {
        episodeNumber: episode.episodeNumber,
        summary: episode.summary || '',
        name: episode.name
      }
    }
  }

  onImageryLoaded(seasonNumber) {
    this.incrementLoadedImage(seasonNumber)
  }

  onImageryError(seasonNumber) {
    this.incrementLoadedImage(seasonNumber)
  }

  setActiveSeason(number) {
    if (number === this.state.activeSeason) return

    const seasonList = this.props.seasons.seasons
    const current = this.props.seasons.current
    const episodeNumbers = keys(this.props.seasons.seasons[number])
    const episodeCount = episodeNumbers.length

    let activeEpisode
    if (number === current.season) {
      activeEpisode = current.episode
    } else {
      activeEpisode = getSmallestValueInList(episodeNumbers)
    }

    const episode = seasonList[number][activeEpisode]

    this.setState({
      activeEpisode,
      activeSeason: number,
      loadedImagery: 0,
      episodeCount,
      synopsis: {
        episodeNumber: episode.episodeNumber,
        summary: episode.summary || '',
        name: episode.name
      }
    })
  }

  setActiveEpisode(number) {
    const seasonList = this.props.seasons.seasons
    // eslint-disable-next-line react/no-access-state-in-setstate
    const episode = seasonList[this.state.activeSeason][number]
    return this.setState({
      activeEpisode: number,
      synopsis: {
        episodeNumber: episode.episodeNumber,
        summary: episode.summary || '',
        name: episode.name
      }
    })
  }

  // eslint-disable-next-line no-undef
  incrementLoadedImage = (seasonNumber) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (seasonNumber === this.state.activeSeason) {
      this.setState(state => ({ loadedImagery: state.loadedImagery + 1 }))
    }
  }

  render() {
    const { seasons, classes } = this.props
    return (
      <div className={classes.wrapper}>
        <Provider value={this.state}>
          <Tabs seasonTag={seasons.seasonTag} />
          <SelectorCarousel
            season={seasons.seasons[this.state.activeSeason]}
            activeEpisode={this.state.activeEpisode}
          />
          <Synopsis />
        </Provider>
      </div>
    )
  }
}

EpisodeSelectorUi.propTypes = {
  seasons: SeasonsType.isRequired
}

export default withStyles(styles)(EpisodeSelectorUi)
export { EpisodeSelectorUiConsumer }
