import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { startWith, delay, take } from 'rxjs/operators'
import { fromEvent, interval, combineLatest } from 'rxjs'

import { history } from '../../../store'
import { selectSource } from '../utils/quality-selector'
import { volumeStore, volumeMuteStore } from '../utils/storage'
import timer from '../utils/timer'
import { urlChange$, unblockPlayback$ } from '../state-stream'
import { INTERVALS, SKIP_MIDROLL_TIME_OFFSET, LINEAR_ADS_TYPE } from '../constants'
import {
  isHighRoadFreeEpisodes,
  highroadMarketingPage
} from '../../shared/highRoadFreeEpisodes'

const { Consumer: ControlConsumer, Provider } = React.createContext()

class ControlCore extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handlePlay = this.handlePlay.bind(this)
    this.enableKeydownListener = this.enableKeydownListener.bind(this)
    this.disableKeydownListener = this.disableKeydownListener.bind(this)
    this.setPlayerVolume = this.setPlayerVolume.bind(this)

    this.registerHidePlayerSubscription = this.registerHidePlayerSubscription.bind(
      this
    )
    this.registerAutoNextEpisodeSubscription = this.registerAutoNextEpisodeSubscription.bind(
      this
    )
    this.onVideoFinished = this.onVideoFinished.bind(this)
    this.getShouldDisplayContinueWatching = this.getShouldDisplayContinueWatching.bind(
      this
    )
    this.redirectToEpisodeById = this.redirectToEpisodeById.bind(this)
    this.showContinueWatching = this.showContinueWatching.bind(this)
    this.cleanUpAutoNextEpisodeSubscription = this.cleanUpAutoNextEpisodeSubscription.bind(
      this
    )

    const initialVolume = volumeStore.get()
    const isVolumeMute = volumeMuteStore.get()
    const { player, getSetters, isSsaiStream } = props

    if (isVolumeMute) {
      player.volume(0)
    } else {
      player.volume(initialVolume)
    }

    this.state = {
      showPageUrl: this.props.showPageUrl,
      play: this.handlePlay,
      pause: () => player.pause(),
      enterFullscreen: () => player.requestFullscreen(),
      exitFullscreen: () => player.exitFullscreen(),
      seekTo: time => {
        const { linearAdRolls, setSeekingPointAfterMidroll } = this.props

        if (isSsaiStream) {
          const playerCurrentContentTime = player.ssai().currentTimelineState().relativeTime
          let adsPodStartTimeInContent = 0
          // The ads pod in linearAdRolls are in chronological order, and seek to time is content time
          // Find the nearest unwatched midroll ads pod before the seeking point when user seeking forward
          const unWatchedMidrollAds = linearAdRolls.findLast(ad => {
            if (playerCurrentContentTime < ad.startTimeInContent && ad.startTimeInContent < time && !ad.watched && ad.type === 'mid') {
              // Set the setSeekingPointAfterMidroll and it will be used in the state.jsx
              setSeekingPointAfterMidroll(time)
              adsPodStartTimeInContent = ad.startTimeInContent
              return true
            }
            return false
          })

          if (unWatchedMidrollAds) {
            // play the unwatched mid roll first, then back to the setSeekingPointAfterMidroll
            // minus 0.1 second to avoid the frozen player
            player.ssai().seekInContentTime(adsPodStartTimeInContent - SKIP_MIDROLL_TIME_OFFSET)
          } else {
            // it's bug in BC SSAI when seek to content time 0, the player exit
            if (time === 0) time += 1
            player.ssai().seekInContentTime(time)
          }
        } else {
          player.currentTime(time)
        }
      },
      setQuality: option => {
        return new Promise(resolve => {
          getSetters().setCurrentQuality(option)
          selectSource(player, option.bitrate)
          resolve()
        })
      },
      disableKeydownListener: this.disableKeydownListener,
      enableKeydownListener: this.enableKeydownListener,
      setPlayerVolume: this.setPlayerVolume,
      setShouldNotUseCuePoint: this.props.setShouldNotUseCuePoint,
      onVideoFinished: this.onVideoFinished,
      setShouldUseCuePoint: this.props.setShouldUseCuePoint,
      getShouldDisplayContinueWatching: this.getShouldDisplayContinueWatching,
      redirectToEpisodeById: this.redirectToEpisodeById,
      showContinueWatching: this.showContinueWatching,
      initializeTextTrackSubscription: this.initializeTextTrackSubscription,
      getTextTrackFromPlayer: () => player.textTracks()
    }
  }

  componentDidMount() {
    // Global event subscription
    this.enableKeydownListener()
    this.qualityLevelCheckSubscription = this.registerQualityLevelCheckSubscription()
    this.hidePlayerSubscription = this.registerHidePlayerSubscription()
    this.playerInitializationSubscription = this.registerPlayerInitializationSubscription()
  }

  componentWillUnmount() {
    this.keydownSubscription.unsubscribe()
    this.qualityLevelCheckSubscription.unsubscribe()
    this.hidePlayerSubscription.unsubscribe()
    this.playerInitializationSubscription.unsubscribe()

    this.cleanUpAutoNextEpisodeSubscription()
  }

  handlePlay() {
    const {
      player, currentTime, duration, getSetters
    } = this.props
    player.play()

    if (currentTime >= duration && duration !== 0) {
      const { setCurrentTime } = getSetters()
      setCurrentTime(0)
    }
  }

  onVideoFinished(props) {
    const {
      nextEpisodeId,
      setIsContinueWatchingShown,
      showPageUrl,
      player
    } = props

    if (isHighRoadFreeEpisodes()) {
      history.push(highroadMarketingPage)
      return
    }
    if (this.getShouldDisplayContinueWatching()) {
      setIsContinueWatchingShown()
    } else if (nextEpisodeId) {
      this.redirectToEpisodeById(nextEpisodeId)
    } else if (showPageUrl) {
      history.push(showPageUrl)
    } else {
      player.pause()
    }
  }

  setPlayerVolume(volume) {
    const { player } = this.props
    player.volume(volume)
  }

  getShouldDisplayContinueWatching() {
    const { continueWatchingThreshold } = this.props
    return !timer.isWithinThreshold(continueWatchingThreshold)
  }

  // eslint-disable-next-line react/sort-comp
  cleanUpAutoNextEpisodeSubscription() {
    if (this.autoNextEpisodeSubscription) {
      this.autoNextEpisodeSubscription.unsubscribe()
    }
  }

  showContinueWatching() {
    const { setIsContinueWatchingShown, player } = this.props
    setIsContinueWatchingShown()
    player.pause()
  }

  redirectToEpisodeById(id) {
    const { setShouldUseCuePoint } = this.props
    setShouldUseCuePoint()
    history.push(id)
  }

  disableKeydownListener() {
    this.keydownSubscription.unsubscribe()
  }

  enableKeydownListener() {
    const startPlaybackEvent = unblockPlayback$.pipe(take(1))

    this.keydownSubscription = combineLatest(
      fromEvent(window, 'keydown'),
      startPlaybackEvent
    ).subscribe(evt => {
      // evt[0] will be the keydown event, evt[1] will be undefined from startInitialPlaySubject$
      const keyDownEvent = evt[0]
      const { player, isPlaying } = this.props
      if (isSpace(keyDownEvent)) {
        // LBX-616 https://tdv-ott.atlassian.net/browse/LBX-616
        // This issue is caused by clicks creating a focus point on the play button.
        // As hitting space is equivalent to clicking a button, preventDefault and stopPropagation
        // will prevent the event cascading to child elements.
        keyDownEvent.preventDefault()
        keyDownEvent.stopPropagation()
        if (isPlaying) {
          player.pause()
        } else {
          player.play()
        }
      }
    })
  }

  /**
   *  We check if the quality setting is correct every second.
   *  when confirmed, terminate this check
   */
  registerQualityLevelCheckSubscription() {
    const subscription = interval(INTERVALS.QUALITY_LEVEL_CHECK).subscribe(() => {
      const { player, currentQuality } = this.props

      const levels = player.qualityLevels()

      if (levels.selectedIndex === -1) return

      const selectedLevel = levels[levels.selectedIndex]
      if (!selectedLevel || !currentQuality) return

      if (selectedLevel.bitrate > currentQuality.bitrate) {
        selectSource(player, currentQuality.bitrate)
      }
      subscription.unsubscribe()
    })

    return subscription
  }

  /**
   *   When step into next episode, we need to hide the video
   *   otherwise it is confusing for user
   *   to see the show is still playing
   *
   *   Here, we need to show the video tag if necessary
   */
  registerHidePlayerSubscription() {
    const { getStreams } = this.props
    const subscription = getStreams().play$.subscribe(() => {
      const { player } = this.props
      if (player.$('video').hidden) {
        player.$('video').hidden = false
        subscription.unsubscribe()
      }
    })
    return subscription
  }

  registerAutoNextEpisodeSubscription() {
    const { getStreams } = this.props
    const subscription = getStreams().refresh$.subscribe(
      ({ duration, currentTime }) => {
        const {
          nextEpisodeId,
          setIsContinueWatchingShown,
          showPageUrl,
          player,
          getIsPlayerInitialized,
          isPlayingAd
        } = this.props
        // We will call onVideoFinished if the content(no ads) is finsihed
        // Because currentTime update from BC player will delay a few seconds.
        // When new episode hits, we will still use the old currentTime, which will be wrong
        if (
          !getIsPlayerInitialized() ||
          Math.floor(currentTime) < Math.floor(duration) ||
          isPlayingAd
        ) {
          return
        }

        this.onVideoFinished({
          nextEpisodeId,
          setIsContinueWatchingShown,
          showPageUrl,
          player
        })

        subscription.unsubscribe()
      }
    )
    return subscription
  }

  // eslint-disable-next-line no-undef
  registerPlayerInitializationSubscription = () => {
    return urlChange$
      .pipe(
        startWith({}),
        // Delay the subscription
        // because `duration` in bc player is not that fast to be set as ours
        // (when next episode stared, the duration and current time still previous episode's)
        // it would be safe to delay for 4s for all content for now
        delay(4000)
      )
      .subscribe(() => {
        // Remove any subscription if there's any
        this.cleanUpAutoNextEpisodeSubscription()
        this.autoNextEpisodeSubscription = this.registerAutoNextEpisodeSubscription()
      })
  }

  render() {
    const { children, player } = this.props
    return ReactDOM.createPortal(
      <Provider value={this.state}>{children}</Provider>,
      player.el()
    )
  }
}

ControlCore.propTypes = {
  getSetters: PropTypes.func.isRequired,
  currentQuality: PropTypes.shape({
    bitrate: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }).isRequired,
  player: PropTypes.shape({
    ssai: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    volume: PropTypes.func.isRequired,
    requestFullscreen: PropTypes.func.isRequired,
    exitFullscreen: PropTypes.func.isRequired,
    currentTime: PropTypes.func.isRequired,
    textTracks: PropTypes.func.isRequired,
    qualityLevels: PropTypes.func.isRequired,
    el: PropTypes.func.isRequired,
    $: PropTypes.func.isRequired
  }).isRequired,
  isPlaying: PropTypes.bool.isRequired,
  // PropTypes.bool is for when there's no currentTime (aka, player is loading)
  currentTime: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    .isRequired,
  duration: PropTypes.number.isRequired,
  continueWatchingThreshold: PropTypes.number.isRequired,
  getStreams: PropTypes.func.isRequired,
  setShouldNotUseCuePoint: PropTypes.func.isRequired,
  setShouldUseCuePoint: PropTypes.func.isRequired,
  setIsContinueWatchingShown: PropTypes.func.isRequired,
  getIsPlayerInitialized: PropTypes.func.isRequired,
  nextEpisodeId: PropTypes.string,
  showPageUrl: PropTypes.string,
  isSsaiStream: PropTypes.bool.isRequired,
  isPlayingAd: PropTypes.bool.isRequired,
  linearAdRolls: PropTypes.arrayOf(PropTypes.shape(LINEAR_ADS_TYPE)),
  setSeekingPointAfterMidroll: PropTypes.func
}

ControlCore.defaultProps = {
  nextEpisodeId: null,
  showPageUrl: null
}

export default ControlCore
export { ControlConsumer }

function isSpace(evt) {
  const SPACE_KEY_CODE = 32
  const SPACE_CODE = 'Space'
  return evt.keyCode === SPACE_KEY_CODE || evt.code === SPACE_CODE
}
