import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

import Image from './image'
import { EpisodeSelectorUiConsumer } from '../episode-selector-ui'
import { IMAGE_MARGIN, IMAGE_WIDTH } from '../constants'
import EpisodeInfo from './episode-info'

const styles = theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      opacity: 0.5,
      transform: 'scale(1)',
      height: 210,
      transition: [
        ['opacity', 'ease-out', '200ms'],
        ['transform', 'ease-out', '200ms']
      ],

      '&:first-of-type': {
        paddingLeft: IMAGE_MARGIN
      },

      '&:last-of-type': {
        paddingRight: IMAGE_MARGIN
      }
    },
    active: {
      zIndex: theme.zIndex.base + 1,
      opacity: 1,
      transform: 'scale(1.1)',
      transitionDuration: '100ms'
    },
    name: {
      margin: [[4, 10, 0, 10]],
      maxWidth: '100%',
      height: 'auto',
      fill: theme.color.white
    },
    current: {
      zIndex: theme.zIndex.base
    },
    link: { position: 'relative', width: IMAGE_WIDTH }
  }
}

class Episode extends React.PureComponent {
  render() {
    const {
      episode, classes, active, forwardRef
    } = this.props
    const imageUri = episode.images.uri
    return (
      <EpisodeSelectorUiConsumer>
        {({ onImageryLoaded, onImageryError, setActiveEpisode }) => {
          return (
            <div
              ref={forwardRef}
              onMouseEnter={() => {
                setActiveEpisode(episode.episodeNumber)
              }}
              className={classnames(classes.wrapper, {
                [classes.active]: active,
                [classes.current]: episode.isCurrentEpisode
              })}
            >
              <div className={classes.link}>
                <EpisodeInfo episode={episode} />
                <Image
                  onLoad={() => onImageryLoaded(episode.seasonNumber)}
                  onLoadError={() => onImageryError(episode.seasonNumber)}
                  src={imageUri}
                  isPrimaryColorBorder={episode.isCurrentEpisode}
                  isBorderShown={active}
                />
                <div className={classes.name}>
                  {episode.episodeNumber}
                  .
                  {episode.name}
                </div>
              </div>
            </div>
          )
        }}
      </EpisodeSelectorUiConsumer>
    )
  }
}

Episode.propTypes = {
  episode: PropTypes.shape({
    images: PropTypes.shape({
      uri: PropTypes.string.isRequired
    }).isRequired,
    name: PropTypes.string.isRequired,
    episodeNumber: PropTypes.string.isRequired,
    isCurrentEpisode: PropTypes.bool.isRequired,
    seasonNumber: PropTypes.string.isRequired
  }).isRequired,
  active: PropTypes.bool.isRequired,
  forwardRef: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.any])
  })
}

Episode.defaultProps = {
  forwardRef: undefined
}

export default withStyles(styles)(Episode)
