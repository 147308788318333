export default function convertTime(secondTime) {
  let outputString = ''
  if (secondTime === 'NaN') {
    return '00:00:00'
  }

  const cursorHour = parseInt(secondTime / (60 * 60), 10)
  if (cursorHour > 0) {
    outputString = `${cursorHour}:`
  }

  const cursorMin = parseInt((secondTime % (60 * 60)) / 60, 10)
  if (cursorMin > 0) {
    if (cursorMin < 10) {
      outputString = `${outputString}0${cursorMin}:`
    } else {
      outputString = `${outputString}${cursorMin}:`
    }
  } else {
    outputString = `${outputString}00:`
  }

  const cursorSec = parseInt(secondTime % 60, 10)
  if (cursorSec === 0 || isNaN(cursorSec)) {
    outputString = `${outputString}00`
  } else if (cursorSec < 10) {
    outputString = `${outputString}0${cursorSec}`
  } else {
    outputString = `${outputString}${cursorSec}`
  }
  return outputString
}
