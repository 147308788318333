import React from 'react'
import { PLAYBACK_ERRORS } from '../../../constants'
import { ErrorHandlerModal, BCErrorHandlerModal } from './modal'

class ErrorHandler extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
      hasBCPlayerError: false
    }

    this.onBCPlayerError = () => {
      this.setState({ hasBCPlayerError: true })
    }
  }

  componentDidCatch(error) {
    if (error.graphQLErrors) {
      const currentError = error.graphQLErrors[0]
      if (PLAYBACK_ERRORS.includes(currentError.code)) {
        this.setState({ hasError: true, errorMsg: currentError.message })
      }
      return
    }

    if (error.name === 'Error') {
      this.setState({ hasError: true, errorMsg: error.message })
    }
  }

  render() {
    const { children } = this.props

    if (this.state.hasBCPlayerError) {
      return <BCErrorHandlerModal />
    }

    if (this.state.hasError) {
      return <ErrorHandlerModal message={this.state.errorMsg} />
    }

    return children(this.onBCPlayerError)
  }
}

export default ErrorHandler
