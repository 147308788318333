const zIndex = {
  modal: 12,
  loadingSpinner: 11,
  uiPlaceholder: 11,
  control: 10,
  overlay: 2,
  header: 3,
  nextEpisodeBanner: 1,
  base: 1,
  moreLikeThis: 1,
  carouselImg: -1
}

export default zIndex
