import React from 'react'
import withStyles from 'react-jss'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ModalWrapper from '../../modal/modal-wrapper'
import { wrapper } from '../../modal/shared-styles'

const styles = theme => {
  return {
    wrapper: {
      extend: wrapper,
      background: theme.color.white,
      boxShadow: theme.shadow.boxShadow(theme.color.black).primary,
      borderRadius: 3,
      padding: [40, 50],
      alignItems: 'center'
    },
    title: {
      fontWeight: 500,
      fontFamily: ['"GothamRounded"', 'sans-serif', '!important'],
      textAlign: 'center',
      marginBottom: 0,
      fontSize: 30
    },
    description: {
      textAlign: 'center',
      fontWeight: 300,
      fontSize: 14
    },
    button: {
      padding: ['10px', '5vw'],
      border: 'none',
      borderRadius: 3,
      fontWeight: 600,
      backgroundColor: theme.color.primary,
      fontSize: 16,
      outline: 'none',
      cursor: 'pointer',
      marginTop: 10
    }
  }
}

class ErrorHandlerModal extends React.PureComponent {
  render() {
    const { classes, message } = this.props
    return (
      <ModalWrapper isShown>
        <div className={classes.wrapper}>
          <h1 className={classes.title}>Hmmm</h1>
          <p className={classes.description}>{message}</p>
          <Link to="/">
            <button className={classes.button}>OK</button>
          </Link>
        </div>
      </ModalWrapper>
    )
  }
}

ErrorHandlerModal.propTypes = {
  message: PropTypes.string
}

ErrorHandlerModal.defaultProps = {
  message: 'Oops! Something went wrong. Please refresh the page and try again.'
}

export default withStyles(styles)(ErrorHandlerModal)
