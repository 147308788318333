/* eslint-disable class-methods-use-this */
import React from 'react'
import PropTypes from 'prop-types'
import {
  fromEvent, interval, merge, Subject, combineLatest
} from 'rxjs'
import {
  distinctUntilChanged,
  map,
  startWith,
  share,
  debounceTime,
  tap,
  take,
  filter
} from 'rxjs/operators'
import { isNil, head } from 'ramda'

import { appConfigPropType } from '../utils/prop-types'
import {
  getVideoDoneThreshold,
  shouldSendStopEvent,
  getAutoplayCountdown,
  getAutoplayThreshold,
  getTvodCountdownWarning,
  getIsEdge,
  getIsSafari,
  getIsFullscreen
} from './utils'
import { readDefaultQualityFromAppConfig, getSDQuality } from '../utils/quality-selector'
import { playbackQualityStore } from '../utils/storage'
import timer from '../utils/timer'
import { positionSavingContinue, refresh } from './stream-operators'
import {
  playbackStarted$,
  unblockPlayback$,
  sendProgressToGA$
} from '../state-stream'
import {
  INTERVALS,
  PLAYER_CONTROLLER_DURATION,
  PLAY_MIDROLL_TIME_OFFSET,
  SKIP_MIDROLL_TIME_OFFSET
} from '../constants'
import { segmentTrackStopVideo, segmentTrackPauseVideo, segmentTrackPlayVideo } from '../../../segment/segment-track'
import { requestAdOnPause, clearAdOnPause } from '../../google-publisher-tag/utils'
import { AD_ON_PAUSE_TIMEOUT } from '../../google-publisher-tag/constants'
import {
  PLAYBACK_EVENT_CONTINUE,
  PLAYBACK_EVENT_PAUSE,
  PLAYBACK_EVENT_RESUME,
  PLAYBACK_EVENT_START,
  PLAYBACK_EVENT_STOP,
  PLAYBACK_EVENT_PREROLL_START
} from '../../video-player/lib/constants'

const { Consumer: StateConsumer, Provider } = React.createContext()

/* eslint-disable react/prop-types */
class StateProvider extends React.PureComponent {
  constructor(props) {
    super(props)
    const {
      isTrailer,
      appConfig,
      player,
      contentId,
      continueWatchingThreshold,
      firstPlayback,
      purchasedFormat,
      svodStreamingResolution,
      isSsaiStream
    } = props
    this.setNewTime$ = new Subject()
    this.unblockPlayback$ = unblockPlayback$
    this.sendProgressToGA$ = sendProgressToGA$
    this.registerEvents = this.registerEvents.bind(this)
    this.setCurrentTime = this.setCurrentTime.bind(this)
    this.getSetters = this.getSetters.bind(this)
    this.getStreams = this.getStreams.bind(this)
    this.toggleSettingsPanelOpen = this.toggleSettingsPanelOpen.bind(this)
    this.setCurrentQuality = this.setCurrentQuality.bind(this)
    this.registerStreams = this.registerStreams.bind(this)
    this.saveCurrentPosition = this.saveCurrentPosition.bind(this)
    this.saveCurrentPositionWhenExit = this.saveCurrentPositionWhenExit.bind(
      this
    )
    this.setIsVolumeBarVisible = this.setIsVolumeBarVisible.bind(this)
    this.setIsContinueWatchingShown = this.setIsContinueWatchingShown.bind(this)
    this.setIsTvodModalShown = this.setIsTvodModalShown.bind(this)
    this.setIsQualityUpgradePopupShown = this.setIsQualityUpgradePopupShown.bind(this)

    this.setIsAdOnPauseShown = this.setIsAdOnPauseShown.bind(this)
    this.clearAdOnPauseState = this.clearAdOnPauseState.bind(this)

    this.setIsEpisodeSelectorOpen = this.setIsEpisodeSelectorOpen.bind(this)

    this.initLinearAdRolls = this.initLinearAdRolls.bind(this)
    this.setSeekingPointAfterMidroll = this.setSeekingPointAfterMidroll.bind(this)

    const initialDuration = parseInt(player.duration(), 10)

    let currentQuality = readDefaultQualityFromAppConfig(appConfig)

    if ([purchasedFormat, svodStreamingResolution].includes('SD') && currentQuality.label === 'HD') {
      currentQuality = getSDQuality(appConfig)
    }

    const values = {
      PLAYER_VIDEO_DONE_THRESHOLD: getVideoDoneThreshold(appConfig.values),
      PLAYER_AUTO_PLAY_THRESHOLD: getAutoplayThreshold(appConfig.values),
      PLAYER_AUTO_PLAY_COUNTDOWN: getAutoplayCountdown(appConfig.values),
      PLAYER_TVOD_COUNTDOWN_WARNING_LABEL: getTvodCountdownWarning(
        appConfig.values
      )
    }

    this.state = {
      isTrailer,
      firstPlayback,
      isPlaying: false,
      isFullscreen: false,
      isLoading: false,
      isStopEventSent: false,
      isUiHidden: false,
      isContinueWatchingShown: false,
      isTvodModalShown: false,
      isQualityUpgradePopupShown: false,
      continueWatchingThreshold,
      currentTime: 0,
      bufferedTime: 0,
      duration: initialDuration || 0,
      contentId,
      isSettingsPanelOpen: false,
      // DO NOT USE!!`isInitialLoadingDone` is depreciated
      // please use getIsPlayerInitialized from Player Component
      isInitialLoadingDone: false, // TODO: remove this
      currentQuality,
      playbackQualityOptions: appConfig.playbackQualityOptions,
      getStreams: this.getStreams,
      getSetters: this.getSetters,
      toggleSettingsPanelOpen: this.toggleSettingsPanelOpen,
      setIsVolumeBarVisible: this.setIsVolumeBarVisible,
      setIsContinueWatchingShown: this.setIsContinueWatchingShown,
      setIsTvodModalShown: this.setIsTvodModalShown,
      setIsQualityUpgradePopupShown: this.setIsQualityUpgradePopupShown,
      values,
      getMetaData: () => this.props.metaData,
      isVolumeBarVisible: false,
      purchasedFormat,
      svodStreamingResolution,
      isSafari: getIsSafari(),
      appConfig,
      isPlayingAd: false,
      isSsaiStream,
      totalAds: 0,
      adsCounter: 0,
      saveCurrentPosition: this.saveCurrentPosition,
      isAdOnPauseShown: false,
      setIsAdOnPauseShown: this.setIsAdOnPauseShown,
      isEpisodeSelectorOpen: false,
      setIsEpisodeSelectorOpen: this.setIsEpisodeSelectorOpen,
      linearAdRolls: null,
      currentLinearAdRolls: null,
      seekingPointAfterMidroll: 0,
      setSeekingPointAfterMidroll: this.setSeekingPointAfterMidroll,
      userHasWatchedFromResumePoint: false
    }

    this.registerStreams()
  }

  componentDidMount() {
    this.registerEvents()
  }

  componentWillUnmount() {
    this.contentplaybackSubscription.unsubscribe()
    this.adsPlaySubscription.unsubscribe()
    this.adsPauseSubscription.unsubscribe()
    this.adsAdStartedSubscription.unsubscribe()
    this.adsPodStartedSubscription.unsubscribe()
    this.adsPodEndedSubscription.unsubscribe()
    this.playSubscription.unsubscribe()
    this.pauseSubscription.unsubscribe()
    this.refreshSubscription.unsubscribe()
    this.fullscreenSubscription.unsubscribe()
    this.playingSubscription.unsubscribe()
    this.waitingSubscription.unsubscribe()
    this.continuePositionSavingSubscription.unsubscribe()
    // this.setResumePointSubscription.unsubscribe()
    this.isPlaybackReadySubscription.unsubscribe()
    this.unblockPlaybackSubscription.unsubscribe()
    this.showUiSubscription.unsubscribe()
    this.userActivitySubscription.unsubscribe()
    // this.initialPlaybackSubscription.unsubscribe()
    this.getPlayProgressSubscription.unsubscribe()
    this.playingSubscriptionForSafariAndEdge.unsubscribe()

    this.saveCurrentPositionWhenExit()
  }

  // eslint-disable-next-line no-undef
  onInitialLoad = () => {
    // TODO remove isInitialLoadingDone
    this.setState({ isInitialLoadingDone: true })
    this.props.setIsPlaybackReady()

    if (this.state.firstPlayback) {
      this.setIsTvodModalShown(true)
      this.props.player.pause()
    }
  }

  /**
   *
   * @param {Number} time
   *
   * This function is for fast seeking when the progress bar is clicked, for better UX
   * Resume point will be saved at the same time
   *
   */
  setCurrentTime(time, skipContinueEvent = false) {
    if (isNil(time)) return
    this.setState(
      state => {
        return {
          currentTime: time,
          isStopEventSent: shouldSendStopEvent(state)
        }
      },
      state => {
        if (!skipContinueEvent) {
          this.saveCurrentPosition(PLAYBACK_EVENT_CONTINUE, state)
        }
        this.setNewTime$.next()
      }
    )
  }

  getStreams() {
    return {
      play$: this.play$,
      pause$: this.pause$,
      refresh$: this.refresh$,
      waiting$: this.waiting$,
      playing$: this.playing$,
      setNewTime$: this.setNewTime$,
      startInitialPlaySubject$: this.unblockPlayback$,
      initialLoad$: this.initialLoad$
    }
  }

  // We put setters here for not easily mutate states
  getSetters() {
    return {
      setCurrentTime: this.setCurrentTime,
      setCurrentQuality: this.setCurrentQuality
    }
  }

  setCurrentQuality(selectedQuality) {
    // set state
    this.setState(
      {
        currentQuality: selectedQuality
      },
      () => {
        // save to localstorage
        playbackQualityStore.set(selectedQuality.id)
      }
    )
  }

  setIsVolumeBarVisible(isFocus) {
    this.setState({ isVolumeBarVisible: isFocus })
  }

  setIsContinueWatchingShown() {
    this.setState({ isContinueWatchingShown: true })
  }

  setIsTvodModalShown(isTvodModalShown) {
    this.setState({ isTvodModalShown })
  }

  setIsQualityUpgradePopupShown(isQualityUpgradePopupShown) {
    this.setState({ isQualityUpgradePopupShown })
  }

  setIsAdOnPauseShown(isAdOnPauseShown) {
    this.setState({ isAdOnPauseShown })
  }

  setSeekingPointAfterMidroll(time) {
    this.setState({ seekingPointAfterMidroll: time })
  }

  setIsEpisodeSelectorOpen(play, pause, disableKeydownListener, enableKeydownListener) {
    // Clear AoP when episode selector opens
    if (this.state.isAdOnPauseShown) this.clearAdOnPauseState()
    return this.setState(
      ({ isEpisodeSelectorOpen }) => {
        return {
          isEpisodeSelectorOpen: !isEpisodeSelectorOpen
        }
      },
      () => {
        if (!this.state.isEpisodeSelectorOpen) {
          play()
          enableKeydownListener()
        } else {
          pause()
          disableKeydownListener()
        }
      }
    )
  }

  getStartTimeInContentList = (ads) => {
    // Change string time format like "01:34:05.0000" to seconds
    const startTimeInContentList = ads?.map(ad => {
      const time = ad.timeOffset_.split(':')
      const seconds = time[0] * 3600 + time[1] * 60 + time[2] * 1
      return isNaN(seconds) ? 0 : seconds
    })

    return startTimeInContentList
  }

  saveCurrentPosition(type, state) {
    const {
      isTrailer,
      emitCurrentPosition,
      contentId: currentContentId
    } = this.props
    const {
      currentTime, isStopEventSent, duration, isPlayingAd
    } = state || this.state

    if (isStopEventSent) return
    if (isTrailer) return

    let ignoreTime = false
    let midRollStartTimeInContent = 0
    // when ads are playing, the only 2 events we send are PREROLL_START & PAUSE
    if (isPlayingAd) {
      /* set the midRollStartTimeInContent here if type is pause which will be trigger by saveCurrentPositionWhenExit
      ** player might frozen if resume from ad start time
      ** so set the resume point to startTimeInContent minus 1 second
      */
      if ([PLAYBACK_EVENT_PAUSE, PLAYBACK_EVENT_PREROLL_START].includes(type)) {
        const ad = this.state.currentLinearAdRolls
        if (ad) {
          midRollStartTimeInContent = ad.startTimeInContent
        }
        ignoreTime = true
      } else {
        return
      }
    }

    if (shouldSendStopEvent(state || this.state)) {
      emitCurrentPosition(PLAYBACK_EVENT_STOP, 0, currentContentId) // when sending STOP, time doesn't matter (refer to resolvers/playback)
      this.setState({ isStopEventSent: true })
    } else if (!isNaN(duration)) {
      // resume point is 0 for pre roll
      // use the midRollStartTimeInContent for resuming from mid roll
      emitCurrentPosition(type, ignoreTime ? (midRollStartTimeInContent || 0) : Math.floor(currentTime), currentContentId)
    }
  }

  saveCurrentPositionWhenExit() {
    if (this.state.duration === 0 || this.state.currentTime === 0) return

    this.saveCurrentPosition(PLAYBACK_EVENT_PAUSE)

    // Add segment data analytics for exiting video
    const { contentId, currentTime, duration } = this.state
    segmentTrackStopVideo({
      contentId,
      currentTime: Math.round(currentTime),
      duration
    })
  }

  toggleSettingsPanelOpen() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isSettingsPanelOpen: !this.state.isSettingsPanelOpen })
  }

  createProgressObservables(percentageProgressArray) {
    return percentageProgressArray.map(progress => {
      return combineLatest(this.refresh$, this.initialLoad$).pipe(
        filter(() => {
          const { currentTime, duration } = this.state
          if (isNaN(duration) || isNaN(currentTime)) return false
          const currentProgressPercentage = currentTime / duration
          return (
            currentProgressPercentage >= progress &&
            currentProgressPercentage < progress + 0.01
          )
        }),
        take(1)
      )
    })
  }

  doPause() {
    this.setState({ isPlaying: false, isLoading: false })
  }

  doPlay() {
    if (!this.state.isPlaying && this.state.isInitialLoadingDone) {
      // this.saveCurrentPosition('PLAY') // TODO: investigate LBX-7648
    }
    this.setState({ isPlaying: true })
  }

  clearAdOnPauseState() {
    // Clear AoP
    const { shouldRenderAoP } = this.props
    if (shouldRenderAoP) {
      clearTimeout(this.adOnPauseTimer)
      clearAdOnPause()
      this.setState({ isAdOnPauseShown: false })
    }
  }

  // Init all ads pod (pre-roll + mid-roll) - a sequence of linear ads that are played back to back
  // Save ads pod start and end absolute time (content + ads)
  initLinearAdRolls(player) {
    const linearAdRolls = player.ssai().timeline_.linearAdRolls_
    const startTimeInContentList = this.getStartTimeInContentList(linearAdRolls)

    const ads = linearAdRolls?.map((ad, index) => {
      return {
        type: ad.type_,
        startTimeInContent: startTimeInContentList[index],
        index: ad.podIndex_,
        watched: false
      }
    })

    this.setState({ linearAdRolls: ads })

    // Add segment data analytics for playing video with ssai
    segmentTrackPlayVideo(this.props.contentItem, linearAdRolls)
  }

  checkMidrollExists(player) {
    const linearAdRolls = player.ssai().timeline_.linearAdRolls_
    const contentHasMidrollAds = linearAdRolls?.find(ad => ad.type_ === 'mid')
    return contentHasMidrollAds
  }

  registerStreams() {
    const { player } = this.props

    this.play$ = fromEvent(player, 'play')
    this.pause$ = fromEvent(player, 'pause')
    this.waiting$ = fromEvent(player, 'waiting')
    this.playing$ = fromEvent(player, 'playing')
    // ads only event
    this.adsPlay$ = fromEvent(player, 'ads-play')
    this.adsPause$ = fromEvent(player, 'ads-pause')
    this.adsAdStarted$ = fromEvent(player, 'ads-ad-started')
    this.adsPodStarted$ = fromEvent(player, 'ads-pod-started')
    this.adsPodEnded$ = fromEvent(player, 'ads-pod-ended')
    // both content start and ads start will trigger contentplayback
    this.contentplayback$ = fromEvent(player, 'contentplayback')

    // Stop registerStreams if the modal(plan upgrade popup) opens on player
    if (location.search.indexOf('modal') !== -1) return

    // It seems for safari/edge, the initial `play` event is `playing` while others are `play`
    if (getIsSafari() || getIsEdge()) {
      player.one('playing', () => playbackStarted$.next())
    } else {
      player.one('play', () => playbackStarted$.next())
    }

    this.fullscreenchange$ = fromEvent(player, 'fullscreenchange').pipe(
      map(() => getIsFullscreen()),
      distinctUntilChanged(),
      startWith(getIsFullscreen())
    )

    this.refresh$ = interval(INTERVALS.REFRESH).pipe(...refresh(this))

    this.continuePositionSaving$ = this.refresh$.pipe(
      ...positionSavingContinue()
    )

    this.initialLoad$ = this.refresh$.pipe(
      filter(
        refreshData => refreshData.bufferedTime > 0 && refreshData.duration > 0
      ),
      take(1),
      share()
    )

    this.showUi$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'touchmove'),
      fromEvent(window, 'touchend'),
      fromEvent(window, 'click'),
      this.initialLoad$
    ).pipe(
      tap(() => {
        this.setState({ isUiHidden: false })
      }),
      debounceTime(PLAYER_CONTROLLER_DURATION),
      filter(
        () => !this.state.isSettingsPanelOpen &&
          this.state.isInitialLoadingDone &&
          this.state.isPlaying
      )
    )

    this.userActivity$ = merge(
      fromEvent(window, 'click'),
      fromEvent(window, 'keydown')
    )
  }

  registerEvents() {
    // contentplayback is only fired when preroll starts or content starts(after preroll or after midroll)
    this.contentplaybackSubscription = this.contentplayback$.subscribe(
      () => {
        const {
          player,
          resumePoint,
          isSsaiStream,
          contentId,
          emitCurrentPosition
        } = this.props
        const { currentTime } = this.state
        if (isSsaiStream) {
          this.setState({
            adsCounter: 0,
            isLoading: false
          })

          // Set all pre roll and mid roll ads info in initLinearAdRolls
          !this.state.linearAdRolls && this.initLinearAdRolls(player)
          const isPlayingPreroll = player.ssai().currentTimelineState().linearAdRoll?.isPreRoll()

          // Only skip the preroll when the play session is started from resume point(continue watching), and preroll & midroll coexist,
          if (isPlayingPreroll && (resumePoint === 0 || (!this.checkMidrollExists(player) && resumePoint > 0))) {
            // Play pre roll
            this.saveCurrentPosition(PLAYBACK_EVENT_PREROLL_START)
          } else if (this.state.seekingPointAfterMidroll) {
            // The seekingPointAfterMidroll will be updated when user seek forward and beyond unwatched midroll
            // Play from the seekingPointAfterMidroll if it is exists
            player.ssai().seekInContentTime(this.state.seekingPointAfterMidroll)
            this.setState({ seekingPointAfterMidroll: 0 })
          } else if (resumePoint && this.state.linearAdRolls) {
            // main content starts playing
            if (this.state.userHasWatchedFromResumePoint) {
              // Ignore the resume point and continue playing main content if the user has watched from the resume point
              emitCurrentPosition(PLAYBACK_EVENT_CONTINUE, Math.floor(currentTime), contentId)
            } else {
              // The resume point only use once in one viewing session
              this.setState({ userHasWatchedFromResumePoint: true })

              // resume from ads start time in content - 1 second if resume point is ads start time in content
              const resumeFromAds = this.state.linearAdRolls.find(ad => ad.startTimeInContent === resumePoint)
              const newResumePoint = resumeFromAds ? resumePoint - PLAY_MIDROLL_TIME_OFFSET : resumePoint

              player.ssai().seekInContentTime(newResumePoint)
              this.setCurrentTime(newResumePoint, true)
              emitCurrentPosition(PLAYBACK_EVENT_RESUME, newResumePoint, contentId)
            }
          } else {
            // No resume point, continue playing content
            const userWatchedMidroll = this.state.linearAdRolls.find(ad => ad.watched && ad.type === 'mid')

            if (userWatchedMidroll) {
              emitCurrentPosition(PLAYBACK_EVENT_CONTINUE, Math.floor(currentTime), contentId)
            } else {
              emitCurrentPosition(PLAYBACK_EVENT_START, Math.floor(currentTime), contentId)
            }
          }
        } else {
          // non ssai stream
          // eslint-disable-next-line no-lonely-if
          if (resumePoint) {
            player.currentTime(resumePoint)
            this.setCurrentTime(resumePoint, true)
            this.saveCurrentPosition(PLAYBACK_EVENT_RESUME)
          } else {
            this.saveCurrentPosition(PLAYBACK_EVENT_START)
          }

          // Add segment data analytics for playing video without ssai
          segmentTrackPlayVideo(this.props.contentItem)
        }
      }
    )

    this.adsPlaySubscription = this.adsPlay$.subscribe(() => {
      this.doPlay()
    })

    this.adsPauseSubscription = this.adsPause$.subscribe(() => {
      this.doPause()
    })

    this.adsPodStartedSubscription = this.adsPodStarted$.subscribe((event) => {
      const { player } = this.props
      let startTimeInContent = 0
      // Find the current ads pod and check the watch status
      const isAdWatched = this.state.linearAdRolls?.find(ad => {
        const currentpod = event[0].target.player.ads.pod
        if (ad.index === currentpod.id) {
          this.setState({
            totalAds: currentpod.size,
            currentLinearAdRolls: ad
          })

          if (ad.watched) {
            startTimeInContent = ad.startTimeInContent
            return true
          }
        }

        return false
      })

      if (isAdWatched) {
        // Skip the watched ads pod by set ads start time in content + 0.1 second
        player.ssai().seekInContentTime(startTimeInContent + SKIP_MIDROLL_TIME_OFFSET)
      } else {
        this.setState({
          isLoading: false,
          isPlayingAd: true
        })
      }
    })

    this.adsPodEndedSubscription = this.adsPodEnded$.subscribe((event) => {
      const { linearAdRolls } = this.state
      const ads = linearAdRolls.map(ad => {
        if (ad.index === event[0].target.player.ads.pod.id) {
          ad.watched = true
        }
        return ad
      })

      this.setState({
        linearAdRolls: ads,
        isPlayingAd: false,
        totalAds: 0
      })
    })

    this.adsAdStartedSubscription = this.adsAdStarted$.subscribe(() => {
      this.setState(({ adsCounter }) => ({ adsCounter: adsCounter + 1 }))
    })

    this.playSubscription = this.play$.subscribe(() => {
      this.doPlay()

      this.clearAdOnPauseState()
    })

    this.pauseSubscription = this.pause$.subscribe(() => {
      this.doPause()

      // Add segment data analytics for pausing video
      const { contentId, currentTime, duration } = this.state
      segmentTrackPauseVideo({
        contentId,
        currentTime: Math.round(currentTime),
        duration
      })

      // No AoP if the episode selector opens
      // Request AoP in 1 second
      const { shouldRenderAoP } = this.props
      if (shouldRenderAoP && !this.state.isEpisodeSelectorOpen) {
        clearTimeout(this.adOnPauseTimer)
        this.adOnPauseTimer = setTimeout(() => {
          requestAdOnPause(this.setIsAdOnPauseShown)
        }, AD_ON_PAUSE_TIMEOUT)
      }
    })

    this.refreshSubscription = this.refresh$.subscribe(refreshData => {
      // GPT AD rendering takes some time (performance issue)
      // Clear AoP again on refresh subscription
      if (this.state.isAdOnPauseShown && this.state.isPlaying) {
        this.clearAdOnPauseState()
      }
      this.setState(refreshData)
    })

    this.fullscreenSubscription = this.fullscreenchange$.subscribe(
      isFullscreen => {
        this.setState({ isFullscreen })
      }
    )

    this.waitingSubscription = this.waiting$.subscribe(() => {
      // Only set isLoading as true when no ads play
      if (!this.state.isPlayingAd) {
        this.setState({ isLoading: true }, () => {})
      }
    })

    this.playingSubscription = this.playing$.subscribe(() => {
      if (this.state.isTvodModalShown) {
        this.props.player.pause()
      }
      this.setState({ isLoading: false })
    })

    // For safari/edge, it seems playing$ is not emit correctly when scrubbing,
    // So we use refresh$ to observe the time increment as a fallback
    this.playingSubscriptionForSafariAndEdge = this.refresh$
      .pipe(
        distinctUntilChanged((previous, next) => {
          return (
            Math.round(previous.currentTime) === Math.round(next.currentTime)
          )
        }),
        filter(({ bufferedTime, currentTime }) => {
          return bufferedTime > currentTime
        })
      )
      .subscribe(v => {
        // cancel this subscription when it is not safari/edge
        if (!(getIsSafari() || getIsEdge())) {
          this.playingSubscriptionForSafariAndEdge.unsubscribe()
          return
        }
        this.setState({ isLoading: false })
      })

    this.continuePositionSavingSubscription = this.continuePositionSaving$.subscribe(
      () => {
        this.saveCurrentPosition(PLAYBACK_EVENT_CONTINUE)
      }
    )

    this.getPercentProgressArray = this.createProgressObservables([
      0.25,
      0.5,
      0.75,
      0.95,
      1
    ])

    this.getPlayProgress$ = merge(...this.getPercentProgressArray)
    this.getPlayProgressSubscription = this.getPlayProgress$.subscribe(data => {
      const { currentTime, duration } = head(data)
      this.sendProgressToGA$.next({
        percentage: `${Math.floor((currentTime / duration) * 100)}%`
      })
    })

    this.unblockPlaybackSubscription = this.unblockPlayback$.subscribe(
      contentId => {
        const { player } = this.props
        if (contentId === this.state.contentId) {
          player.play()
        }
      }
    )

    this.isPlaybackReadySubscription = this.initialLoad$.subscribe(
      this.onInitialLoad
    )

    this.showUiSubscription = this.showUi$.subscribe(() => {
      this.setState({ isUiHidden: true })
    })

    this.userActivitySubscription = this.userActivity$.subscribe(event => {
      // Reset continueWatching timer
      if (event.type === 'keydown' || event.type === 'click') {
        timer.reset(event)
      }
    })
  }

  render() {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

StateProvider.propTypes = {
  player: PropTypes.shape({
    currentTime: PropTypes.func.isRequired,
    buffered: PropTypes.func.isRequired,
    duration: PropTypes.func.isRequired,
    play: PropTypes.func.isRequired,
    pause: PropTypes.func.isRequired,
    one: PropTypes.func.isRequired
  }).isRequired,
  contentId: PropTypes.string.isRequired,
  appConfig: appConfigPropType.isRequired,
  isTrailer: PropTypes.bool.isRequired,
  emitCurrentPosition: PropTypes.func.isRequired,
  metaData: PropTypes.shape({
    cuePoints: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        time: PropTypes.number
      })
    )
  }),
  resumePoint: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
    .isRequired,
  continueWatchingThreshold: PropTypes.number.isRequired,
  setIsPlaybackReady: PropTypes.func.isRequired,
  firstPlayback: PropTypes.shape({
    rentalPeriodHours: PropTypes.number,
    viewingPeriodHours: PropTypes.number
  }),
  purchasedFormat: PropTypes.string.isRequired,
  svodStreamingResolution: PropTypes.string,
  isSsaiStream: PropTypes.bool.isRequired,
  shouldRenderAoP: PropTypes.bool.isRequired
}

StateProvider.defaultProps = {
  metaData: null,
  firstPlayback: null,
  svodStreamingResolution: null
}

export default StateProvider
export { StateConsumer }
