import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import color from 'color'
import Imgix from 'react-imgix'
import { Link } from 'react-router-dom'
import { merge, interval, EMPTY } from 'rxjs'
import { switchMap, mapTo, startWith } from 'rxjs/operators'

import { INTERVALS } from '../../../constants'
import {
  PLAY_BUTTON_NEXT_EPISODE_TEXT,
  PLAY_BUTTON_NEXT_SEASON_TEXT,
  CLOSE_BUTTON_WATCH_CREDITS_TEXT
} from './constants'
import { StateConsumer } from '../../../state'

const styles = theme => {
  return {
    wrapper: {
      background: color(theme.color.black)
        .alpha(0.6)
        .toString(),
      display: 'flex',
      width: '100%',
      position: 'fixed',
      bottom: 100,
      zIndex: theme.zIndex.nextEpisodeBanner,
      animationDuration: '300ms',
      animationTimingFunction: 'ease-in',
      animationName: '$fadein',
      opacity: 0,
      pointerEvents: 'auto',
      transition: ['opacity', '300ms', 'ease-in']
    },
    show: {
      opacity: 1
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%'
    },
    title: {
      fontFamily: 'GTWalsheim',
      fontSize: 25,
      fontWeight: 400,
      color: theme.color.white,
      padding: [[0, 50]],
      marginTop: 0,
      marginBottom: 5
    },
    header: {
      fontWeight: 500
    },
    episode: {
      marginTop: 5,
      fontSize: 22,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '70%',
      whiteSpace: 'nowrap'
    },
    seconds: {
      color: theme.color.primary
    },
    playButton: {
      height: 64,
      width: 'auto',
      filter: theme.shadow.filterShadow(theme.color.black).primary
    },
    thumbnail: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 25
    },
    roundedBorder: {
      borderRadius: 4
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      margin: [[0, 50]]
    },
    button: {
      borderRadius: 4,
      padding: [[12, 20]],
      marginRight: 10,
      textAlign: 'center',
      fontFamily: 'GothamRounded',
      fontSize: 18,
      cursor: 'pointer'
    },
    buttonNext: {
      color: theme.color.black,
      backgroundColor: theme.color.primary,

      '&:hover': {
        backgroundColor: color(theme.color.primary)
          .lighten(0.2)
          .toString()
      }
    },
    buttonClose: {
      color: theme.color.white,
      backgroundColor: color(theme.color.white)
        .alpha(0.1)
        .toString(),

      '&:hover': {
        backgroundColor: color(theme.color.white)
          .alpha(0.2)
          .toString()
      }
    },
    '@keyframes fadein': {
      from: { opacity: 0 },
      to: { opacity: 1 }
    }
  }
}

class NextEpisodeCountdownUi extends React.PureComponent {
  constructor(props) {
    super(props)
    this.initialiseCountdown = this.initialiseCountdown.bind(this)
    this.countDown = this.countDown.bind(this)
    this.clearCountDown = this.clearCountDown.bind(this)
    this.dismissCountdown = this.dismissCountdown.bind(this)

    this.state = {
      timeRemaining: props.countdownTime
    }
  }

  componentDidMount() {
    this.initialiseCountdown()
  }

  componentWillUnmount() {
    this.clearCountDown()
  }

  initialiseCountdown() {
    const { getStreams, isPlaying } = this.props
    const countdown$ = interval(INTERVALS.NEXT_EPISODE_COUNTDOWN)
    const pauseEvent$ = getStreams().pause$.pipe(mapTo(false))
    const playEvent$ = getStreams().play$.pipe(mapTo(true))
    const counting$ = merge(pauseEvent$, playEvent$).pipe(
      startWith(isPlaying),
      // playing is the mapped value from pauseEvent$ or playEvent$
      // and will stop the countdown ticking while player is paused
      switchMap(playing => (playing ? countdown$ : EMPTY))
    )

    this.countingSubscription = counting$.subscribe(() => {
      this.countDown(this.clearCountDown)
    })
  }

  countDown(cancelSubscription) {
    const {
      id,
      redirectToEpisodeById,
      getShouldDisplayContinueWatching,
      showContinueWatching
    } = this.props
    const nextValue = this.state.timeRemaining - 1

    if (nextValue < 0) {
      if (getShouldDisplayContinueWatching()) {
        showContinueWatching()
      } else if (id) {
        redirectToEpisodeById(id)
        cancelSubscription()
      }
    } else {
      this.setState({
        timeRemaining: nextValue
      })
    }
  }

  clearCountDown() {
    this.countingSubscription.unsubscribe()
  }

  dismissCountdown() {
    const { dismissBanner } = this.props
    dismissBanner()
    this.clearCountDown()
  }

  render() {
    const pluralise = this.state.timeRemaining !== 1 ? 's' : ''
    const {
      classes,
      id,
      nextEpisodeNumber,
      nextEpisodeSeason,
      currentSeason,
      title,
      imageUrl,
      setShouldUseCuePoint
    } = this.props

    const episodeOrSeason =
      nextEpisodeSeason > currentSeason ? 'season' : 'episode'

    const ctaPrefix = nextEpisodeSeason > currentSeason
      ? PLAY_BUTTON_NEXT_SEASON_TEXT
      : PLAY_BUTTON_NEXT_EPISODE_TEXT

    return (
      <StateConsumer>
        {({
          isAdOnPauseShown,
          isEpisodeSelectorOpen
        }) => {
          return (
            <div
              className={classnames(classes.wrapper, {
                [classes.show]: !isAdOnPauseShown && !isEpisodeSelectorOpen
              })}
            >
              <div className={classes.thumbnail}>
                <Imgix src={imageUrl} width={290} height={164} className={classes.roundedBorder} />
              </div>
              <div className={classes.info}>
                <h2 className={classnames(classes.title, classes.header)}>
                  {`Next ${episodeOrSeason} in `}
                  <span>
                    {`${
                      this.state.timeRemaining
                    } second${pluralise}`}
                  </span>
                </h2>
                <h3 className={classnames(classes.title, classes.episode)}>
                  {`S${nextEpisodeSeason} E${nextEpisodeNumber} '${title}'`}
                </h3>
                <div className={classes.buttonWrapper}>
                  <Link
                    to={id}
                    className={classnames(classes.button, classes.buttonNext)}
                    onClick={() => {
                      setShouldUseCuePoint()
                    }}
                  >
                    <span>{ctaPrefix}</span>
                  </Link>
                  <div className={classnames(classes.button, classes.buttonClose)} onClick={this.dismissCountdown}>
                    {CLOSE_BUTTON_WATCH_CREDITS_TEXT}
                  </div>
                </div>
              </div>
            </div>
          )
        }}
      </StateConsumer>
    )
  }
}

NextEpisodeCountdownUi.propTypes = {
  id: PropTypes.string.isRequired,
  nextEpisodeSeason: PropTypes.number.isRequired,
  nextEpisodeNumber: PropTypes.number.isRequired,
  currentSeason: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  getStreams: PropTypes.func.isRequired,
  countdownTime: PropTypes.number.isRequired,
  redirectToEpisodeById: PropTypes.func.isRequired,
  getShouldDisplayContinueWatching: PropTypes.func.isRequired,
  showContinueWatching: PropTypes.func.isRequired,
  setShouldUseCuePoint: PropTypes.func.isRequired,
  dismissBanner: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired
}

export default withStyles(styles)(NextEpisodeCountdownUi)
