import React from 'react'

/**
 * We find it is annoying to have to put all values
 * into the `value` prop of React Context api
 *
 * Because everytime a new value passed in,
 * there shall be a new `value` object created
 * thus made the component to re-render.
 *
 * This createContext will use React prop check reference to see if it requires re-render
 *
 * @param {Object} defaultValue
 */
function createContext(defaultValue) {
  const { Provider, Consumer } = React.createContext(defaultValue)

  return {
    Provider: class BetterProvider extends React.PureComponent {
      render() {
        const { children, ...others } = this.props
        return <Provider value={others}>{children}</Provider>
      }
    },
    Consumer
  }
}

export default createContext
