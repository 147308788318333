import React from 'react'
import PropTypes from 'prop-types'
import { apm } from '@elastic/apm-rum'
import getDeviceInfo from '../../../lib/device-info'
import { AccountQuery, ContentItemQuery } from '../utils/query'
import getContentFromQuery from '../utils/get-content-from-query'
import getContentType from '../utils/get-content-type'
import { readDefaultQualityFromAppConfig } from '../utils/quality-selector'
import { appConfigPropType } from '../utils/prop-types'
import Youbora from './youbora'
import withConfigCacheOnly from '../../../hoc/with-config'

class YouboraData extends React.PureComponent {
  render() {
    const {
      id, isTrailer, appConfig, player
    } = this.props
    const deviceInfo = getDeviceInfo()
    const isGuest = !localStorage.getItem('token')

    // TODO: The quality change won't reflect on youbora tracking
    // For Custom Dimensions > Data Saver
    // It will read the initial quality when player instance intialized.
    // when player is not disposed, it won't update the value
    // But if a new Youbora plugin is created, new value will be extracted
    // and attach to the new plugin instance.
    const currentQuality = readDefaultQualityFromAppConfig(appConfig)
    return (
      <ContentItemQuery id={id}>
        {contentItemQuery => {
          if (contentItemQuery.loading) {
            return null
          }

          if (contentItemQuery.error) {
            apm.captureError(contentItemQuery.error)
            return null
          }

          const contentItemPayload = getContentFromQuery(contentItemQuery.data)
          const contentType = getContentType(contentItemPayload, isTrailer)
          const { contentItem } = contentItemQuery.data
          const { title } = contentItemPayload
          // ldid for content, id for trailers
          const houseId = contentItem.ldId || contentItem.id

          if (isGuest) {
            return (
              <Youbora
                player={player}
                options={{
                  currentQuality,
                  contentItem,
                  title,
                  houseId,
                  contentType,
                  deviceInfo
                }}
              />
            )
          }

          return (
            <AccountQuery>
              {accountQuery => {
                if (accountQuery.loading) {
                  return null
                }

                if (accountQuery.error) {
                  // error log to let new relic capture it.
                  console.error(accountQuery.error)
                  return null
                }

                const { account } = accountQuery.data

                return (
                  <Youbora
                    player={player}
                    options={{
                      account,
                      currentQuality,
                      contentItem,
                      title,
                      houseId,
                      contentType,
                      deviceInfo
                    }}
                  />
                )
              }}
            </AccountQuery>
          )
        }}
      </ContentItemQuery>
    )
  }
}

YouboraData.propTypes = {
  id: PropTypes.string.isRequired,
  isTrailer: PropTypes.bool.isRequired,
  appConfig: appConfigPropType.isRequired,
  player: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default withConfigCacheOnly(YouboraData)
