// TODO: dispose players after player is disposed
import {
  toPairs, takeLast, compose, map, flatten, isNil, last
} from 'ramda'
import { VHS_PLAYER } from '../../../constants'

/**
 * Convert {a:1, b:2} to [1, 2]
 */
const convertToPlayerArray = compose(
  flatten,
  map(takeLast(1)),
  toPairs
)

/**
 *
 * @param {Element} videoTag
 * @param {Object} config
 *
 * This will create a structure like
 * ```
 * <div class="lbx-player">
 *  <videojs/> <- videojs components
 *  <Ui/> <- React components
 * </div>
 *```
 *
 * After dispose, we want the html back to
 * ```
 * <div class="lbx-player"></div>
 * ```
 */
export function setupBcPlayer(anchor, config, onBCPlayerError) {
  const { bc, videojs } = window
  if (!bc) {
    // TODO proper error handling?
    throw new Error('video player script is not correctly loaded')
  }

  const players = convertToPlayerArray(videojs.players)
  let player
  // for next episode, we reuse the player
  if (players.length > 0 && !isNil(last(players)) && last(players).id !== VHS_PLAYER.id) {
    player = last(players)
    player.el().hidden = false
  } else {
    const videoTag = prepareVideoTag(anchor)
    // Otherwise, we create new instance of player
    player = bc(videoTag)
    player.addClass(config.rootClassName)
    player.errorDisplay.addClass(config.errorDisplayClassName)
    player.textTrackDisplay.addClass(config.textTrackClassName)
    player.loadingSpinner.hide()
    player.one('error', onBCPlayerError)
  }

  return player
}

/**
 *
 * @param {Element} anchor
 *
 * Create video tag within the anchor
 * From
 * ```
 * <div class="lbx-player"></div>
 * ```
 * To
 * ```
 * <div class="lbx-player">
 *  <video />
 * </div>
 * ```
 *
 */
function prepareVideoTag(anchor) {
  const video = document.createElement('video')
  anchor.appendChild(video)
  return video
}
