import React from 'react'
import Imgix from 'react-imgix'
import PropTypes from 'prop-types'
import withStyle from 'react-jss'
import classnames from 'classnames'
import color from 'color'

import {
  IMAGE_WIDTH,
  IMAGE_RATIO,
  BORDER
} from '../constants'

const styles = theme => {
  return {
    image: {
      position: 'relative',
      cursor: 'pointer',
      transition: [['border', '300ms']],
      margin: -BORDER,
      border: [
        [
          BORDER,
          'solid',
          color(theme.color.white)
            .alpha(0)
            .toString()
        ]
      ],
      borderRadius: '6px',
      zIndex: theme.zIndex.carouselImg
    },
    error: {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'block',
        background: theme.color.black
      }
    },
    current: {
      border: [[BORDER, 'solid', theme.color.white]]
    },
    active: {
      border: [[BORDER, 'solid', theme.color.primary]]
    }
  }
}

class Image extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleError = this.handleError.bind(this)
    this.state = {
      imgError: false
    }
  }

  handleError() {
    const { onLoadError } = this.props
    onLoadError()
    this.setState({ alt: '', imgError: true })
  }

  render() {
    const {
      classes,
      onLoad,
      onLoadError,
      isPrimaryColorBorder,
      isBorderShown,
      src,
      ...others
    } = this.props
    return (
      <Imgix
        className={classnames(classes.image, {
          [classes.current]: isPrimaryColorBorder,
          [classes.active]: isBorderShown,
          [classes.error]: this.state.imgError
        })}
        height={IMAGE_WIDTH / IMAGE_RATIO}
        width={IMAGE_WIDTH}
        src={src}
        htmlAttributes={{
          draggable: false,
          onLoad,
          alt: this.state.alt,
          onError: this.handleError,
          ...others
        }}
      />
    )
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  onLoadError: PropTypes.func.isRequired,
  isPrimaryColorBorder: PropTypes.bool.isRequired,
  isBorderShown: PropTypes.bool.isRequired
}

export default withStyle(styles)(Image)
