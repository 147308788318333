import React from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'

import { StateConsumer } from '../../state'
import Query from '../../lib/query'
import nextEpisodeQuery from './next-episode-query.gql'
import { convertData } from '../episode-selection'

function NextEpisodeQuery({ children, contentId, ...others }) {
  if (contentId) {
    return (
      <Query {...others} query={nextEpisodeQuery} variables={{ contentId }}>
        {passToChildren(children)}
      </Query>
    )
  }

  return (
    <StateConsumer>
      {({ contentId: contentIdFromState }) => {
        return (
          <Query
            {...others}
            query={nextEpisodeQuery}
            variables={{ contentId: contentIdFromState }}
          >
            {passToChildren(children)}
          </Query>
        )
      }}
    </StateConsumer>
  )
}

NextEpisodeQuery.propTypes = {
  contentId: PropTypes.string
}

NextEpisodeQuery.defaultProps = {
  contentId: null
}

function passToChildren(children) {
  return ({ data, loading, error }) => {
    if (loading || error) {
      return children({ data: null, loading, error })
    }

    // return null for trailer and movie
    const isTrailerOrMovie = !data.contentItem.series

    if (!data.contentItem.series) {
      return children({
        data: { isTrailerOrMovie }
      })
    }

    const episodeInfo = convertData(data)
    return children({
      data: { isTrailerOrMovie, id: path(['nextEpisode', 'id'])(episodeInfo) }
    })
  }
}

export default NextEpisodeQuery
