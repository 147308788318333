import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { compose } from 'recompose'
import color from 'color'

import SlipNSlide from '../../../../../../../lib/slip-n-slide'
import {
  slipnslideIsMobile,
  slipnslideCalcTileShowCount,
  slipnslideGetMargin
} from '../../../../../../lib/utils'
import { withScreenWidthWatching } from '../../../../../../hoc/with-screen-width-watching'
import TileFields from '../../../../../../../graphql/fragments/tile.gql'
import { getTileRecipe } from '../../../../../imgix/recipes'
import Tile from './tile'

const styles = theme => {
  return {
    wrapper: {
      overflow: 'hidden'
    },
    arrow: {
      backgroundColor: color(theme.color.dark)
        .alpha(0.3)
        .toString(),
      '&:hover': {
        backgroundColor: color(theme.color.dark)
          .alpha(0.5)
          .toString()
      },

      '& svg': {
        fill: theme.color.light
      }
    }
  }
}

class Carousel extends React.PureComponent {
  render() {
    const {
      tiles, type, screenWidth, classes, header
    } = this.props
    const recipe = getTileRecipe(type)
    const tileCount = slipnslideCalcTileShowCount(screenWidth, 2)
    const margin = slipnslideGetMargin(screenWidth)

    return (
      <div className={classes.wrapper}>
        <SlipNSlide
          duration={tileCount * 200}
          slidesToShow={tileCount}
          swipeMode={slipnslideIsMobile}
          margin={margin}
          arrowClass={classes.arrow}
          ref={elem => {
            this.carousel = elem
          }}
        >
          {tiles.map((tile, index) => (
            <Tile key={tile.image} tile={tile} recipe={recipe} header={header} position={index} />
          ))}
        </SlipNSlide>
      </div>
    )
  }
}

Carousel.propTypes = {
  tiles: PropTypes.arrayOf(propType(TileFields)).isRequired,
  type: PropTypes.oneOf([
    'Title',
    'Series',
    'Episode',
    'TITLE',
    'SERIES',
    'Movie4x3',
    'CHARACTER'
  ]).isRequired,
  screenWidth: PropTypes.number.isRequired,
  header: PropTypes.string.isRequired
}

const enhance = compose(
  withScreenWidthWatching,
  withStyles(styles)
)

export default enhance(Carousel)
