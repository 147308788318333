const getProgressPayload = percentage => {
  return {
    event: 'trackEvent',
    'eventDetails.category': 'video watch',
    'eventDetails.label': window.location.href,
    'eventDetails.action': percentage,
    videoWatched: percentage === '95%' ? 'yes' : 'no'
  }
}

export default getProgressPayload
