import React from 'react'
import PropTypes from 'prop-types'

import { interval } from 'rxjs'
import { getTextTrack } from '../../../utils/text-track'
import { INTERVALS } from '../../../constants'

class CheckIfTextTrackExists extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = {
    isTextTrackExist: false,
    textTracks: null
  }

  componentDidMount() {
    this.showTextTracksSubscription = this.initializeTextTrackSubscription()
  }

  componentWillUnmount() {
    this.showTextTracksSubscription.unsubscribe()
  }

  // eslint-disable-next-line no-undef
  setTextTrackExist = textTracks => {
    this.setState({
      isTextTrackExist: true,
      textTracks
    })
  }

  // eslint-disable-next-line no-undef
  initializeTextTrackSubscription = () => {
    const subscription = interval(INTERVALS.TEXT_TRACK_EXIST_CHECK).subscribe(counter => {
      const { getTextTrackFromPlayer } = this.props

      if (counter >= 10 * 5) {
        subscription.unsubscribe()
      }
      const tracks = getTextTrackFromPlayer()

      // There's usually at least one track for metadata, which we don't care about,
      // so when there's more than 2 tracks, we should kick in and run `getTextTrack`.
      // The check is to make the function fail fast thus have better performance
      // because `getTextTrack` uses an iteration inside
      if (!tracks || tracks.length < 2) return

      const textTracks = getTextTrack(tracks)
      if (textTracks) {
        this.setTextTrackExist(textTracks)

        subscription.unsubscribe()
      }
    })

    return subscription
  }

  render() {
    const { children } = this.props
    if (!this.state.isTextTrackExist) return null
    return children(this.state.textTracks)
  }
}

CheckIfTextTrackExists.propTypes = {
  getTextTrackFromPlayer: PropTypes.func.isRequired
}

export default CheckIfTextTrackExists
