import React from 'react'
import PropTypes from 'prop-types'
import { AnalyticsDataConsumer } from '../analytics-data'
import { StateConsumer } from '../state'
import ChromecastCore from './chromecast-core'

import withInitializeCastSender from '../../../hoc/with-castPlayer-sender-initialize'

class Chromecast extends React.PureComponent {
  render() {
    const {
      children,
      showPageUrl,
      maybeInitializeCastSender,
      client
    } = this.props
    return (
      <StateConsumer>
        {state => (
          <AnalyticsDataConsumer>
            {data => (
              <ChromecastCore
                contentId={state.contentId}
                deviceInfo={data.deviceInfo}
                account={data.account}
                contentItem={data.contentItem}
                contentType={data.contentType}
                client={client}
                maybeInitializeCastSender={maybeInitializeCastSender}
                showPageUrl={showPageUrl}
              >
                {children}
              </ChromecastCore>
            )}
          </AnalyticsDataConsumer>
        )}
      </StateConsumer>
    )
  }
}

Chromecast.propTypes = {
  showPageUrl: PropTypes.string.isRequired,
  maybeInitializeCastSender: PropTypes.func.isRequired
}

export default withInitializeCastSender(Chromecast)
