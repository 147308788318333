import React from 'react'
import PropTypes from 'prop-types'
import color from 'color'
import withStyles from 'react-jss'
import classnames from 'classnames'

const styles = theme => {
  return {
    overlay: {
      zIndex: theme.zIndex.modal,
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: color(theme.color.black)
        .alpha(0.3)
        .toString(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 1,
      transition: [['opacity', '200ms', 'ease-in']]
    },
    hide: {
      opacity: 0,
      pointerEvents: 'none'
    }
  }
}

class ModalWrapper extends React.PureComponent {
  render() {
    const { children, classes, isShown } = this.props
    return (
      <div
        className={classnames(classes.overlay, {
          [classes.hide]: !isShown
        })}
      >
        {children}
      </div>
    )
  }
}

ModalWrapper.propTypes = {
  isShown: PropTypes.bool.isRequired
}

export default withStyles(styles)(ModalWrapper)
