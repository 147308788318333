import React from 'react'
import WithStyles from 'react-jss'
import {
  compose, defaultTo, join, take, pathOr
} from 'ramda'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Clamped from '../../../../../../shared/clamped'
import { TilePropTypes } from '../../prop-types'
import { playerDataLayerProductClick } from '../../../../../ga/utils'

const styles = theme => {
  return {
    wrapper: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.color.dark,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: ['opacity', 'ease-out', '500ms'],
      color: theme.color.light,
      cursor: 'pointer',
      textAlign: 'center',
      border: [2, 'solid', theme.color.primary],
      '&:hover': {
        opacity: 1
      },
      margin: 0,
      padding: [[10, 12]]
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 2,
      height: 'auto'
    },
    season: {
      textTransform: 'capitalize',
      fontSize: 12,
      fontWeight: 400,
      flexGrow: 1
    },
    genres: {
      fontFamily: 'GothamRounded',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '21px',
      fontStyle: 'italic',
      flexGrow: 1
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    button: {
      backgroundColor: theme.color.primary,
      borderRadius: 2,
      fontSize: 14,
      fontFamily: 'GothamRounded',
      color: theme.color.black,
      padding: [8, 32],
      textAlign: 'center',
      '&:hover': {
        backgroundColor: [theme.color.primary, 'lightness(+30 %)']
      }
    },
    [theme.breakpoint.tablet]: {
      title: {
        fontSize: 16
      },
      button: {
        padding: [8, 42]
      },
      wrapper: {
        padding: [30, 12]
      }
    },
    [theme.breakpoint.desktopXLarge]: {
      title: {
        fontSize: 18
      },
      year: {
        fontSize: 16
      },
      genres: {
        fontSize: 16
      },
      wrapper: {
        padding: [30, 12, 70]
      },
      duration: {
        fontSize: 16
      },
      price: {
        fontSize: 18
      }
    }
  }
}

function SeriesOverlay({
  tile, classes, header, position
}) {
  const contentItem = tile.contentItem
  const title = contentItem.title

  const genres = compose(
    join(', '),
    take(2),
    defaultTo([])
  )(contentItem.genres)
  const url = pathOr('', ['contentItem', 'path'])(tile)

  return (
    <Link className={classes.wrapper} to={url} onClick={() => { playerDataLayerProductClick(contentItem, position, header) }}>
      <div className={classes.title}>
        <Clamped>{title}</Clamped>
      </div>
      <div className={classes.season}>
        <Clamped lines={1}>{tile.subHeader}</Clamped>
      </div>
      <div className={classes.genres}>
        <Clamped lines={1}>{genres}</Clamped>
      </div>
      <div className={classes.buttonWrapper}>
        <div className={classes.button}>More Info</div>
      </div>
    </Link>
  )
}

SeriesOverlay.propTypes = {
  tile: TilePropTypes.isRequired,
  header: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired
}

export default WithStyles(styles)(SeriesOverlay)
