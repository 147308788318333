import React from 'react'
import PropTypes from 'prop-types'

import { setObservableConfig } from 'recompose'
import { from } from 'rxjs'

import ScriptLoader from './script-loader'
import Core from './core'
import ErrorHandler from './error-handler'
import { YouboraData } from './youbora'
import Ga from './ga'
import Chromecast from './chromecast'
import Ui from './ui'
import Control, { ControlConsumer } from './control'
import Meta from './meta'
import State, { StateConsumer } from './state'
import { urlChange$ } from './state-stream'
import AnalyticsData from './analytics-data'
import { history } from '../../store'
import { isPathTrailer } from './utils/is-path-trailer'
import PlayerThemeProvider from './theme/player-theme-provider'
import AdsCounter from './ads-counter'
import SkipIntro from './skip-intro'
import { AdOnPause, AdOnPauseHeader } from '../google-publisher-tag'
import { shouldShowAoP } from '../google-publisher-tag/utils'

setObservableConfig({
  fromESObservable: from,
  toESObservable: stream => stream
})

class Player extends React.Component {
  constructor(props) {
    super(props)

    this.init = this.init.bind(this)
    this.setShouldUseCuePoint = this.setShouldUseCuePoint.bind(this)
    this.setShouldNotUseCuePoint = this.setShouldNotUseCuePoint.bind(this)
    this.getIsStartFromCuePoint = this.getIsStartFromCuePoint.bind(this)
    this.getIsPlayerInitialized = this.getIsPlayerInitialized.bind(this)
    this.setIsPlaybackReady = this.setIsPlaybackReady.bind(this)
    this.setIsCuePointLoaded = this.setIsCuePointLoaded.bind(this)

    this.init()

    this.unlistenUrlChange = history.listen(() => {
      this.init()
    })
  }

  shouldComponentUpdate(props) {
    return this.props.location !== props.location
  }

  componentWillUnmount() {
    this.unlistenUrlChange()
  }

  setShouldUseCuePoint() {
    this.isStartFromCuePoint = true
  }

  setShouldNotUseCuePoint() {
    this.isStartFromCuePoint = false
  }

  getIsStartFromCuePoint() {
    return this.isStartFromCuePoint
  }

  setIsCuePointLoaded() {
    this.isCuePointLoaded = true
  }

  setIsPlaybackReady() {
    this.isPlaybackReady = true
  }

  getIsPlayerInitialized() {
    return this.isPlaybackReady && this.isCuePointLoaded
  }

  init() {
    this.isPlaybackReady = false
    this.isCuePointLoaded = false
    urlChange$.next()
  }

  render() {
    const anchor = document.getElementsByClassName('lbx-player')[0]
    // videoExtraId - trailer
    // contentId - normal content
    const { contentId, videoExtraId } = this.props.match.params
    const isTrailer = isPathTrailer(location.href)
    const id = videoExtraId || contentId
    return (
      <PlayerThemeProvider>
        <ScriptLoader>
          <ErrorHandler>
            {onBCPlayerError => {
              return (
                <Core
                  anchor={anchor}
                  onBCPlayerError={onBCPlayerError}
                >
                  {({ player }) => {
                    return (
                      <>
                        <YouboraData
                          id={id}
                          isTrailer={isTrailer}
                          player={player}
                        />
                        <Meta
                          id={id}
                          player={player}
                          isTrailer={isTrailer}
                          getIsStartFromCuePoint={this.getIsStartFromCuePoint}
                          setIsCuePointLoaded={this.setIsCuePointLoaded}
                        >
                          {({
                            appConfig,
                            emitCurrentPosition,
                            metaData,
                            resumePoint,
                            continueWatchingThreshold,
                            isGuest,
                            firstPlayback,
                            purchasedFormat,
                            svodStreamingResolution,
                            isSsaiStream,
                            adOnPauseParams,
                            isKid,
                            contentItem
                          }) => {
                            const shouldRenderAoP = shouldShowAoP(adOnPauseParams, isKid)
                            return (
                              <State
                                continueWatchingThreshold={
                                  continueWatchingThreshold
                                }
                                resumePoint={resumePoint}
                                metaData={metaData}
                                isTrailer={isTrailer}
                                player={player}
                                contentId={id}
                                appConfig={appConfig}
                                emitCurrentPosition={emitCurrentPosition}
                                setIsPlaybackReady={this.setIsPlaybackReady}
                                getIsPlayerInitialized={
                                  this.getIsPlayerInitialized
                                }
                                firstPlayback={firstPlayback}
                                purchasedFormat={purchasedFormat}
                                svodStreamingResolution={svodStreamingResolution}
                                isSsaiStream={isSsaiStream}
                                shouldRenderAoP={shouldRenderAoP}
                                contentItem={contentItem}
                              >
                                <Control
                                  player={player}
                                  playbackQualityOptions={
                                    appConfig.playbackQualityOptions
                                  }
                                  setShouldUseCuePoint={
                                    this.setShouldUseCuePoint
                                  }
                                  setShouldNotUseCuePoint={
                                    this.setShouldNotUseCuePoint
                                  }
                                  getIsPlayerInitialized={
                                    this.getIsPlayerInitialized
                                  }
                                >
                                  <ControlConsumer>
                                    {({ showPageUrl }) => (
                                      <StateConsumer>
                                        {({
                                          currentQuality,
                                          getStreams,
                                          duration,
                                          setIsAdOnPauseShown
                                        }) => {
                                          return (
                                            <AnalyticsData
                                              isGuest={isGuest}
                                              playbackQuality={currentQuality}
                                              id={id}
                                              contentId={contentId}
                                              isTrailer={isTrailer}
                                              duration={duration}
                                            >
                                              <Ga getStreams={getStreams}>
                                                <Chromecast
                                                  showPageUrl={showPageUrl}
                                                >
                                                  <AdsCounter />
                                                  <SkipIntro />
                                                  {
                                                    shouldRenderAoP && (
                                                      <>
                                                        <AdOnPauseHeader setIsAdOnPauseShown={setIsAdOnPauseShown} />
                                                        <AdOnPause adOnPauseParams={adOnPauseParams} />
                                                      </>
                                                    )
                                                  }
                                                  <Ui />
                                                </Chromecast>
                                              </Ga>
                                            </AnalyticsData>
                                          )
                                        }}
                                      </StateConsumer>
                                    )}
                                  </ControlConsumer>
                                </Control>
                              </State>
                            )
                          }}
                        </Meta>
                      </>
                    )
                  }}
                </Core>
              )
            }}
          </ErrorHandler>
        </ScriptLoader>
      </PlayerThemeProvider>
    )
  }
}

Player.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      contentId: PropTypes.string.isRequired,
      videoExtraId: PropTypes.string
    }).isRequired
  }).isRequired
}

Player.defaultProps = {
  videoExtraId: ''
}

export default Player
