import { compose, head, path } from 'ramda'

export function getContentData(data) {
  const getTitle = path(['contentItem'])
  const getEpisode = path(['contentItem', 'series'])
  const getTrailer = compose(
    head,
    path(['contentItem', 'contentItems'])
  )
  switch (data.contentItem.__typename) {
    case 'VideoExtra':
      return getTrailer(data)
    case 'Episode':
      return getEpisode(data)
    case 'Title':
      return getTitle(data)
    default:
      throw new Error('unknown content type')
  }
}

export function getEpisodeRating(data) {
  const getRating = path(['contentItem', 'rating'])

  if (data.contentItem.__typename === 'Episode') {
    return getRating(data)
  }

  return null
}
