import color from 'color'

function boxShadow(inputColor) {
  return {
    primary: [
      [
        1,
        1,
        24,
        color(inputColor)
          .alpha(0.4)
          .toString()
      ]
    ],
    secondary: [
      1,
      1,
      4,
      color(inputColor)
        .alpha(0.2)
        .toString()
    ]
  }
}

function textShadow(inputColor) {
  return {
    primary: [
      [
        '1px',
        '1px',
        '1px',
        color(inputColor)
          .alpha(0.3)
          .toString()
      ]
    ]
  }
}

function filterShadow(inputColor) {
  return {
    primary: `drop-shadow(1px 1px 1px ${color(inputColor)
      .alpha(0.3)
      .toString()})`
  }
}

export default { boxShadow, textShadow, filterShadow }
