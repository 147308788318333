export function shouldShowBanner(
  getMetaData,
  duration,
  currentTime,
  isContinueWatchingShown,
  countdownThreshold,
  countdownTime
) {
  if (duration === 0) return false
  if (isContinueWatchingShown) return false
  const roundedCurrentTime = Math.floor(currentTime)

  // using up_next of cue_points if up_next exists
  const metaData = getMetaData()
  const cuePoints = metaData.cuePoints
  const upNext = cuePoints.find(cp => cp.name === 'up_next')
  if (upNext) {
    const roundedUpNextTime = Math.floor(upNext.time)
    if (duration - roundedUpNextTime <= countdownTime) return false
    return roundedCurrentTime >= roundedUpNextTime
  }

  // using PLAYER_AUTO_PLAY_THRESHOLD of config if up_next of cue_points not exists
  const roundedEndTime = Math.floor(countdownThreshold * duration)
  if (duration - roundedEndTime <= countdownTime) return false
  return roundedCurrentTime >= roundedEndTime
}
