import React from 'react'
import withStyles from 'react-jss'

const styles = theme => {
  return {
    error: {
      color: theme.color.white,
      fontFamily: ['"GothamRounded"', '!important'],
      position: 'fixed',
      textAlign: 'center',
      transform: 'translate(-50%)',
      top: '50%',
      left: '50%'
    }
  }
}

class BCErrorHandlerModal extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.error}>
        Oops! Something went wrong. Please refresh the page and try again.
      </div>
    )
  }
}

export default withStyles(styles)(BCErrorHandlerModal)
