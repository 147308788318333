import React from 'react'
import { ThemeProvider } from 'react-jss'

import theme from '.'

const PlayerThemeProvider = ({ children }) => {
  const playerTheme = theme.neon

  return <ThemeProvider theme={playerTheme}>{children}</ThemeProvider>
}

export default PlayerThemeProvider
