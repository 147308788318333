const VOLUME_VALUE_KEY = 'lbx:player:volume'
const VOLUME_MUTE_VALUE_KEY = 'lbx:player:is_mute'
const QUALITY_PRESET_KEY = 'lbx:player:quality'

export const playbackQualityStore = {
  key: QUALITY_PRESET_KEY,
  set: id => {
    localStorage.setItem(QUALITY_PRESET_KEY, id)
  },
  get: () => {
    return localStorage.getItem(QUALITY_PRESET_KEY)
  }
}

export const volumeStore = {
  key: VOLUME_VALUE_KEY,
  /**
   * @param {Number} volume
   */
  set: volume => {
    localStorage.setItem(VOLUME_VALUE_KEY, volume)
  },
  get: () => {
    return isNaN(parseFloat(localStorage.getItem(VOLUME_VALUE_KEY)))
      ? 1
      : parseFloat(localStorage.getItem(VOLUME_VALUE_KEY))
  }
}

export const volumeMuteStore = {
  key: VOLUME_MUTE_VALUE_KEY,
  /**
   * @param {Boolean} isMute
   */
  set: isMute => {
    localStorage.setItem(VOLUME_MUTE_VALUE_KEY, isMute)
  },
  get: () => {
    return localStorage.getItem(VOLUME_MUTE_VALUE_KEY) === 'true'
  }
}
