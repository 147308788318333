import { Subject } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { volumeMuteStore, volumeStore } from '../../../../utils/storage'

class VolumeLocalStorage {
  constructor() {
    this.isVolumeMute$ = new Subject()
    this.volume$ = new Subject()

    this.register = this.register.bind(this)
    this.unRegister = this.unRegister.bind(this)
    this.saveVolumeToLocalStorage = this.saveVolumeToLocalStorage.bind(this)
    this.saveIsVolumeMuteToLocalStorage = this.saveIsVolumeMuteToLocalStorage.bind(
      this
    )
  }

  saveVolumeToLocalStorage() {
    this.volume$.next()
  }

  saveIsVolumeMuteToLocalStorage() {
    this.isVolumeMute$.next()
  }

  register(component) {
    this.volumeSubscription = this.volume$
      .pipe(debounceTime(500))
      .subscribe(() => {
        volumeStore.set(component.state.volume)
      })

    this.isVolumeMuteSubscription = this.isVolumeMute$
      .pipe(debounceTime(500))
      .subscribe(() => {
        volumeMuteStore.set(component.state.isVolumeMute)
      })
  }

  unRegister() {
    this.volumeSubscription.unsubscribe()
    this.isVolumeMuteSubscription.unsubscribe()
  }
}

export default new VolumeLocalStorage()
