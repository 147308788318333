import React from 'react'
import withStyles from 'react-jss'
import color from 'color'
import PropTypes from 'prop-types'
import { T } from 'ramda'
import PlayToggle from './play-toggle'
import ProgressBar from './progress-bar'
import FullscreenToggle from './fullscreen-toggle'
import TimeDisplay from './time-display'
import Rewind from './rewind'
import SettingsButton from './settings-button'
import ChromeCastButton from './chromecast-button'
import NextEpisode from './next-episode'
import VolumeControl from './volume-control'
import TextTrackToggle from './text-track-toggle'

const styles = theme => {
  return {
    wrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      color: theme.color.white,
      width: '100%',
      background: `linear-gradient(to bottom, transparent, 60%, ${color(
        theme.color.black
      )
        .alpha(0.5)
        .toString()})`,
      order: 2
    },
    control: {
      margin: [[0, 12]],
      height: 48,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    controlBucket: {
      display: 'flex',
      flexDirection: 'row'
    },
    left: {
      composes: '$controlBucket'
    },
    right: {
      composes: '$controlBucket'
    }
  }
}

class Footer extends React.PureComponent {
  render() {
    const {
      classes, isPlayingAd, isTextTrackShown, saveCloseCaption
    } = this.props
    return (
      <div className={classes.wrapper}>
        <ProgressBar />
        <div className={classes.control}>
          <div className={classes.left}>
            <PlayToggle />
            {!isPlayingAd && <Rewind />}
            {!isPlayingAd && <NextEpisode />}
            <VolumeControl />
            <TimeDisplay />
          </div>
          <div className={classes.right}>
            {!isPlayingAd && <ChromeCastButton />}
            {!isPlayingAd && <TextTrackToggle isTextTrackShown={isTextTrackShown} saveCloseCaption={saveCloseCaption} />}
            {!isPlayingAd && <SettingsButton />}
            <FullscreenToggle />
          </div>
        </div>
      </div>
    )
  }
}

Footer.propTypes = {
  isPlayingAd: PropTypes.bool,
  isTextTrackShown: PropTypes.bool,
  saveCloseCaption: PropTypes.func
}

Footer.defaultProps = {
  isPlayingAd: false,
  isTextTrackShown: false,
  saveCloseCaption: T
}

export default withStyles(styles)(Footer)
