import React from 'react'
import PropTypes from 'prop-types'
import AuthQuery from '../../lib/auth-query'
import contentItemQuery from './content-item-query.gql'

class ContentItemQuery extends React.PureComponent {
  render() {
    const { children, id } = this.props
    // the reason of using 'no-cache', https://skynz.atlassian.net/browse/LBX-8508
    // and apollo doc: https://www.apollographql.com/docs/react/api/react/hoc#optionsfetchpolicy
    return (
      <AuthQuery query={contentItemQuery} variables={{ contentId: id }} fetchPolicy="no-cache">
        {children}
      </AuthQuery>
    )
  }
}

ContentItemQuery.propTypes = {
  id: PropTypes.string.isRequired
}

export default ContentItemQuery
