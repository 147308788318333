import React from 'react'
import withStyles from 'react-jss'
import classnames from 'classnames'
import { componentFromStream, createEventHandler } from 'recompose'
import {
  combineLatest,
  startWith,
  mapTo,
  debounce,
  distinctUntilChanged
} from 'rxjs/operators'
import { merge, timer, of } from 'rxjs'
import { Link } from 'react-router-dom'
import { GaConsumer } from '../../../ga/ga-core'
import { ShowPageQuery } from '../../../utils/query'
import BackIcon from './back-icon'
import {
  isHighRoadFreeEpisodes,
  highroadMarketingPage
} from '../../../../shared/highRoadFreeEpisodes'

export const BACKGROUND_DEBOUNCE_MS = 800

const styles = theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: 48,
      position: 'relative'
    },
    title: {
      color: theme.color.white,
      paddingRight: 12,
      transition: [['color', '100ms', 'ease-out']],
      textShadow: theme.shadow.textShadow(theme.color.black).primary,
      userSelect: 'none',
      maxWidth: '32em',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    icon: {
      fill: theme.color.white,
      marginLeft: 12,
      transition: [['fill', '100ms', 'ease-out']],
      filter: theme.shadow.filterShadow(theme.color.black).primary
    },
    background: {
      position: 'absolute',
      content: '" "',
      background: theme.color.primary,
      height: 36,
      width: '100%',
      marginLeft: '-100%',
      zIndex: -1,
      transition: [
        ['margin', '100ms', 'ease-out'],
        ['box-shadow', '100ms', 'ease-out']
      ],
      willChange: 'marginLeft'
    },
    hover: {
      '& > $background': {
        marginLeft: 0,
        boxShadow: theme.shadow.boxShadow(theme.color.black).primary
      },
      '& > $title': { color: theme.color.black, textShadow: 'none' },
      '& > $icon': { fill: theme.color.black, filter: 'none' }
    }
  }
}

const BackToShowPageButton = componentFromStream(props$ => {
  const { handler: onMouseEnter, stream: mouseEnter$ } = createEventHandler()
  const { handler: onMouseLeave, stream: mouseLeave$ } = createEventHandler()

  const isMouseOver$ = merge(
    mouseEnter$.pipe(mapTo(true)),
    mouseLeave$.pipe(mapTo(false))
  ).pipe(
    distinctUntilChanged(),
    debounce(
      isMouseOver => (isMouseOver ? of(true) : timer(BACKGROUND_DEBOUNCE_MS))
    ),
    startWith(false)
  )
  return props$.pipe(
    combineLatest(isMouseOver$, (props, isMouseOver) => {
      const {
        content,
        classes,
        emitCloseDataLayerEvent
      } = props
      let path = content.path
      if (isHighRoadFreeEpisodes()) {
        path = highroadMarketingPage
      }
      return (
        <Link
          to={path}
          className={classnames(classes.wrapper, {
            [classes.hover]: isMouseOver
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={emitCloseDataLayerEvent}
        >
          <BackIcon className={classes.icon} />
          <span className={classes.title}>{content.title}</span>
          <span className={classnames(classes.background)} />
        </Link>
      )
    })
  )
})

class BackToShowPage extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = { isHover: false }

  render() {
    const { classes } = this.props

    return (
      <ShowPageQuery>
        {({ data, loading, error }) => {
          if (loading || error) {
            return <BackIcon className={classes.icon} />
          }
          const { content } = data

          return (
            <GaConsumer>
              {({ emitCloseDataLayerEvent }) => {
                return (
                  <div>
                    <BackToShowPageButton
                      content={content}
                      classes={classes}
                      emitCloseDataLayerEvent={emitCloseDataLayerEvent}
                    />
                  </div>
                )
              }}
            </GaConsumer>
          )
        }}
      </ShowPageQuery>
    )
  }
}

export default withStyles(styles)(BackToShowPage)
