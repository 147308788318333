import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PlaybackIcon from '../../footer/play-toggle/play-icon'
import { StateConsumer } from '../../../state'

const styles = theme => {
  return {
    wrapper: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      pointerEvents: 'none'
    },
    playbackIcon: {
      cursor: 'pointer',
      display: 'flex',
      fill: theme.color.white,
      filter: theme.shadow.filterShadow(theme.color.black).primary,
      height: 80,
      opacity: 1,
      pointerEvents: 'auto',
      width: 80,
      transition: [
        ['opacity', 'ease-in', '100ms'],
        ['fill', 'ease-in', '100ms'],
        ['transform', 'ease-in', '100ms']
      ],
      willChange: ['opacity', 'fill', 'transform'],
      '&:hover': {
        opacity: 1,
        fill: theme.color.primary
      }
    },
    hide: {
      pointerEvents: 'none',
      opacity: 0,
      transform: ['scale(1.5)', 'translate3d(0,0,0)']
    }
  }
}

class PauseButton extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <StateConsumer>
        {({ isPlaying, isLoading, isInitialLoadingDone }) => (
          <div className={classes.wrapper}>
            <PlaybackIcon
              className={classNames(classes.playbackIcon, {
                [classes.hide]: isPlaying || isLoading || !isInitialLoadingDone
              })}
            />
          </div>
        )}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(PauseButton)
