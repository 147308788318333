import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { path, prop } from 'ramda'
import { useSelector } from 'react-redux'

import ProfileCloseCaption from '../../graphql/queries/profile-close-caption.gql'
import SaveClosedCaption from '../../graphql/queries/save-close-caption.gql'
import { checkIsAuthenticated } from '../lib/auth'

/**
 * Hook to return profile close caption
 */
export const useProfileCloseCaption = () => {
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isAuthenticated = session && checkIsAuthenticated(mapState)

  // Guest user does not have profile should skip the following query and mutation
  if (!isAuthenticated) {
    return {
      isTextTrackShown: false,
      currentProfileId: null,
      clickTextTrackHandler: () => {}
    }
  }

  const [isTextTrackShown, setIsTextTrackShown] = useState(false)
  const [currentProfileId, setCurrentProfileId] = useState(null)

  const { data, loading, error } = useQuery(ProfileCloseCaption)

  const [
    saveClosedCaption,
    { data: saveData, loading: saveLoading, error: saveError }
  ] = useMutation(SaveClosedCaption)

  const getCurrentProfileCloseCaption = (profiles, profileId) => {
    const currentProfile = profiles && profiles.find(p => p.id === profileId)
    const closedCaption = prop('closedCaption')(currentProfile)
    return closedCaption
  }

  useEffect(() => {
    if (error) {
      console.error(
        `Unable to obtain profile close caption`
      )
      return
    }

    if (!loading && data) {
      const account = path(['account'])(data)
      const selectedProfile = path(['selectedProfile'], account)
      setCurrentProfileId(selectedProfile)

      const profiles = prop('profiles')(account)
      const closedCaption = getCurrentProfileCloseCaption(profiles, selectedProfile)
      setIsTextTrackShown(closedCaption)
    }
  }, [data, loading, error])

  useEffect(() => {
    if (saveError) {
      console.error(
        `Unable to save profile close caption`
      )
      return
    }

    if (!saveLoading && saveData) {
      const profile = path(['profile'])(saveData)
      const profiles = prop('profiles')(profile)
      const closedCaption = getCurrentProfileCloseCaption(profiles, currentProfileId)
      setIsTextTrackShown(closedCaption)
    }
  }, [saveData, saveLoading, saveError])

  const clickTextTrackHandler = toggled => {
    saveClosedCaption({
      variables: {
        closedCaption: toggled,
        profileId: currentProfileId
      }
    })
  }

  return {
    isTextTrackShown,
    currentProfileId,
    clickTextTrackHandler
  }
}
