// This is for ignoring Highroad free content, we need to remove them from the list
// REMOVEME: Remove this file after the campain
export const highRoadFreeEpisodes = [
  '6ad66fd6-dc47-49a1-a40d-435d0a1a3b73',
  'b1a79c03-2e9b-486d-a092-d63af49be725',
  '79cbc4c5-e2ac-4e6d-a9b8-4958f1091e30',
  'e1aa168b-d797-405c-9689-b1a28d4715ff',
  'e1d154c7-1fcf-43cf-93c9-ed102400c4fe',
  '71fa223e-dc58-4b7b-a070-ec81ac8743ee',
  '07345d85-2f8d-4926-8dbd-d14a94fa61ad',
  '0e252dff-342e-4716-8bc1-e06380470786'
]

function getCurrentVideoId() {
  const parts = location.href.split('/')

  return parts[parts.length - 1]
}

export function isHighRoadFreeEpisodes() {
  return highRoadFreeEpisodes.includes(getCurrentVideoId())
}

export const highroadMarketingPage = '/high-road-free-episodes'
