import { isNil } from 'ramda'

// TODO update it to match the progressbar logic

function getHours(minuteTime) {
  return parseInt(minuteTime / 60, 10)
}

function getMinutes(minuteTime) {
  return parseInt(minuteTime % 60, 10)
}

function getTimeString(minuteTime) {
  let outputString = ''

  if (isNil(minuteTime) || isNaN(minuteTime)) return outputString

  const cursorHour = getHours(minuteTime)
  if (cursorHour > 0) {
    outputString = `${cursorHour}h `
  }

  const cursorMin = getMinutes(minuteTime)
  if (cursorMin > 0) {
    if (cursorMin < 10) {
      outputString = `${outputString}0${cursorMin}m`
    } else {
      outputString = `${outputString}${cursorMin}m`
    }
  } else {
    outputString = `${outputString}`
  }

  return outputString
}

export default getTimeString
