import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'
import classnames from 'classnames'
import Carousel from './carousel'
import CloseIcon from './close-icon'
import { TilePropTypes } from './prop-types'
import { StateConsumer } from '../../../state'

const styles = theme => {
  return {
    wrapper: {
      position: 'absolute',
      bottom: 100,
      color: theme.color.light,
      width: '100%',
      zIndex: theme.zIndex.moreLikeThis,
      paddingBottom: 24,
      backgroundColor: color(theme.color.dark)
        .alpha(0.6)
        .toString(),
      opacity: 0,
      pointerEvents: 'none',
      transition: ['opacity', '300ms', 'ease-in'],
      animationDuration: '300ms',
      animationTimingFunction: 'ease-in',
      animationName: '$fadein'
    },
    show: {
      opacity: 1,
      pointerEvents: 'auto'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    title: {
      fontSize: 28,
      fontWeight: 400,
      margin: ['15px', '0', '15px', '55px'],
      textShadow: theme.shadow.textShadow(theme.color.black).primary
    },
    close: {
      marginRight: 26,
      width: 30,
      cursor: 'pointer',
      fill: theme.color.white,
      filter: theme.shadow.filterShadow(theme.color.black).primary,
      '&:hover': {
        fill: theme.color.primary
      }
    },
    '@keyframes fadein': {
      from: { opacity: 0 },
      to: { opacity: 1 }
    }
  }
}

class MoreLikeThisUi extends React.PureComponent {
  constructor(props) {
    super(props)

    this.dismissMoreLikeThis = this.dismissMoreLikeThis.bind(this)
    this.state = {
      isShown: true
    }
  }

  dismissMoreLikeThis() {
    this.setState({
      isShown: false
    })
  }

  render() {
    const { classes, carousel } = this.props
    const { isShown } = this.state

    let type = carousel.contentType || carousel.subType

    if (!type) {
      type = 'Series'
    }

    return (
      <StateConsumer>
        {({
          isAdOnPauseShown,
          isEpisodeSelectorOpen
        }) => {
          return (
            <div
              className={classnames(classes.wrapper, {
                [classes.show]: isShown && !isAdOnPauseShown && !isEpisodeSelectorOpen
              })}
            >
              <div className={classes.header}>
                <h2 className={classes.title}>{carousel.header}</h2>
                <CloseIcon
                  className={classes.close}
                  onClick={this.dismissMoreLikeThis}
                />
              </div>

              <Carousel tiles={carousel.tiles} type={type} header={carousel.header} />
            </div>
          )
        }}
      </StateConsumer>
    )
  }
}

MoreLikeThisUi.propTypes = {
  carousel: PropTypes.shape({
    tiles: PropTypes.arrayOf(TilePropTypes.isRequired).isRequired,
    contentType: PropTypes.string,
    subType: PropTypes.string,
    header: PropTypes.string
  }).isRequired
}

export default withStyles(styles)(MoreLikeThisUi)
