import jwtDecode from 'jwt-decode'

function determineIsKids() {
  const tokenFromLocalStorage = localStorage.getItem('token')

  const decoded = tokenFromLocalStorage && jwtDecode(tokenFromLocalStorage)

  const isKid = decoded ? decoded.isKid : false
  return isKid
}

export default determineIsKids
