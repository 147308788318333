import React from 'react'
import withStyles from 'react-jss'
import {
  compose, defaultTo, join, take, pathOr
} from 'ramda'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getDurationTextFromMinutes } from '../../../../../../../lib/date-time'
import { getLowestPrice } from '../../../../../../../lib/content-item'
import Clamped from '../../../../../../shared/clamped'
import { TilePropTypes } from '../../prop-types'
import { playerDataLayerProductClick } from '../../../../../ga/utils'

const styles = theme => {
  return {
    wrapper: {
      opacity: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.color.dark,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: ['opacity', 'ease-out', '500ms'],
      color: theme.color.light,
      cursor: 'pointer',
      textAlign: 'center',
      border: [2, 'solid', theme.color.primary],
      '&:hover': {
        opacity: 1
      },
      margin: 0,
      padding: [20, 12]
    },
    title: {
      fontSize: 14,
      fontWeight: 500,
      height: '28%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      flexGrow: 2
    },
    upperParts: {
      flexGrow: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    year: {
      fontSize: 14,
      display: 'inline-block',
      flexGrow: 1
    },
    genres: {
      fontFamily: 'GothamRounded',
      fontSize: 12,
      fontWeight: 300,
      lineHeight: '21px',
      fontStyle: 'italic',
      flexGrow: 1
    },
    duration: {
      fontSize: 12,
      lineHeight: '21px',
      flexGrow: 2,
      justifyContent: 'flex-end'
    },
    price: {
      verticalAlign: 'bottom',
      fontSize: 14,
      flexGrow: 3,
      fontWeight: 500
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    button: {
      backgroundColor: theme.color.primary,
      borderRadius: 2,
      fontSize: 14,
      fontFamily: 'GothamRounded',
      color: theme.color.dark,
      padding: [8, 32],
      textAlign: 'center',
      '&:hover': {
        backgroundColor: [theme.color.primary, 'lightness(+30 %)']
      }
    },
    [theme.breakpoint.tablet]: {
      title: {
        fontSize: 16
      },
      button: {
        padding: [8, 42]
      },
      wrapper: {
        padding: [30, 12, 40]
      },
      price: {
        fontSize: 16
      }
    },
    [theme.breakpoint.desktopXLarge]: {
      title: {
        fontSize: 18
      },
      year: {
        fontSize: 16
      },
      genres: {
        fontSize: 16
      },
      wrapper: {
        paddingBottom: 42
      },
      season: {
        fontSize: 16
      }
    }
  }
}

function MovieOverlay({
  tile, classes, header, position
}) {
  const contentItem = tile.contentItem
  const title = contentItem.title
  const year = contentItem.year
  const rating = contentItem.rating && contentItem.rating.rating
  const genres = compose(
    join(', '),
    take(2),
    defaultTo([])
  )(contentItem.genres)

  const duration = getDurationTextFromMinutes(contentItem.duration)
  const url = pathOr('', ['contentItem', 'path'])(tile)
  const price = getLowestPrice(contentItem)

  return (
    <Link className={classes.wrapper} to={url} onClick={playerDataLayerProductClick(contentItem, position, header)}>
      <Clamped className={classes.title} lines={3}>
        {title}
      </Clamped>

      <div className={classes.upperParts}>
        <div className={classes.year}>
          {year}
          {' '}
          |
          {rating}
        </div>
        <Clamped className={classes.genres} lines={1}>
          {genres}
        </Clamped>
        <div className={classes.duration}>{duration}</div>
      </div>

      {price !== '' && (
        <div className={classes.price}>
          Rent $
          {price}
        </div>
      )}

      <div className={classes.buttonWrapper}>
        <div className={classes.button}>More Info</div>
      </div>
    </Link>
  )
}

MovieOverlay.propTypes = {
  tile: TilePropTypes.isRequired,
  header: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired
}

export default withStyles(styles)(MovieOverlay)
