import React from 'react'
import withStyles from 'react-jss'

import { EpisodeSelectorUiConsumer } from '../episode-selector-ui'

const styles = theme => {
  return {
    wrapper: {
      padding: '2vw'
    },
    name: {
      fontSize: '3vw',
      fontWeight: 400
    },
    summary: {
      fontSize: '2vw',
      fontWeight: 100,
      fontStyle: 'italic'
    }
  }
}

class Synopsis extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <EpisodeSelectorUiConsumer>
        {({ synopsis }) => {
          return (
            <div className={classes.wrapper}>
              <h3 className={classes.name}>
                {synopsis.episodeNumber}
                .
                {synopsis.name}
              </h3>
              <p className={classes.summary}>{synopsis.summary}</p>
            </div>
          )
        }}
      </EpisodeSelectorUiConsumer>
    )
  }
}

export default withStyles(styles)(Synopsis)
