import { path, compose, head } from 'ramda'

const getContentItem = path(['contentItem'])
const getEpisode = path(['contentItem', 'series'])
const getTrailer = compose(
  head,
  path(['contentItem', 'contentItems'])
)
const getTypeName = path(['contentItem', '__typename'])

// TODO test?
export default function getContentFromQuery(data) {
  switch (getTypeName(data)) {
    case 'VideoExtra':
      return getTrailer(data)
    case 'Episode':
      return getEpisode(data)
    case 'Title':
      return getContentItem(data)
    // trailers will return as series/title
    case 'Series':
      return getContentItem(data)
    default:
      throw new Error('Content not found.')
  }
}
