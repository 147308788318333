import React from 'react'
import { keys } from 'ramda'
import withStyles from 'react-jss'

import { SeasonTagType } from '../prop-types'
import { IMAGE_MARGIN } from '../constants'
import { EpisodeSelectorUiConsumer } from '../episode-selector-ui'
import FocusTrackingCarousel from '../utils/focus-tracking-carousel'

import Season from './season'

const styles = {
  wrapper: {
    margin: [[0, IMAGE_MARGIN]],
    display: 'flex',
    overflow: 'hidden'
  }
}

function Tabs({ seasonTag, classes }) {
  const seasonNumbers = keys(seasonTag)
  return (
    <EpisodeSelectorUiConsumer>
      {({
        currentSeason,
        activeSeason,
        setActiveSeason,
        episodeCount,
        loadedImagery
      }) => {
        const loadedPercentage = loadedImagery / episodeCount

        return (
          <FocusTrackingCarousel
            className={classes.wrapper}
            count={seasonNumbers.length}
            activeIndex={seasonNumbers.indexOf(currentSeason)}
          >
            {seasonNumbers.map(seasonNumber => {
              const isCurrent = currentSeason === seasonNumber
              const isActive = activeSeason === seasonNumber

              return (
                <Season
                  key={seasonNumber}
                  isCurrent={isCurrent}
                  isActive={isActive}
                  onClick={() => setActiveSeason(seasonNumber)}
                  seasonNumber={seasonNumber}
                  loadedPercentage={isActive ? loadedPercentage : 1}
                >
                  {seasonTag[seasonNumber]}
                </Season>
              )
            })}
          </FocusTrackingCarousel>
        )
      }}
    </EpisodeSelectorUiConsumer>
  )
}

Tabs.propTypes = {
  seasonTag: SeasonTagType.isRequired
}

export default withStyles(styles)(Tabs)
