import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import withStyles from 'react-jss'
import color from 'color'

const styles = theme => {
  return {
    wrapper: {
      width: 32,
      height: 32,
      backgroundColor: color(theme.color.black)
        .alpha(0.7)
        .toString(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      border: [[theme.color.white, 'solid', 3]],
      transition: [
        ['background', '200ms', 'ease-out'],
        ['opacity', '200ms', 'ease-out'],
        ['transform', '100ms', 'ease-out']
      ],
      boxShadow: theme.shadow.boxShadow(theme.color.black).primary
    }
  }
}
function IconArrow({ className, iconClassName, classes }) {
  return (
    <div className={classnames(classes.wrapper, className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className={classnames(iconClassName, classes.icon)}
      >
        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      </svg>
    </div>
  )
}

IconArrow.propTypes = {
  iconClassName: PropTypes.string,
  className: PropTypes.string
}
IconArrow.defaultProps = {
  iconClassName: '',
  className: ''
}

export default withStyles(styles)(IconArrow)
