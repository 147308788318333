import {
  path, reduce, max, filter, map, isNil
} from 'ramda'

/**
 * Convert graphql query result into
{
  current: {episode: Number, season: Number},
  nextEpisode:{
    id: ID
  },
  playList: {
    5:{ // <- seasonNumber:season
      1:{id:ID},
      2:{id:ID}, // <- episodeNumber:episode
      ...}
    }
}
 *
 * @param {Object} input
 */

export function convertData(input) {
  const contentItem = path(['contentItem'])(input)
  const current = {
    episode: contentItem.episodeNumber,
    season: contentItem.seasonNumber
  }
  const playlist = getPlaylist(contentItem.series.seasons)
  const nextEpisodeInfo = getNextEpisodeInfo(playlist, current)
  if (isNil(nextEpisodeInfo)) return { current, playlist, nextEpisode: null }
  const nextEpisodeId = path(['id'])(nextEpisodeInfo)
  const nextEpisodeNumber = path(['episodeNumber'])(nextEpisodeInfo)
  const nextEpisodeTitle = path(['title'])(nextEpisodeInfo)
  const nextEpisodeSeasonNumber = path(['seasonNumber'])(nextEpisodeInfo)
  const nextEpisodeImageUrl = path(['imageUrl'])(nextEpisodeInfo)

  return {
    current,
    playlist,
    nextEpisode: {
      id: nextEpisodeId,
      episodeNumber: nextEpisodeNumber,
      title: nextEpisodeTitle,
      seasonNumber: nextEpisodeSeasonNumber,
      imageUrl: nextEpisodeImageUrl
    }
  }
}

function getPlaylist(input) {
  return reduce(
    (seasons, { episodes, seasonNumber }) => {
      const currentSeason = reduce(
        (season, episode) => {
          const currentEpisode = {
            id: episode.id,
            episodeNumber: episode.episodeNumber,
            title: episode.title,
            available: episode.available,
            seasonNumber,
            imageUrl: path(['tile', 'image'])(episode)
          }
          return {
            ...season,
            [episode.episodeNumber]: currentEpisode
          }
        },
        {},
        episodes
      )
      currentSeason.length = episodes.length

      return {
        ...seasons,
        [seasonNumber]: currentSeason
      }
    },
    {},
    input
  )
}

function getNextEpisodeInfo(playlist, current) {
  const currentSeason = playlist[current.season]
  const lastEpisode = getLastEpisode(currentSeason)
  // still in current season
  if (current.episode <= lastEpisode) {
    const nextEpisode = currentSeason[current.episode + 1]

    if (nextEpisode && nextEpisode.available) {
      return nextEpisode
    }
  }

  // next season
  const nextSeason = playlist[current.season + 1]
  if (nextSeason) {
    const nextEpisode = nextSeason[1]
    if (nextEpisode && nextEpisode.available) {
      return nextEpisode
    }
  }
  // all seasons have done
  return null
}

function getLastEpisode(season) {
  const seasonKeys = Object.keys(season)

  // there is other elements in keys such as `length`
  const seasons = filter(key => key, map(key => parseInt(key, 10), seasonKeys))
  return reduce(max, 0, seasons)
}
