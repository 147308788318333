import { prop } from 'ramda'

const getCuePoint = prop('cue_points')

function getStartCuePoint(metaData) {
  const cuepoints = getCuePoint(metaData)
  if (!cuepoints) {
    return null
  }

  const cuepoint = cuepoints.find(cp => cp.name === 'Start')

  return cuepoint && cuepoint.startTime
}

export { getStartCuePoint }
