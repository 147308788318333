import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'

const styles = theme => {
  return {
    bar: ({ isShown }) => ({
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: color(theme.color.white)
        .alpha(0.3)
        .toString(),
      transition: [['opacity', 'ease', '300ms']],
      opacity: isShown ? 1 : 0
    })
  }
}

function Cursor({ classes, percent, isShown }) {
  return (
    <div
      className={classes.bar}
      style={{
        width: `${percent * 100}%`
      }}
    />
  )
}

Cursor.propTypes = {
  percent: PropTypes.number.isRequired,
  isShown: PropTypes.bool.isRequired
}

export default withStyles(styles)(Cursor)
