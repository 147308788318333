import React from 'react'
import withStyles from 'react-jss'
import color from 'color'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { Checkbox } from '../../shared/button'
import { StateConsumer } from '../../../state'

const styles = theme => {
  return {
    label: {
      width: 64,
      fontSize: '1.2em'
    },
    description: {
      fontSize: '0.9em',
      width: 290
    },
    '@keyframes pulse': {
      from: {
        backgroundColor: color(theme.color.primary)
          .alpha(0)
          .toString()
      },
      to: {
        backgroundColor: color(theme.color.primary)
          .alpha(0.4)
          .toString()
      }
    },
    checkBoxCell: {
      width: 74,
      textAlign: 'center'
    },
    row: {
      height: 54,
      cursor: 'pointer',
      willChange: 'background-color',
      '&:hover *': {
        color: theme.color.primary,
        fill: theme.color.primary
      }
    },
    active: {
      animation: [['0.2s', 2]],
      animationName: '$pulse'
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.4
    },
    greyOut: {
      opacity: 0.4
    }
  }
}

class SettingOption extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.state = { transition: false }
  }

  handleClick(svodStreamingResolution, showQualityUpgradePopup) {
    if (svodStreamingResolution === 'SD') {
      // Show Upgrade Popup Message
      showQualityUpgradePopup(true)
    } else {
      const { setDisable, onClick, isActive } = this.props
      if (isActive) return
      // prevent user from click on the table again.
      setDisable(true)
      // kick off animation
      this.setState({ transition: true }, () => {
        // animation last 500ms
        setTimeout(() => {
          this.setState({ transition: false }, onClick)
        }, 500)
      })
    }
  }

  render() {
    const {
      option,
      classes,
      isActive,
      showQualityUpgradePopup
    } = this.props

    return (
      <StateConsumer>
        {({
          purchasedFormat,
          svodStreamingResolution,
          isTrailer
        }) => {
          return (
            <tr
              className={classnames(classes.row, {
                [classes.active]: this.state.transition,
                [classes.greyOut]: option.label === 'HD' && svodStreamingResolution === 'SD' && !isTrailer,
                [classes.disabled]: option.label === 'HD' && purchasedFormat === 'SD' && !isTrailer
              })}
              onClick={() => this.handleClick(svodStreamingResolution, showQualityUpgradePopup)}
            >
              <td className={classes.checkBoxCell}>
                <Checkbox isChecked={isActive} />
              </td>
              <td className={classes.label}>{option.label}</td>
              <td className={classes.description}>{option.description}</td>
            </tr>
          )
        }}
      </StateConsumer>
    )
  }
}

SettingOption.propTypes = {
  option: PropTypes.shape({
    bitrate: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setDisable: PropTypes.func.isRequired,
  showQualityUpgradePopup: PropTypes.func
}

export default withStyles(styles)(SettingOption)
