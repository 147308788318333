import React from 'react'
import PropTypes from 'prop-types'
import { ControlConsumer } from '../../../control'
import NextEpisodeCountdownUi from './next-episode-countdown-ui'

class NextEpisodeCountdown extends React.PureComponent {
  render() {
    const {
      nextEpisode,
      currentEpisodeInfo,
      getStreams,
      countdownTime,
      dismissBanner,
      isPlaying
    } = this.props

    const {
      id, episodeNumber, seasonNumber, title, imageUrl
    } = nextEpisode
    const { season } = currentEpisodeInfo

    return (
      <ControlConsumer>
        {({
          getShouldDisplayContinueWatching,
          redirectToEpisodeById,
          showContinueWatching,
          setShouldUseCuePoint
        }) => {
          return (
            <NextEpisodeCountdownUi
              nextEpisodeNumber={episodeNumber}
              id={id}
              nextEpisodeSeason={seasonNumber}
              currentSeason={season}
              title={title}
              imageUrl={imageUrl}
              getStreams={getStreams}
              countdownTime={countdownTime}
              isCountdownShowing={this.isCountdownShowing}
              getShouldDisplayContinueWatching={
                getShouldDisplayContinueWatching
              }
              redirectToEpisodeById={redirectToEpisodeById}
              showContinueWatching={showContinueWatching}
              setShouldUseCuePoint={setShouldUseCuePoint}
              dismissBanner={dismissBanner}
              isPlaying={isPlaying}
            />
          )
        }}
      </ControlConsumer>
    )
  }
}

NextEpisodeCountdown.propTypes = {
  nextEpisode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    seasonNumber: PropTypes.number.isRequired,
    episodeNumber: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired
  }).isRequired,
  currentEpisodeInfo: PropTypes.shape({
    season: PropTypes.number
  }).isRequired,
  getStreams: PropTypes.func.isRequired,
  countdownTime: PropTypes.number.isRequired,
  dismissBanner: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool.isRequired
}

export default NextEpisodeCountdown
