import React, { useEffect, useState } from 'react'
import withStyles from 'react-jss'
import PropType from 'prop-types'
import classnames from 'classnames'

import Footer from './footer'
import Body from './body'
import Header from './header'
import RatingInPlayer from './rating-in-player'
import EpisodeSelector from './episode-selector'
import LoadingSpinner from './loading-spinner'
import { StateConsumer } from '../state'
import Modal from '../modal'
import { PLAYER_RATING_DURATION } from '../constants'
import ratingStyles from './ratingInPlayer.css'
import { useProfileCloseCaption } from '../../../hooks/use-profile-close-caption'

const { Provider, Consumer: UiConsumer } = React.createContext()

const styles = {
  wrapper: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    transition: [['opacity', 'ease-out', '500ms']]
  },

  hideCursor: {
    cursor: 'none'
  }
}

const UIContent = ({ isUiHidden, isPlayingAd }) => {
  const {
    isTextTrackShown,
    clickTextTrackHandler
  } = useProfileCloseCaption()

  const [ratingDisplayed, setRatingDisplayed] = useState(false)
  useEffect(() => {
    if (isUiHidden && !ratingDisplayed && !isPlayingAd) {
      const displayRating = setTimeout(() => setRatingDisplayed(true), PLAYER_RATING_DURATION)
      sessionStorage.setItem('PLAYER_RATING_TIMEOUT_ID', displayRating)
    }

    if (!isUiHidden && sessionStorage.getItem('PLAYER_RATING_TIMEOUT_ID')) {
      clearTimeout(sessionStorage.getItem('PLAYER_RATING_TIMEOUT_ID'))
    }
  }, [isUiHidden, isPlayingAd])

  if (!isUiHidden) {
    return (
      <>
        <Header isPlayingAd={isPlayingAd} />
        <EpisodeSelector />
        <Body />
        <Footer isPlayingAd={isPlayingAd} isTextTrackShown={isTextTrackShown} saveCloseCaption={clickTextTrackHandler} />
      </>
    )
  }

  return (
    <div
      className={classnames(ratingStyles.ratingContainer, {
        [ratingStyles.hidden]: ratingDisplayed || isPlayingAd
      })}
    >
      <RatingInPlayer />
    </div>
  )
}

UIContent.propTypes = {
  isUiHidden: PropType.bool.isRequired,
  isPlayingAd: PropType.bool.isRequired
}

class Ui extends React.PureComponent {
  constructor(props) {
    super(props)

    // todo: should we put this to application state?
    this.state = {
      isRatingDisplayed: false
    }
  }

  render() {
    const { classes } = this.props

    return (
      <StateConsumer>
        {({ isUiHidden, isPlayingAd }) => {
          return (
            <>
              <LoadingSpinner />
              <Modal />
              <div
                className={classnames(classes.wrapper, {
                  [classes.hideCursor]: isUiHidden
                })}
              >
                <Provider value={this.state}>
                  <UIContent isUiHidden={isUiHidden} isPlayingAd={isPlayingAd} />
                </Provider>
              </div>
            </>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(Ui)
export { UiConsumer }
