import React from 'react'
import { path, head } from 'ramda'
import { StateConsumer } from '../../../state'
import Query from '../../../lib/query'
import SeasonEpisodeQuery from './season-episode-query.gql'
import EpisodeSelectorToggleUi from './episode-selector-toggle-ui'

const getSeasons = path(['contentItem', 'series', 'seasons'])

class EpisodeSelectorToggle extends React.PureComponent {
  render() {
    return (
      <StateConsumer>
        {({ contentId }) => {
          return (
            <Query query={SeasonEpisodeQuery} variables={{ contentId }}>
              {result => {
                // TODO error handling?
                if (result.loading || result.error) {
                  return null
                }

                const seasons = getSeasons(result.data)
                if (!seasons) return null
                const firstSeason = head(seasons)
                if (!firstSeason) return null
                if (!head(firstSeason.episodes)) return null

                // Hide the button when there's only 1 season and 1 episode only
                if (seasons.length < 2 && firstSeason.episodes.length < 2) {
                  return null
                }

                const seasonEpisodeNumber =
                  result.data.contentItem.__typename === 'Episode' &&
                  getseasonEpisodeNumber(result.data.contentItem)

                if (!seasonEpisodeNumber) return null

                return (
                  <EpisodeSelectorToggleUi
                    seasonEpisodeNumber={seasonEpisodeNumber}
                  />
                )
              }}
            </Query>
          )
        }}
      </StateConsumer>
    )
  }
}

function getseasonEpisodeNumber(contentItem) {
  return `S${contentItem.seasonNumber}.E${contentItem.episodeNumber}`
}

export default EpisodeSelectorToggle
