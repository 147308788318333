import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'
import classnames from 'classnames'

import { StateConsumer } from '../../player/state'
import { clearAdOnPause } from '../utils'

const styles = theme => {
  return {
    header: {
      top: 0,
      position: 'absolute',
      color: theme.color.white,
      textShadow: theme.shadow.textShadow(theme.color.black).primary,
      width: '100%',
      height: 64,
      textAlign: 'center',
      display: 'block',
      opacity: 0
    },

    title: {
      display: 'inline-block',
      zIndex: theme.zIndex.overlay,
      position: 'relative',
      lineHeight: 4.5
    },

    closeButton: {
      display: 'inline-block',
      zIndex: theme.zIndex.overlay,
      right: 0,
      top: 14,
      position: 'fixed',
      marginRight: 20,
      padding: [10, 20],
      backgroundColor: color(theme.color.black)
        .alpha(0.1)
        .toString(),
      borderRadius: 4,
      cursor: 'pointer',

      '&:hover': {
        color: theme.color.black,
        backgroundColor: theme.color.primary,
        textShadow: 'none'
      }
    },

    show: {
      opacity: 1
    }
  }
}

class AdOnPauseHeader extends React.PureComponent {
  render() {
    const { classes, setIsAdOnPauseShown } = this.props

    const onClickHandler = () => {
      clearAdOnPause()
      setIsAdOnPauseShown(false)
    }

    return (
      <StateConsumer>
        {({
          isAdOnPauseShown
        }) => {
          return (
            <div
              className={classnames(classes.header, {
                [classes.show]: isAdOnPauseShown
              })}
            >
              <div className={classes.title}>Sponsored Content</div>
              <div className={classes.closeButton} onClick={() => { onClickHandler() }}>CLOSE AD</div>
            </div>
          )
        }}
      </StateConsumer>
    )
  }
}

AdOnPauseHeader.propTypes = {
  setIsAdOnPauseShown: PropTypes.func.isRequired
}

export default withStyles(styles)(AdOnPauseHeader)
