import { map, distinctUntilChanged, share } from 'rxjs/operators'

export const refresh = stateInstance => [
  map(count => {
    const { props, state } = stateInstance
    const playerCurrentTime = props.player.currentTime()
    const playerTime = parseFloat(playerCurrentTime)

    const countLimit = 4

    // to make click to indicate the exact time it is clicked.
    // ie click on 12.8, the progress bar should show 12.8 instead of 13
    let currentTime = playerTime

    // 1. TODO for some reason, the player sometimes will report wrong time
    // at beginning when the playback starts.
    // here we use a counter to prevent it from happen at first 2 seconds.
    // But this is a player bug should be fixed by brightcove lol
    // 2. The ssai stream player will report the wrong time at the beginning
    // when the ssai playback starts using the next episode button
    if (
      (playerTime < state.currentTime && count < countLimit) ||
      (state.isSsaiStream && playerTime > state.currentTime && count < countLimit)
    ) {
      currentTime = state.currentTime
    }
    return {
      currentTime: currentTime || 0,
      bufferedTime: parseInt(props.player.buffered().end(0), 10),
      duration: parseInt(props.player.duration(), 10)
    }
  }),
  distinctUntilChanged((current, next) => {
    return (
      current.duration === next.duration &&
      current.currentTime === next.currentTime &&
      current.bufferedTime === next.bufferedTime
    )
  }),
  share()
]
