import React from 'react'
import withStyles from 'react-jss'
import className from 'classnames'

import { GaConsumer } from '../../ga/ga-core'
import { ShowPageQuery } from '../../utils/query'
import { Rating } from '../../../rating'

const styles = theme => {
  return {
    ratingContainer: {
      background: 'rgba(0, 0, 0, 0.25)',
      height: '100vh'
    },
    ratingFrame: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '20px 0 0 20px'
    },
    fadeIn: { animation: 'fadeIn 2s' },
    ratingReason: {
      color: 'white',
      fontFamily: 'GTWalsheim',
      fontWeight: 300,
      fontStyle: 'normal',
      paddingLeft: 12,
      maxWidth: 650,
      fontSize: 20
    },
    '@media (max-width: 1024px)': {
      ratingReason: {
        maxWidth: 400
      }
    },
    '@media (max-width: 768px)': {
      ratingReason: {
        fontSize: 14
      }
    }
  }
}

function RatingInPlayer({ classes }) {
  return (
    <ShowPageQuery>
      {({ data, loading, error }) => {
        if (loading || error) {
          return null
        }
        const { content, episodeRating } = data
        const isCVODRating = episodeRating ? episodeRating.ratingSystem === 'CVOD' : content.rating?.ratingSystem === 'CVOD'

        const displayRating = () => {
          return (
            <div className={classes.ratingContainer}>
              <div className={className(classes.ratingFrame, classes.fadeIn)}>
                <Rating size="large" rating={episodeRating || content.rating} isInPlayer />
                <p className={classes.ratingReason}>{episodeRating ? episodeRating.reason : content.rating.reason}</p>
              </div>
            </div>
          )
        }

        return (
          <GaConsumer>
            {() => {
              return (
                isCVODRating && displayRating()
              )
            }}
          </GaConsumer>
        )
      }}
    </ShowPageQuery>
  )
}

export default withStyles(styles)(RatingInPlayer)
