import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import VolumeButton from './volume-button'
import VolumeBar from './volume-bar'
import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'
import { volumeMuteStore, volumeStore } from '../../../utils/storage'
import volumeLocalStorage from './utils/volume-local-storage'

const {
  saveIsVolumeMuteToLocalStorage,
  saveVolumeToLocalStorage,
  register,
  unRegister
} = volumeLocalStorage

const styles = theme => {
  const expandedWidth = 122
  return {
    icon: {
      fill: theme.color.white
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      flexGrow: 1,
      width: 28,
      margin: ['0', '6px'],
      overflow: 'hidden',
      transition: ['width', 'ease-in', '300ms'],
      '&:hover': {
        width: expandedWidth
      }
    },
    focus: {
      width: expandedWidth,
      overflow: 'visible'
    }
  }
}

const UNMUTE_FROM_MUTE_VOLUME = 0.5

class VolumeControl extends React.PureComponent {
  constructor(props) {
    super(props)

    this.setVolume = this.setVolume.bind(this)
    this.setIsVolumeMute = this.setIsVolumeMute.bind(this)

    this.state = {
      volume: volumeStore.get(),
      isVolumeMute: volumeMuteStore.get()
    }
  }

  componentDidMount() {
    register(this)
  }

  componentWillUnmount() {
    unRegister()
  }

  setVolume(volume, callback) {
    this.setState({ volume, isVolumeMute: volume === 0 }, callback)
  }

  setIsVolumeMute(isVolumeMute, callback) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    let targetVolume = this.state.volume

    if (!isVolumeMute && this.state.volume === 0) {
      targetVolume = UNMUTE_FROM_MUTE_VOLUME
    }

    this.setState({ isVolumeMute, volume: targetVolume }, callback)
  }

  render() {
    const { classes } = this.props
    return (
      <ControlConsumer>
        {({ setPlayerVolume }) => {
          return (
            <StateConsumer>
              {({ isVolumeBarVisible, setIsVolumeBarVisible }) => {
                const { isVolumeMute, volume } = this.state
                const { setIsVolumeMute, setVolume } = this

                const setVolumeFromVolumeControl = value => {
                  setVolume(value, () => {
                    setPlayerVolume(this.state.volume)
                    setIsVolumeBarVisible(true)
                    saveVolumeToLocalStorage()
                    saveIsVolumeMuteToLocalStorage()
                  })
                }

                const setIsVolumeMuteFromVolumeControl = isMute => {
                  setIsVolumeMute(isMute, () => {
                    if (isMute) {
                      setPlayerVolume(0)
                    } else {
                      setPlayerVolume(this.state.volume)
                      if (this.state.volume === UNMUTE_FROM_MUTE_VOLUME) {
                        saveVolumeToLocalStorage(UNMUTE_FROM_MUTE_VOLUME)
                      }
                    }
                    setIsVolumeBarVisible(true)
                    saveIsVolumeMuteToLocalStorage()
                  })
                }

                return (
                  <div
                    className={classNames(classes.wrapper, {
                      [classes.focus]: isVolumeBarVisible
                    })}
                  >
                    <VolumeButton
                      isVolumeMute={isVolumeMute}
                      setIsVolumeMute={setIsVolumeMuteFromVolumeControl}
                      volume={isVolumeMute ? 0 : volume}
                    />
                    <VolumeBar
                      volume={volume}
                      setVolume={setVolumeFromVolumeControl}
                      isVolumeMute={isVolumeMute}
                    />
                  </div>
                )
              }}
            </StateConsumer>
          )
        }}
      </ControlConsumer>
    )
  }
}

export default withStyles(styles)(VolumeControl)
