import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

const styles = theme => {
  return {
    svg: {
      transform: 'rotate(-90deg)'
    }
  }
}

class IconPlayProgress extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      progress: 50,
      radius: 12,
      stroke: 2
    }

    this.normalizedRadius = this.state.radius - this.state.stroke * 2
    this.circumference = this.normalizedRadius * 2 * Math.PI
  }

  render() {
    const {
      progress, className, classes, theme, ...others
    } = this.props

    const strokeDashoffset = this.circumference - progress * this.circumference

    return (
      <svg
        {...others}
        height={24}
        width={24}
        viewBox="0 0 24 24"
        className={classnames(className, classes.svg)}
      >
        <path d="M7.5 10l4.5 6 4.5-6-9 0z" />
        <circle
          stroke={theme.color.white}
          fill="transparent"
          strokeWidth={this.state.stroke}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={12}
          cy={12}
        />
        <circle
          stroke={theme.color.primary}
          fill="transparent"
          strokeWidth={this.state.stroke}
          strokeDasharray={`${this.circumference} ${this.circumference}`}
          style={{ strokeDashoffset }}
          r={this.normalizedRadius}
          cx={12}
          cy={12}
        />
      </svg>
    )
  }
}

IconPlayProgress.propTypes = {
  progress: PropTypes.number.isRequired,
  className: PropTypes.string,
  theme: PropTypes.shape({
    color: PropTypes.shape({
      primary: PropTypes.string.isRequired,
      white: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
}

IconPlayProgress.defaultProps = {
  className: ''
}

export default withStyles(styles, { injectTheme: true })(IconPlayProgress)
