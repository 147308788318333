import React from 'react'
import PropTypes from 'prop-types'
import { emitDataLayerEvent } from '../../../lib/analytics/datalayer'
import { playbackStart } from '../../../lib/analytics/events'
import { getProgressPayload, getClosePayload } from './utils'
import { sendProgressToGA$ } from '../state-stream'

const { Consumer: GaConsumer, Provider } = React.createContext()

class GaCore extends React.PureComponent {
  constructor(props) {
    super(props)
    const { playPayloadGetter } = this.props
    this.state = {
      emitCloseDataLayerEvent: () => {
        emitDataLayerEvent(getClosePayload())
      },
      emitPlayDataLayerEvent: () => {
        emitDataLayerEvent(playPayloadGetter())
      }
    }
  }

  componentDidMount() {
    const { getStreams, onLoadPayload, playPayloadGetter } = this.props

    this.onLoadSubscription = getStreams().initialLoad$.subscribe(() => {
      emitDataLayerEvent(playPayloadGetter('init'))

      const {
        showTitle, videoCategory, seasonNumber, episodeNumber
      } = onLoadPayload
      playbackStart(showTitle, videoCategory, { season: seasonNumber, episode: episodeNumber })
    })

    this.sendProgressToGASubscription = sendProgressToGA$.subscribe(
      ({ percentage }) => {
        emitDataLayerEvent(getProgressPayload(percentage))
      }
    )
  }

  componentWillUnmount() {
    this.onLoadSubscription.unsubscribe()
    this.sendProgressToGASubscription.unsubscribe()
  }

  render() {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

// TODO: need refactor, object dimension would be changed after refactor.
GaCore.propTypes = {
  getStreams: PropTypes.func.isRequired,
  onLoadPayload: PropTypes.oneOfType([PropTypes.object]).isRequired, // eslint-disable-line
  playPayloadGetter: PropTypes.func.isRequired
}

export default GaCore
export { GaConsumer }
