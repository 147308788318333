import React from 'react'
import { path } from 'ramda'

import { StateConsumer } from '../state'
import ControlCore from './control-core'
import { NextEpisodeQuery, ShowPageQuery } from '../utils/query'

const getShowpagePath = path(['content', 'path'])

class Control extends React.PureComponent {
  render() {
    return (
      <ShowPageQuery>
        {showPageQuery => {
          let showPageUrl
          if (showPageQuery.data) {
            showPageUrl = getShowpagePath(showPageQuery.data)
          }
          // using shouldUpdate will make NextEpisodeQuery update,
          // so it can pass showPageUrl to children component
          return (
            <NextEpisodeQuery shouldUpdate={showPageUrl}>
              {nextEpisodeQuery => {
                let nextEpisodeId
                if (nextEpisodeQuery.data) {
                  nextEpisodeId = path(['data', 'id'])(nextEpisodeQuery)
                }

                return (
                  <StateConsumer>
                    {({
                      continueWatchingThreshold,
                      setIsContinueWatchingShown,
                      setIsTvodModalShown,
                      getSetters,
                      isPlaying,
                      currentQuality,
                      currentTime,
                      duration,
                      getStreams,
                      firstPlayback,
                      isSsaiStream,
                      isPlayingAd,
                      linearAdRolls,
                      setSeekingPointAfterMidroll
                    }) => {
                      return (
                        <ControlCore
                          {...this.props}
                          isPlaying={isPlaying}
                          currentQuality={currentQuality}
                          currentTime={currentTime}
                          duration={duration}
                          getStreams={getStreams}
                          nextEpisodeId={nextEpisodeId}
                          continueWatchingThreshold={continueWatchingThreshold}
                          setIsContinueWatchingShown={
                            setIsContinueWatchingShown
                          }
                          setIsTvodModalShown={setIsTvodModalShown}
                          getSetters={getSetters}
                          resetCurrentTime={() => getSetters().setCurrentTime(0)}
                          showPageUrl={showPageUrl}
                          firstPlayback={firstPlayback}
                          isSsaiStream={isSsaiStream}
                          isPlayingAd={isPlayingAd}
                          linearAdRolls={linearAdRolls}
                          setSeekingPointAfterMidroll={setSeekingPointAfterMidroll}
                        />
                      )
                    }}
                  </StateConsumer>
                )
              }}
            </NextEpisodeQuery>
          )
        }}
      </ShowPageQuery>
    )
  }
}

export default Control
