import React from 'react'
import withStyles from 'react-jss'

const styles = theme => {
  return {
    castButton: {
      height: 24,
      background: 'rgba(0, 0, 0, 0)',
      border: 'none',
      padding: '2px 0',
      cursor: 'pointer',
      top: 12,
      '--disconnected-color': theme.color.white,
      '--connected-color': theme.color.primary,
      '&:hover': {
        '--disconnected-color': theme.color.primary,
        '--connected-color': theme.color.primary
      }
    },
    wrapper: {
      width: 48,
      height: 48,
      position: 'relative',
      animation: [['200ms', 1, 'ease-out', 'forwards']],
      animationName: '$enter',
      '& svg': {
        fill: theme.color.white
      }
    },
    '@keyframes enter': {
      0: {
        transform: 'scale(0)'
      },
      '100%': {
        transform: 'scale(1)'
      }
    }
  }
}
const createCastButton = className => ({
  __html: `<button class="${className} inPlayerView" is="google-cast-button"></button>`
})

class ChromeCastButton extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <div
        className={classes.wrapper}
      >
        <div dangerouslySetInnerHTML={createCastButton(classes.castButton)} />
      </div>
    )
  }
}

export default withStyles(styles)(ChromeCastButton)
