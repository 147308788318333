import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import color from 'color'

import { IMAGE_MARGIN } from '../constants'

const styles = theme => {
  return {
    button: {
      cursor: 'pointer',
      background: 'transparent',
      fontSize: 20,
      fontWeight: 300,
      letterSpacing: 1,
      border: 'none',
      whiteSpace: 'nowrap',

      '&:hover': {
        '&>$underline': {
          backgroundColor: color(theme.color.white)
            .alpha(0.5)
            .toString(),
          transform: [['translateY(2px)', 'scaleX(1)']]
        }
      },

      '&:focus': {
        outline: 'none'
      },

      '&:not(:last-of-type)': {
        marginRight: IMAGE_MARGIN
      }
    },
    current: {
      color: theme.color.primary,
      '&$active>$underline': {
        backgroundColor: [
          [
            color(theme.color.primary)
              .alpha(0.5)
              .toString()
          ],
          '!important'
        ],
        transform: [['translateY(0)', 'scaleX(1)'], '!important'],

        '&>$progress': {
          backgroundColor: theme.color.primary
        }
      }
    },
    active: {
      '&>$underline': {
        backgroundColor: color(theme.color.white)
          .alpha(0.5)
          .toString(),
        transform: [['translateY(0)', 'scaleX(1)'], '!important']
      }
    },
    underline: {
      marginTop: 2,
      width: '100%',
      height: 2,
      transition: [['background', '200ms'], ['transform', 'ease', '200ms']],
      backgroundColor: color(theme.color.white)
        .alpha(0.5)
        .toString(),
      transform: [['translateY(8px)', 'scaleX(0)']]
    },
    progress: {
      height: '100%',
      backgroundColor: theme.color.white
    }
  }
}

function Season({
  children,
  classes,
  isCurrent,
  isActive,
  onClick,
  loadedPercentage
}) {
  return (
    <button
      className={classnames(classes.button, {
        [classes.current]: isCurrent,
        [classes.active]: isActive
      })}
      onClick={onClick}
    >
      {children}
      <div className={classes.underline}>
        <div
          className={classes.progress}
          style={{ width: `${calcLoadedPercentage(loadedPercentage) * 100}%` }}
        />
      </div>
    </button>
  )
}

Season.propTypes = {
  isCurrent: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loadedPercentage: PropTypes.number.isRequired
}
export default withStyles(styles)(Season)

function calcLoadedPercentage(loadedPercentage) {
  if (loadedPercentage <= 0) {
    return 0
  } if (loadedPercentage >= 1) {
    return 1
  }
  return loadedPercentage
}
