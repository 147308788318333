import React from 'react'
import withStyles from 'react-jss'
import color from 'color'

import { StateConsumer } from '../state'

const styles = theme => {
  return {
    wrapper: {
      bottom: 85,
      right: 0,
      marginRight: 15,
      padding: 10,
      position: 'absolute',
      flexDirection: 'column',
      color: theme.color.white,
      backgroundColor: color(theme.color.black)
        .alpha(0.5)
        .toString(),
      borderRadius: 4
    }
  }
}

class AdsCounter extends React.PureComponent {
  render() {
    const { classes } = this.props

    return (
      <StateConsumer>
        {({
          isPlayingAd, totalAds, adsCounter
        }) => {
          return (isPlayingAd && totalAds > 0 && adsCounter > 0) ? (
            <div className={classes.wrapper}>
              { adsCounter }
              &nbsp;/&nbsp;
              { totalAds }
            </div>
          ) : null
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(AdsCounter)
