import youbora from 'youboralib'

/**
 * We implement this function as per youbora suggestion
 * They will try to add this into their HLS SDK bundle in future
 */
export function buildGetRendition(adapter) {
  // eslint-disable-next-line consistent-return
  return () => {
    const { Util } = youbora
    const media = adapter.getTech().hls.playlists.media()
    if (media && media.attributes) {
      const att = media.attributes
      if (att.RESOLUTION) {
        return Util.buildRenditionString(
          att.RESOLUTION.width,
          att.RESOLUTION.height,
          att.BANDWIDTH
        )
      }
      return Util.buildRenditionString(att.BANDWIDTH)
    }
  }
}
