import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import color from 'color'
import classNames from 'classnames'
import SpinnerIcon from './spinner-icon'

const styles = theme => {
  return {
    wrapper: {
      alignItems: 'center',
      bottom: 0,
      display: 'flex',
      position: 'fixed',
      pointerEvents: 'none',
      top: 0,
      right: 0,
      left: 0,
      justifyContent: 'center',
      zIndex: theme.zIndex.loadingSpinner
    },
    svgIcon: {
      height: 80,
      width: 80,
      stroke: theme.color.primary
    },
    overlay: {
      backgroundColor: color(theme.color.dark)
        .alpha(0.6)
        .toString(),
      pointerEvents: 'all'
    },
    spinner: {
      animation: [['infinite', '800ms', 'linear']],
      animationName: '$spin',
      opacity: 0,
      transition: ['opacity', 'ease-in', '100ms'],
      willChange: 'opacity'
    },
    show: {
      opacity: 1
    },
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)'
      },
      to: {
        transform: 'rotate(360deg)'
      }
    }
  }
}

class LoadingSpinnerUi extends React.PureComponent {
  render() {
    const { isInitial, isShown, classes } = this.props
    return (
      <div
        className={classNames(classes.wrapper, {
          [classes.overlay]: isInitial
        })}
      >
        <SpinnerIcon
          className={classNames(classes.spinner, classes.svgIcon, {
            [classes.show]: isShown || isInitial
          })}
        />
      </div>
    )
  }
}

LoadingSpinnerUi.propTypes = {
  isInitial: PropTypes.bool.isRequired,
  isShown: PropTypes.bool.isRequired
}

export default withStyles(styles)(LoadingSpinnerUi)
