import React from 'react'
import { createEventHandler } from 'recompose'
import { merge, fromEvent } from 'rxjs'
import {
  combineLatest,
  mapTo,
  filter,
  distinctUntilChanged
} from 'rxjs/operators'
import PropTypes from 'prop-types'
import VolumeBarUi from './volume-bar-ui'
import { cursorPosPercent, takeValidVolume } from './utils/volume-utils'

class VolumeBar extends React.PureComponent {
  constructor(props) {
    super(props)

    this.getOffsetLeft = this.getOffsetLeft.bind(this)
    this.setRef = this.setRef.bind(this)

    this.onMouseDownHandler = createEventHandler()
    this.onDragHandler = createEventHandler()

    this.mouseUp$ = fromEvent(window, 'mouseup')
    this.mousemove$ = fromEvent(window, 'mousemove')
  }

  componentDidMount() {
    this.cursorVolumeLevelSubscription = merge(
      this.onMouseDownHandler.stream.pipe(mapTo(true)),
      this.mouseUp$.pipe(mapTo(false)),
      this.onDragHandler.stream.pipe(mapTo(false))
    )
      .pipe(
        combineLatest(this.mousemove$, (isActive, mouseMoveEvent) => {
          if (!isActive) return null
          return cursorPosPercent(mouseMoveEvent, this.getOffsetLeft())
        }),
        filter(takeValidVolume),
        distinctUntilChanged((current, next) => current === next)
      )
      .subscribe(volume => {
        this.props.setVolume(volume)
      })
  }

  getOffsetLeft() {
    return this.volumeRef.offsetLeft
  }

  setRef(element) {
    this.volumeRef = element
  }

  render() {
    const { setVolume, isVolumeMute, volume } = this.props
    return (
      <VolumeBarUi
        onMouseDown={this.onMouseDownHandler.handler}
        onDrag={this.onDragHandler.handler}
        onClick={event => {
          setVolume(cursorPosPercent(event, this.getOffsetLeft()))
        }}
        volume={isVolumeMute ? 0 : volume}
        setRef={this.setRef}
      />
    )
  }
}

VolumeBar.propTypes = {
  setVolume: PropTypes.func.isRequired,
  isVolumeMute: PropTypes.bool.isRequired,
  volume: PropTypes.number.isRequired
}

export default VolumeBar
