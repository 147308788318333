export function getTextTrack(textTracks) {
  // textTracks is an object instead of array,
  // so we cannot use iterators
  for (let i = 0; i < textTracks.length; i += 1) {
    if (textTracks[i].kind.toLowerCase() === 'subtitles') {
      return textTracks[i]
    }
  }
  return null
}

export function toggleTextTrack(shouldShowTrack, track) {
  const mode = shouldShowTrack ? 'showing' : 'hidden'
  if (!track) return track

  track.mode = mode
  return track
}
