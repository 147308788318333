import React from 'react'
import PropTypes from 'prop-types'
import { getOnLoadPayload, getPlayPayload } from './utils'
import { AnalyticsDataConsumer } from '../analytics-data'
import GaCore from './ga-core'

class Ga extends React.PureComponent {
  render() {
    const { getStreams, children } = this.props
    return (
      <AnalyticsDataConsumer>
        {data => (
          <GaCore
            getStreams={getStreams}
            onLoadPayload={getOnLoadPayload(data)}
            playPayloadGetter={getPlayPayload(data)}
          >
            {children}
          </GaCore>
        )}
      </AnalyticsDataConsumer>
    )
  }
}

Ga.propTypes = {
  getStreams: PropTypes.func.isRequired
}

export default Ga
