import React from 'react'
import classnames from 'classnames'
import withStyles from 'react-jss'
import color from 'color'
import { Link } from 'react-router-dom'
import { isNil } from 'ramda'
import { StateConsumer } from '../state'
import { unblockPlayback$ } from '../state-stream'

import ModalWrapper from './modal-wrapper'
import { ShowPageQuery } from '../utils/query'
import StartRentalIcon from './start-rental-icon'
import { CloseIconFlat } from './banners/next-episode-countdown/close-icon'
import {
  wrapper, button, hide, title, control
} from './shared-styles'

const styles = theme => {
  return {
    wrapper: {
      extend: wrapper,
      background: theme.color.white,
      boxShadow: theme.shadow.boxShadow(theme.color.black),
      borderRadius: 0
    },
    clockIcon: {
      fill: 'black',
      alignSelf: 'center',
      width: 70,
      height: 70
    },
    closeIcon: {
      alignSelf: 'flex-end'
    },
    hide: {
      extend: hide
    },
    title: {
      extend: title,
      fontSize: 33
    },
    text: {
      marginLeft: 35,
      marginRight: 35,
      textAlign: 'center'
    },
    control: {
      extend: control
    },
    primary: {
      cursor: 'pointer'
    },
    button: {
      extend: button,
      border: [
        [
          1,
          'solid',
          color(theme.color.white)
            .darken(0.2)
            .toString()
        ]
      ],
      color: theme.color.black,
      '&$primary': {
        background: theme.color.primary,
        border: 'none'
      },

      '&:hover': {
        background: color(theme.color.primary)
          .lighten(0.2)
          .toString()
      }
    }
  }
}

class TvodModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.isPlaying = false
  }

  render() {
    const { classes } = this.props
    return (
      <StateConsumer>
        {({
          contentId,
          firstPlayback,
          isTvodModalShown,
          setIsTvodModalShown,
          values
        }) => {
          const startPlayback = () => {
            unblockPlayback$.next(contentId)
          }
          if (this.isPlaying) {
            return null
          }
          if (isNil(firstPlayback)) {
            startPlayback()
            this.isPlaying = true
            return null
          }
          const text = values.PLAYER_TVOD_COUNTDOWN_WARNING_LABEL
          return (
            <ModalWrapper isShown={isTvodModalShown}>
              <div
                className={classnames(classes.wrapper, {
                  [classes.hide]: !isTvodModalShown
                })}
              >
                <ShowPageQuery contentId={contentId}>
                  {({
                    data: showPageData,
                    loading: showPageLoading,
                    error: showPageError
                  }) => {
                    if (showPageLoading || showPageError) return null
                    const { content } = showPageData
                    return (
                      <>
                        <Link to={content.path} className={classes.closeIcon}>
                          <CloseIconFlat />
                        </Link>
                        <StartRentalIcon className={classes.clockIcon} />
                        <h1 className={classes.title}>Start Rental Period</h1>
                        <p className={classes.text}>{text}</p>
                        <div className={classes.control}>
                          <Link to={content.path} className={classes.button}>
                            Cancel
                          </Link>
                          <div
                            onClick={() => {
                              setIsTvodModalShown(false)
                              startPlayback()
                            }}
                            className={classnames(
                              classes.button,
                              classes.primary
                            )}
                          >
                            Watch now
                          </div>
                        </div>
                      </>
                    )
                  }}
                </ShowPageQuery>
              </div>
            </ModalWrapper>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(TvodModal)
