import {
  compose, prop, reduce, head
} from 'ramda'

const getPlaybackInfoArray = prop('playbackInfoMany')

const buildList = reduce((list, info) => {
  const item = head(info.items)
  return {
    ...list,
    [item.contentItemId]: item.playbackInfo
  }
}, {})

export const buildPlaybackInfoMap = compose(
  buildList,
  getPlaybackInfoArray
)
