// Official GPT sources
export const GPT_STANDARD_URL = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js'

// ID of a div element on the page that will contain the ad
export const GPT_IFRAME_CONTAINER_ID = 'gptIframeContainerId'

// Ad on pause default size
export const AD_ON_PAUSE_WIDTH = 1280
export const AD_ON_PAUSE_HEIGHT = 720

// Ad on pause timer
export const AD_ON_PAUSE_TIMEOUT = 1000
