import { VOLUMEBAR_WIDTH } from '../constants'

export function cursorPosPercent(event, offsetLeft) {
  const percent = (event.clientX - offsetLeft) / VOLUMEBAR_WIDTH
  if (percent > 1) return 1
  if (percent < 0) return 0
  return Math.round(percent * 100) / 100
}

export function takeValidVolume(volume) {
  return volume !== null && !isNaN(volume)
}
