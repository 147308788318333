import React from 'react'
import PropTypes from 'prop-types'

import getDeviceInfo from '../../../lib/device-info'
import createContext from '../lib/create-context'
import getContentFromQuery from '../utils/get-content-from-query'
import getContentType from '../utils/get-content-type'
import { ContentItemQuery, AccountQuery } from '../utils/query'

const { Provider, Consumer: AnalyticsDataConsumer } = createContext()

class AnalyticsDataProvider extends React.PureComponent {
  constructor(props) {
    super(props)

    this.deviceInfo = getDeviceInfo()
  }

  render() {
    const {
      children,
      id,
      playbackQuality,
      isTrailer,
      isGuest,
      duration
    } = this.props

    return (
      <ContentItemQuery id={id}>
        {contentItem => {
          // TODO: error handling
          if (contentItem.loading || contentItem.error) return null
          const contentItemPayload = getContentFromQuery(contentItem.data)
          const contentType = getContentType(contentItemPayload, isTrailer)

          // skip the account query if it is a guest user
          if (isGuest) {
            return (
              <Provider
                deviceInfo={this.deviceInfo}
                account={null}
                playbackQuality={playbackQuality}
                contentItem={contentItem.data.contentItem}
                title={contentItemPayload.title}
                houseId={contentItemPayload.ldId || null}
                contentType={contentType}
                duration={duration}
              >
                {children}
              </Provider>
            )
          }

          return (
            <AccountQuery>
              {account => {
                // TODO: error handling?
                if (account.loading || account.error) return null
                return (
                  <Provider
                    deviceInfo={this.deviceInfo}
                    account={account.data.account}
                    playbackQuality={playbackQuality}
                    contentItem={contentItem.data.contentItem}
                    title={contentItemPayload.title}
                    houseId={contentItemPayload.ldId || null}
                    contentType={contentType}
                    duration={duration}
                  >
                    {children}
                  </Provider>
                )
              }}
            </AccountQuery>
          )
        }}
      </ContentItemQuery>
    )
  }
}

AnalyticsDataProvider.propTypes = {
  playbackQuality: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired,
  id: PropTypes.string.isRequired,
  isTrailer: PropTypes.bool.isRequired,
  isGuest: PropTypes.bool.isRequired,
  duration: PropTypes.number.isRequired
}

export default AnalyticsDataProvider
export { AnalyticsDataConsumer }
