/* eslint-disable no-underscore-dangle */
import youbora from 'youboralib'
import 'youbora-adapter-videojs'
import { buildGetRendition, transformOptions } from './utils'

function setupYoubora({ player, options }) {
  const plugin = new youbora.Plugin({
    accountCode: process.env.APP_YOUBORA_ACCOUNT_CODE
  })
  const adapter = new youbora.adapters.Videojs(player)
  // Youbora videojs adapter: https://bitbucket.org/npaw/videojs-adapter-js/src/master/
  // Youbora plugin: https://bitbucket.org/npaw/lib-plugin-js/src/master/
  // Youbora sends a PAUSE event when the pre-roll ad is started, we want to avoid this as it's NOT triggered by user or player.
  // Override function firePause in https://bitbucket.org/npaw/lib-plugin-js/src/master/src/adapter/adapter.js
  adapter.firePause = function (params, triggeredEvent) {
    this._discardFalseBuffers()
    if (this.flags.isBuffering) {
      this.fireBufferEnd(null, 'firePauseCall')
    }
    if (this.flags.isJoined && !this.flags.isPaused) {
      this.flags.isPaused = true
      this.chronos.pause.start()
      if (triggeredEvent) {
        params = params || {}
        params.triggeredEvents = [triggeredEvent]
      }
      if (!this.plugin.isBreakStarted) {
        this.emit(youbora.Adapter.Event.PAUSE, { params })
      }
    }
  }

  try {
    adapter.getRendition = buildGetRendition(adapter)
    plugin.setOptions(transformOptions(options))
    plugin.setAdapter(adapter)
  } catch (error) {
    console.error(error)
  }
  return { adapter, plugin }
}

export default setupYoubora
