import React from 'react'
import withStyles from 'react-jss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import color from 'color'

import { styleBuilder } from './button-styles'

const styles = theme => {
  const { wrapper, button, icon } = styleBuilder(theme)
  return {
    wrapper,
    icon,
    button: {
      ...button,
      '&:active': {
        transform: 'scale(0.8)'
      }
    },
    disabled: {
      fill: color(theme.color.white)
        .darken(0.5)
        .toString(),
      pointerEvents: 'none',
      button: {
        '&:hover': {
          '&>$icon': {
            fill: color(theme.color.white)
              .darken(0.5)
              .toString()
          }
        },
        '&:active': {
          transform: 'none'
        }
      }
    }
  }
}

function Button({
  disabled, onClick, classes, icon
}) {
  const updatedIcon = React.cloneElement(icon, {
    className: classnames(icon.props.className, classes.icon, {
      [classes.disabled]: disabled
    })
  })
  return (
    <div className={classes.wrapper}>
      <button
        disabled={disabled}
        onClick={onClick}
        className={classnames(classes.button, {
          [classes.disabled]: disabled
        })}
      >
        {updatedIcon}
      </button>
    </div>
  )
}

Button.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.element.isRequired,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  onClick: () => {},
  disabled: false
}

export default withStyles(styles, { injectTheme: true })(Button)
