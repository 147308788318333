import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import { VOLUMEBAR_WIDTH } from './constants'

const styles = theme => {
  return {
    wrapper: {
      cursor: 'pointer',
      display: 'flex',
      flex: ['0', '0', '76px'],
      justifyContent: 'center',
      flexDirection: 'column'
    },
    barContainer: {
      width: '100%',
      backgroundColor: 'grey',
      height: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      position: 'relative'
    },
    indicator: {
      width: 12,
      height: 12,
      marginLeft: -6,
      marginTop: -5,
      borderRadius: '50%',
      background: theme.color.white,
      transition: ['background', 'ease-in', '100ms'],
      '&:hover': {
        background: theme.color.primary
      }
    },
    barLeft: {
      background: theme.color.primary
    }
  }
}

class VolumeBarUi extends React.PureComponent {
  render() {
    const {
      classes, onMouseDown, volume, onDrag, onClick, setRef
    } = this.props

    return (
      <div
        className={classes.wrapper}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onDrag={onDrag}
        ref={setRef}
      >
        <div className={classes.barContainer}>
          <div
            className={classes.barLeft}
            style={{
              width: volume * VOLUMEBAR_WIDTH
            }}
          />
          <div className={classes.indicator} />
        </div>
      </div>
    )
  }
}

VolumeBarUi.propTypes = {
  volume: PropTypes.number.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  onDrag: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired
}

export default withStyles(styles)(VolumeBarUi)
