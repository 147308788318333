import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'

import BackToShowPage from './back-to-show-page'
import EpisodeSelectorToggle from './episode-selector-toggle'

const styles = theme => {
  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      height: 64,
      width: '100%',
      order: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: theme.zIndex.header
    }
  }
}

function Header({ classes, isPlayingAd }) {
  return (
    <div className={classes.wrapper}>
      <BackToShowPage />
      {!isPlayingAd && <EpisodeSelectorToggle />}
    </div>
  )
}

Header.propTypes = {
  isPlayingAd: PropTypes.bool
}

Header.defaultProps = {
  isPlayingAd: false
}

export default withStyles(styles)(Header)
