import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'

const styles = theme => {
  return {
    bar: {
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: theme.color.primary
    }
  }
}

function Current({ classes, percent }) {
  if (percent > 1) {
    percent = 1
  }
  return <div className={classes.bar} style={{ width: `${percent * 100}%` }} />
}

Current.propTypes = {
  percent: PropTypes.number.isRequired
}

export default withStyles(styles)(Current)
