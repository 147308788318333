import { useEffect } from 'react'
import PropTypes from 'prop-types'
import setupYoubora from './setup-youbora'

function Youbora({ player, options }) {
  useEffect(() => {
    const { plugin } = setupYoubora({ player, options })
    return () => {
      plugin.disable()
    }
  })
  return null
}

Youbora.propTypes = {
  player: PropTypes.oneOfType([PropTypes.object]),
  options: PropTypes.oneOfType([PropTypes.array])
}

export default Youbora
