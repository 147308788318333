import { isBefore, addMinutes } from 'date-fns'

class Timer {
  constructor() {
    this.current = new Date()
  }

  reset() {
    this.current = new Date()
  }

  isWithinThreshold(threshold) {
    return isBefore(Date.now(), addMinutes(this.current, threshold))
  }
}

export default new Timer()
