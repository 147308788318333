import dark from './dark'
import neon from './neon'
import zIndex from './shared/z-index'
import shadow from './shared/shadow'
import breakpoint from './shared/breakpoint'

export default {
  dark: {
    ...dark, zIndex, shadow, breakpoint
  },
  // REMOVEME: Remove the neon property once we go live with the neon 2.0 branding
  neon: {
    ...neon, zIndex, shadow, breakpoint
  }
}
