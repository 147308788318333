import React from 'react'
import PropTypes from 'prop-types'
import { path, isNil } from 'ramda'
import MoreLikeThisUi from './more-like-this-ui'
import MoreLikeThisQuery from './more-like-this.gql'
import AuthQuery from '../../../lib/auth-query'

class MoreLikeThis extends React.PureComponent {
  render() {
    const { showpageUrl } = this.props

    return (
      <AuthQuery
        query={MoreLikeThisQuery}
        variables={{ screenId: showpageUrl }}
      >
        {carouselInfo => {
          if (carouselInfo.loading || carouselInfo.error) {
            return null
          }
          let carousel
          const carouselList = path(['data', 'screen', 'components'])(
            carouselInfo
          )

          if (carouselList) {
            carousel = carouselList[1]
          }

          if (isNil(carousel)) {
            return null
          }

          return <MoreLikeThisUi carousel={carousel} />
        }}
      </AuthQuery>
    )
  }
}

MoreLikeThis.propTypes = {
  showpageUrl: PropTypes.string.isRequired
}

export default MoreLikeThis
