import { TOOLTIP_WIDTH } from '../constants'

/**
 *
 * @param {Number} mousePosX
 *
 * Calculate the left offset of tooltip for progress bar
 */
export default function tooltipPosition(mousePosX) {
  const maxTooltipPosition = window.innerWidth - TOOLTIP_WIDTH
  const minTooltipPosition = 0
  let toolTipPosition = mousePosX - TOOLTIP_WIDTH / 2
  if (toolTipPosition < minTooltipPosition) {
    toolTipPosition = minTooltipPosition
  }

  if (mousePosX - TOOLTIP_WIDTH / 2 > maxTooltipPosition) {
    toolTipPosition = maxTooltipPosition
  }

  return toolTipPosition
}
