import React from 'react'
import withStyles from 'react-jss'
import color from 'color'
import {
  componentFromStream,
  createEventHandler,
  setDisplayName,
  compose
} from 'recompose'
import {
  combineLatest,
  startWith,
  map,
  mapTo,
  debounce,
  distinctUntilChanged
} from 'rxjs/operators'
import { merge, timer, of } from 'rxjs'

import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'
import Current from './current'
import Buffered from './buffered'
import Tooltip from './tooltip'
import Cursor from './cursor'
import MidrollMarkers from './midroll-markers'
import { PROGRESSBAR_MARGIN } from './constants'

import convertTime from '../../utils/convert-time'
import tooltipPosition from './utils/tooltip-position'

const styles = theme => {
  return {
    wrapper: {
      position: 'absolute',
      top: -14,
      height: 16,
      cursor: 'pointer',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '&:hover': {
        '&>$tube': {
          transform: [['scaleY(2)']]
        }
      }
    },
    tube: {
      position: 'relative',
      height: 3,
      margin: [[0, PROGRESSBAR_MARGIN]],
      borderRadius: 2,
      backgroundColor: color(theme.color.white)
        .darken(0.5)
        .alpha(0.5)
        .toString(),
      boxShadow: theme.shadow.boxShadow(theme.color.black).secondary,
      transform: 'scaleY(1)',
      transition: [
        ['transform', '300ms'],
        ['height', 'linear', '140ms'],
        ['background', 'linear', '140ms']
      ]
    }
  }
}

const enhance = compose(
  withStyles(styles),
  setDisplayName('ProgressBar')
)

const ProgressBar = componentFromStream(props$ => {
  const { handler: onMouseMove, stream: mouseMove$ } = createEventHandler()
  const { handler: onMouseEnter, stream: mouseEnter$ } = createEventHandler()
  const { handler: onMouseLeave, stream: mouseLeave$ } = createEventHandler()

  const isMouseOver$ = merge(
    mouseEnter$.pipe(mapTo(true)),
    mouseLeave$.pipe(mapTo(false))
  )
    .pipe(
      distinctUntilChanged(),
      debounce(isMouseOver => (isMouseOver ? of(true) : timer(400)))
    )
    .pipe(startWith(false))

  return props$.pipe(
    combineLatest(
      isMouseOver$,
      mouseMove$.pipe(
        map(evt => evt.clientX),
        startWith(0)
      ),
      (props, isMouseOver, mousePosX) => {
        const { classes } = props
        return (
          <StateConsumer>
            {({
              getSetters, currentTime, bufferedTime, duration, isPlayingAd, linearAdRolls
            }) => {
              const currentPercent = currentTime / duration

              // calculate cursor time
              const progressBarWidth =
                window.innerWidth - PROGRESSBAR_MARGIN * 2
              let cursorPosPercent =
                (mousePosX - PROGRESSBAR_MARGIN) / progressBarWidth

              if (cursorPosPercent > 1) {
                cursorPosPercent = 1
              }

              let cursorTime = cursorPosPercent * duration

              if (cursorTime < 0) {
                cursorTime = 0
              }

              if (cursorTime > duration) {
                cursorTime = duration
              }

              const cursorString = convertTime(cursorTime)

              return (
                <ControlConsumer>
                  {({ seekTo }) => (
                    <div
                      onClick={() => {
                        // disable to moving position on progress bar during ads playing
                        if (isPlayingAd) return
                        const { setCurrentTime } = getSetters()
                        // fast forward to the position in progress bar
                        setCurrentTime(cursorTime)
                        // seek the player to the position
                        seekTo(cursorTime)
                      }}
                      className={classes.wrapper}
                      onMouseEnter={onMouseEnter}
                      onMouseLeave={onMouseLeave}
                      onMouseMove={onMouseMove}
                    >
                      <Tooltip
                        mousePosX={tooltipPosition(mousePosX)}
                        isShown={isMouseOver}
                      >
                        {cursorString}
                      </Tooltip>
                      <div className={classes.tube}>
                        <Buffered percent={bufferedTime / duration} />
                        <Cursor
                          isShown={isMouseOver}
                          percent={cursorPosPercent}
                        />
                        <MidrollMarkers ads={linearAdRolls} duration={duration} isPlayingAd={isPlayingAd} />
                        <Current
                          percent={
                            // Use default value 0.005 to create a round shaped progress bar
                            // otherwise it would just a box when `percent` is really small
                            currentPercent < 0.005 ? 0.005 : currentPercent
                          }
                        />
                      </div>
                    </div>
                  )}
                </ControlConsumer>
              )
            }}
          </StateConsumer>
        )
      }
    )
  )
})

export default enhance(ProgressBar)
