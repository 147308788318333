import React from 'react'
import withStyles from 'react-jss'

const styles = theme => {
  return {
    icon: {
      fill: theme.color.white,
      filter: theme.shadow.filterShadow(theme.color.black).primary,
      '&:hover': {
        fill: theme.color.primary
      }
    }
  }
}

function IconWrapper({ children, classes }) {
  return React.cloneElement(children, {
    className: classes.icon
  })
}

export default withStyles(styles)(IconWrapper)
