import React from 'react'
import { PLAYER_ID, ACCOUNT_ID } from '../constants'
import { loadScript } from '../../../lib/script-loader'

const scriptUrl = `https://players.brightcove.net/${ACCOUNT_ID}/${PLAYER_ID}_default/index.min.js`

class ScriptLoader extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = { isScriptLoaded: false }

  componentDidMount() {
    this.script = loadScript(scriptUrl, () => {
      this.setState({ isScriptLoaded: true })
    })

    // TODO proper error handling
  }

  componentDidCatch(error) {
    this.setState({ error })
  }

  componentWillUnmount() {
    this.script.remove()
  }

  render() {
    const { children } = this.props

    if (!this.state.isScriptLoaded) return null

    return children
  }
}

export default ScriptLoader
