import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { path, compose, head } from 'ramda'

import PlaybackAuthMutation from './playback-auth-mutation.gql'
import PlaybackInfoQuery from './playback-info.gql'
import PlaybackEventMutation from './playback-event.gql'

import AuthQuery from '../lib/auth-query'
import AuthMutation from '../lib/auth-mutation'
import PromiseResolver from '../lib/promise-resolver'

import { appConfigPropType } from '../utils/prop-types'
import {
  getAdultThreshold,
  getKidsThreshold
} from './utils/get-continue-watching-threshold'
import { getStartCuePoint } from './utils/get-cue-point'
import determineIsKids from './utils/determine-is-kids'
import { HelmetIfNoModal } from '../../helmet-if-no-modal'
import { getDRMSystem } from './utils/drm'
import { getOperatingSystem } from '../../../utils/os-device'

import UiPlaceholder from './ui-placeholder'
import PlanUpgradePopup from '../../modals/plan-upgrade-popup/plan-upgrade-popup'
import StreamingLimitPopup from '../../modals/streaming-limit-popup/streaming-limit-popup'
import { GPT_STANDARD_URL } from '../../google-publisher-tag/constants'

// TODO: we should use the new api for this query
// https://tdv-ott.atlassian.net/browse/LBX-1043
import withConfigCacheOnly from '../../../hoc/with-config'

const getPlaybackResumePoint = compose(
  path(['playbackInfo', 'position']),
  head,
  path(['playbackInfo', 'items'])
)

class Meta extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isStreamingUpgradePopupShown: true
    }
  }

  componentDidUpdate(props) {
    // When step into next episode, we need to hide the video
    // otherwise it is confusing for user
    // to see the show is still playing
    if (props.id !== this.props.id) {
      const { player } = this.props
      player.$('video').hidden = true
    }
  }

  onPopupConfirm = () => {
    this.setState({ isStreamingUpgradePopupShown: false })
  }

  render() {
    const {
      children,
      appConfig,
      id,
      isTrailer,
      player,
      getIsStartFromCuePoint,
      setIsCuePointLoaded
    } = this.props

    // determine continue watching threshold
    const isKid = determineIsKids()
    const continueWatchingThreshold = isKid
      ? getKidsThreshold(appConfig)
      : getAdultThreshold(appConfig)
    let resumePoint = false

    const { name, version } = getOperatingSystem()

    return (
      <PromiseResolver getPromise={() => getDRMSystem()}>
        {drmSystem => {
          if (drmSystem.loading) {
            return <UiPlaceholder />
          }

          return (
            <AuthMutation mutation={PlaybackAuthMutation} ignoreResults={false}>
              {({ mutate, result }) => {
                let request = Promise.resolve()
                if (!result.called) {
                  request = mutate({
                    variables: {
                      drmLevel: drmSystem.data,
                      os: name,
                      osVersion: version,
                      contentItemId: id
                    }
                  })
                }
                if (result.error) {
                  const basicStreamingLimitError = result.error.graphQLErrors?.find(item => item.code === 'BASIC_STREAMING_LIMIT_SVOD')
                  const streamingLimitError = result.error.graphQLErrors?.find(item => item.code === 'STREAMING_LIMIT_SVOD')

                  if (basicStreamingLimitError) {
                    return this.state.isStreamingUpgradePopupShown ? <PlanUpgradePopup appConfig={appConfig} isStreamingUpgradePopupShown onPopupConfirm={this.onPopupConfirm} /> : null
                  }
                  if (streamingLimitError) {
                    return this.state.isStreamingUpgradePopupShown ? <StreamingLimitPopup message={streamingLimitError.message} /> : null
                  }
                  throw result.error
                }

                return (
                  <PromiseResolver getPromise={() => request}>
                    {mutation => {
                      if (mutation.loading) {
                        return <UiPlaceholder />
                      }

                      return (
                        <AuthQuery
                          query={PlaybackInfoQuery}
                          variables={{ id }}
                          fetchPolicy="network-only"
                          skip={isTrailer || getIsStartFromCuePoint()}
                          shouldUpdate={result.loading}
                        >
                          {playbackInfo => {
                            if (playbackInfo.error) resumePoint = 0

                            if (playbackInfo.loading || result.loading) {
                              return <UiPlaceholder />
                            }

                            setIsCuePointLoaded()

                            const purchasedFormat =
                              result.playAuth &&
                              result.playAuth.maxResolution === 'SD'
                                ? 'SD'
                                : 'HD'

                            const svodStreamingResolution =
                              result.playAuth &&
                              result.playAuth.svodStreamingResolution === 'SD'
                                ? 'SD'
                                : 'HD'

                            const video = player.catalog.transformVideoResponse(
                              result.playAuth
                            )
                            const { sources, adOnPauseParams } = video

                            sources.forEach(source => {
                              // Only add the auth token as an eme header for DRM content
                              if (
                                result.playAuth.drmToken &&
                                source.key_systems
                              ) {
                                source.emeHeaders = {
                                  'BCOV-Auth': result.playAuth.drmToken
                                }
                              }
                            })

                            // load content to player
                            player.catalog.load(video)

                            if (!isTrailer) {
                              if (this.props.getIsStartFromCuePoint()) {
                                resumePoint =
                                  getStartCuePoint(result.playAuth) || 0
                              } else if (playbackInfo.data.playbackInfo) {
                                resumePoint =
                                  getPlaybackResumePoint(playbackInfo.data) || 0
                              }
                            } else {
                              resumePoint = 0
                            }

                            return (
                              <AuthMutation mutation={PlaybackEventMutation}>
                                {playbackEventMutation => {
                                  if (playbackEventMutation.result.error) throw playbackEventMutation.result.error

                                  return (
                                    <>
                                      <HelmetIfNoModal>
                                        <meta property="ad:vid" content={result.playAuth?.skyMediaId ?? ''} />
                                        {/* Load GPT for Ad on pause */}
                                        <script async src={GPT_STANDARD_URL} />
                                      </HelmetIfNoModal>
                                      {children({
                                        isGuest: !localStorage.getItem('token'),
                                        continueWatchingThreshold,
                                        resumePoint,
                                        metaData: result.playAuth && {
                                          cuePoints: result.playAuth.cue_points
                                        },
                                        appConfig,
                                        emitCurrentPosition: createEmitCurrentPosition(
                                          playbackEventMutation.mutate
                                        ),
                                        firstPlayback:
                                          result.playAuth &&
                                          result.playAuth.firstPlayback,
                                        purchasedFormat,
                                        svodStreamingResolution,
                                        isSsaiStream: checkIsSsaiStream(video),
                                        adOnPauseParams,
                                        isKid,
                                        contentItem: result.playAuth.contentItem
                                      })}
                                    </>
                                  )
                                }}
                              </AuthMutation>
                            )
                          }}
                        </AuthQuery>
                      )
                    }}
                  </PromiseResolver>
                )
              }}
            </AuthMutation>
          )
        }}
      </PromiseResolver>
    )
  }
}

Meta.propTypes = {
  id: PropTypes.string.isRequired,
  player: PropTypes.shape({
    on: PropTypes.func.isRequired,
    one: PropTypes.func.isRequired,
    qualityLevels: PropTypes.func.isRequired,
    catalog: PropTypes.shape({
      load: PropTypes.func.isRequired,
      transformVideoResponse: PropTypes.func.isRequired
    }).isRequired,
    $: PropTypes.func.isRequired,
    ssai: PropTypes.func.isRequired
  }).isRequired,
  appConfig: appConfigPropType.isRequired,
  isTrailer: PropTypes.bool.isRequired,
  getIsStartFromCuePoint: PropTypes.func.isRequired,
  setIsCuePointLoaded: PropTypes.func.isRequired
}

export default memo(withConfigCacheOnly(Meta))

function createEmitCurrentPosition(mutation) {
  return (type, position, contentId) => {
    mutation({
      variables: {
        input: {
          type,
          position,
          contentItemId: contentId
        }
      }
    })
  }
}

function checkIsSsaiStream(video) {
  if (video.sources[0].vmap) return true
  return false
}
