import {
  path,
  reduce,
  compose,
  evolve,
  head,
  ifElse,
  isNil,
  filter
} from 'ramda'

/**
 * target:
 * {
 *  current:{episode: 1, season: 1},
 *  seasonTag: {1:'Season 1', 2:'Season 2'...},
 *  seasons:{
 *     1:{
 *       1:{
 *        // episode stuff
 *       }
 *     }
 *  }
 * }
 */

const getContentItem = path(['contentItem'])
const getSeasonsFromContentItem = path(['series', 'seasons'])

const transformContentItem = playbackInfoMap => contentItem => {
  const current = getCurrent(contentItem)
  return {
    current,
    seasonTag: getSeasonTag(contentItem),
    seasons: getSeasons(playbackInfoMap, current)(contentItem)
  }
}

function getCurrent(input) {
  return {
    episode: input.episodeNumber.toString(),
    season: input.seasonNumber.toString()
  }
}

const getSeasonTag = compose(
  reduce((seasonTags, season) => {
    return {
      ...seasonTags,
      [season.seasonNumber]: `Season ${season.seasonNumber}`
    }
  }, {}),
  getSeasonsFromContentItem
)

const getEpisode = compose(
  evolve({
    images: head,
    episodeNumber: number => number.toString()
  })
)

const buildCurrentSeason = (playbackInfoMap, current, seasonNumber) => reduce((episodes, episode) => {
  const isCurrentEpisode =
      current.season === seasonNumber &&
      current.episode === episode.episodeNumber.toString()
  return {
    ...episodes,
    [episode.episodeNumber]: {
      ...getEpisode(episode),
      seasonNumber,
      playbackInfo: playbackInfoMap[episode.id],
      isCurrentEpisode
    }
  }
}, {})

const buildSeasonList = (playbackInfoMap, current) => reduce((seasons, season) => {
  return {
    ...seasons,
    [season.seasonNumber]: buildCurrentSeason(
      playbackInfoMap,
      current,
      season.seasonNumber.toString()
    )(season.episodes)
  }
}, {})

const getSeasons = (playbackInfoMap, current) => compose(
  buildSeasonList(playbackInfoMap, current),
  getSeasonsFromContentItem
)

const getEpisodeIdMap = compose(
  reduce((ids, episode) => {
    return [...ids, episode.id]
  }, []),
  filter(({ available }) => available)
)

const buildEpisodeMap = reduce((seasons, season) => {
  return [...seasons, ...getEpisodeIdMap(season.episodes)]
}, [])

export const getEpisodeIds = compose(
  ifElse(isNil, v => v, buildEpisodeMap),
  getSeasonsFromContentItem,
  getContentItem
)

export const transformSeasonData = (episodeInfo, playbackInfoMap) => compose(
  ifElse(isNil, v => v, transformContentItem(playbackInfoMap)),
  getContentItem
)(episodeInfo)
