import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'
import classnames from 'classnames'

import { IMAGE_WIDTH, IMAGE_MARGIN } from '../../constants'
import IconArrow from './icon-arrow'

const styles = theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative'
    },
    navButton: {
      zIndex: theme.zIndex.control,
      position: 'absolute',
      height: '100%',
      width: IMAGE_MARGIN,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: [['transform', '100ms', 'ease']],
      cursor: 'pointer',

      '&:hover': {
        '&>$arrow': {
          backgroundColor: color(theme.color.primary).toString(),

          '&>$icon': {
            fill: theme.color.black
          }
        }
      },

      '&:active': {
        '&>$arrow': {
          transform: 'scale(0.8)'
        }
      }
    },
    left: {
      composes: '$navButton',
      left: 0
    },
    right: {
      composes: '$navButton',
      right: 0
    },
    arrow: {},
    icon: {
      fill: theme.color.white,
      transition: [['fill', '200ms', 'ease-out']]
    },
    leftArrow: {
      composes: '$icon',
      transform: 'rotate(180deg)'
    },
    rightArrow: {
      composes: '$icon',
      transform: 'rotate(0deg)'
    },
    disabled: {
      transform: 'scale(0)'
    }
  }
}

class SliderCarousel extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleRightClick = this.handleRightClick.bind(this)
    this.handleLeftClick = this.handleLeftClick.bind(this)
    this.setScreenDimensionsAndInitialOffset = this.setScreenDimensionsAndInitialOffset.bind(
      this
    )

    this.wrapperRef = React.createRef()
    this.sliderRef = React.createRef()

    this.state = {
      offsetCount: 0
    }
  }

  componentDidMount() {
    this.setScreenDimensionsAndInitialOffset()
  }

  componentDidUpdate(props) {
    if (props.currentSeason !== this.props.currentSeason) {
      this.setScreenDimensionsAndInitialOffset()
    }
  }

  handleLeftClick(evt) {
    evt.stopPropagation()
    if (this.state.offsetCount > 0) {
      this.setState(({ offsetCount }) => ({ offsetCount: offsetCount - 1 }))
    }
  }

  handleRightClick(evt) {
    evt.stopPropagation()
    if (this.state.offsetCount < this.maxScrollRounds) {
      this.setState(({ offsetCount }) => ({ offsetCount: offsetCount + 1 }))
    }
  }

  setScreenDimensionsAndInitialOffset() {
    const { count, focusIndex } = this.props

    // calculate dimensions
    const screenWidth = this.wrapperRef.current.getBoundingClientRect().width
    const sliderWidth = this.sliderRef.current.getBoundingClientRect().width
    this.perScollPx = screenWidth - IMAGE_WIDTH
    this.maxScrollRounds = Math.floor(sliderWidth / this.perScollPx)
    this.lastScrollPx = (sliderWidth % this.perScollPx) - IMAGE_WIDTH

    if (this.lastScrollPx < 0) {
      this.maxScrollRounds -= 1
      this.lastScrollPx =
        sliderWidth - this.perScollPx * this.maxScrollRounds - IMAGE_WIDTH
    }

    // calculate initial offset
    const perScrollItemCount = Math.floor(count / this.maxScrollRounds)
    this.setState({
      offsetCount: Math.floor(focusIndex / perScrollItemCount)
    })
  }

  render() {
    const {
      classes, children, isDisabled, wrapperProps
    } = this.props
    const { offsetCount } = this.state

    let scrollPx = this.perScollPx * offsetCount

    if (offsetCount === this.maxScrollRounds) {
      scrollPx =
        this.lastScrollPx + this.perScollPx * (this.maxScrollRounds - 1)
    } else if (offsetCount === 0) {
      scrollPx = 0
    }

    return (
      <div className={classes.wrapper} ref={this.wrapperRef}>
        <div
          className={classnames(classes.left, {
            [classes.disabled]: offsetCount === 0 || isDisabled
          })}
          onClick={evt => {
            if (offsetCount !== 0) {
              this.handleLeftClick(evt)
            }
          }}
        >
          <IconArrow
            isDisabled={offsetCount === 0}
            className={classes.arrow}
            iconClassName={classes.leftArrow}
          />
        </div>
        <div
          ref={this.sliderRef}
          {...wrapperProps}
          style={{ transform: `translateX(-${scrollPx}px)` }}
        >
          {children}
        </div>
        <div
          className={classnames(classes.right, {
            [classes.disabled]:
              offsetCount === this.maxScrollRounds || isDisabled
          })}
          onClick={evt => {
            if (offsetCount !== this.maxScrollRounds) {
              this.handleRightClick(evt)
            }
          }}
        >
          <IconArrow
            className={classes.arrow}
            iconClassName={classes.rightArrow}
          />
        </div>
      </div>
    )
  }
}

SliderCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  focusIndex: PropTypes.number.isRequired,
  wrapperProps: PropTypes.shape({
    className: PropTypes.string.isRequired
  }).isRequired,
  // We use current season only to determine if reset screen dimensions.
  // We really don't care what's inside
  currentSeason: PropTypes.oneOfType([PropTypes.object]).isRequired,
  isDisabled: PropTypes.bool
}

SliderCarousel.defaultProps = {
  isDisabled: false
}

export default withStyles(styles)(SliderCarousel)
