import React from 'react'

import ContinueWatching from './continue-watching'
import Banners from './banners'
import TvodModal from './tvod-modal'

class Modal extends React.PureComponent {
  render() {
    return (
      <>
        <TvodModal />
        <ContinueWatching />
        <Banners />
      </>
    )
  }
}

export default Modal
