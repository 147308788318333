import React from 'react'
import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'
import FullscreenEnterIcon from './fullscreen-enter-icon'
import FullscreenExitIcon from './fullscreen-exit-icon'
import { ToggleButton } from '../../shared/button'

class FullscreenToggle extends React.PureComponent {
  render() {
    return (
      <StateConsumer>
        {({ isFullscreen }) => (
          <ControlConsumer>
            {({ enterFullscreen, exitFullscreen }) => {
              return (
                <ToggleButton
                  icon1={<FullscreenEnterIcon />}
                  icon2={<FullscreenExitIcon />}
                  isIcon1={isFullscreen}
                  onClick={isFullscreen ? exitFullscreen : enterFullscreen}
                />
              )
            }}
          </ControlConsumer>
        )}
      </StateConsumer>
    )
  }
}

export default FullscreenToggle
