import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

import { buttonStyleBuilder } from '../../shared/button'
import IconTextTrackToggle from './icon-text-track-toggle'
import IconCheck from './icon-check'
import { toggleTextTrack } from '../../../utils/text-track'
import { segmentTrackSubtitleChanged } from '../../../../../segment/segment-track'

const styles = theme => {
  const hoverCheck = {
    backgroundColor: theme.color.primary,
    borderColor: theme.color.black,
    fill: theme.color.black
  }
  const buttonStyle = buttonStyleBuilder(theme)

  return {
    wrapper: {
      position: 'relative',
      animation: [['200ms', 1, 'ease-out', 'forwards']],
      animationName: '$enter',
      transform: 'scale(0)'
    },
    checkContainer: {
      position: 'absolute',
      left: 2,
      bottom: 10,
      width: 15,
      display: 'flex',
      opacity: 0,
      transform: 'scale(0)',
      transition: ['transform', '200ms', 'ease']
    },
    iconWrapper: buttonStyle.wrapper,
    iconButton: {
      ...buttonStyle.button,
      width: 48,
      '&:active': {
        transform: 'scale(0.8)'
      },
      '&:hover': {
        ...buttonStyle.button['&:hover'],
        '& $iconCheck': hoverCheck
      }
    },
    icon: {
      ...buttonStyle.icon
    },
    iconCheck: {
      fill: theme.color.white,
      backgroundColor: theme.color.black,
      borderRadius: 15,
      height: '100%',
      border: [[1, 'solid', theme.color.white]],
      transition: [
        ['border', 'ease-out', '200ms'],
        ['fill', 'ease-out', '200ms'],
        ['background', 'ease-out', '200ms']
      ]
    },
    activeCheck: {
      opacity: 1,
      transform: 'scale(1)'
    },
    '@keyframes enter': {
      0: {
        transform: 'scale(0)'
      },
      '100%': {
        transform: 'scale(1)'
      }
    }
  }
}

class TextTrackToggleUi extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isTextTrackExist: false,
      textTracks: null
    }
  }

  componentDidMount() {
    const { isTextTrackShown } = this.props
    this.showOrHideTextTrack(isTextTrackShown)
  }

  componentDidUpdate(prevProps) {
    const { isTextTrackShown } = this.props
    if (isTextTrackShown !== prevProps.isTextTrackShown) {
      this.showOrHideTextTrack(isTextTrackShown)
    }
  }

  // eslint-disable-next-line no-undef
  showOrHideTextTrack = shouldShowTextTrack => {
    const { textTracks } = this.props
    toggleTextTrack(shouldShowTextTrack, textTracks)
  }

  render() {
    const { saveCloseCaption, isTextTrackShown, classes } = this.props

    return (
      <div
        className={classes.wrapper}
        onClick={() => {
          const toggled = !isTextTrackShown
          saveCloseCaption(toggled)
          this.showOrHideTextTrack(toggled)

          // Add segment data analytics for enabling subtitle
          const pathname = window.location.pathname
          segmentTrackSubtitleChanged({
            contentId: pathname.replace('/watch/', ''),
            toggledOn: toggled
          })
        }}
      >
        <div className={classes.iconWrapper}>
          <button className={classes.iconButton}>
            <IconTextTrackToggle className={classes.icon} />
            <div
              className={classnames(classes.checkContainer, {
                [classes.activeCheck]: isTextTrackShown
              })}
            >
              <IconCheck className={classes.iconCheck} />
            </div>
          </button>
        </div>
      </div>
    )
  }
}

TextTrackToggleUi.propTypes = {
  isTextTrackShown: PropTypes.bool.isRequired,
  saveCloseCaption: PropTypes.func.isRequired,
  textTracks: PropTypes.shape({
    length: PropTypes.number
  }).isRequired
}

export default withStyles(styles)(TextTrackToggleUi)
