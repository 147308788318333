import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import { withScreenWidthWatching } from '../../../hoc/with-screen-width-watching'
import { defineAdOnPause, destroyAdOnPause, getAoPSize } from '../utils'
import { GPT_IFRAME_CONTAINER_ID } from '../constants'

const styles = {
  container: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  }
}

class AdOnPause extends React.PureComponent {
  componentDidMount() {
    const { adOnPauseParams } = this.props
    defineAdOnPause(adOnPauseParams)
  }

  componentWillUnmount() {
    destroyAdOnPause()
  }

  render() {
    const { classes, screenWidth } = this.props

    return (
      <div
        className={classes.container}
        style={getAoPSize(screenWidth)}
        id={GPT_IFRAME_CONTAINER_ID}
      />
    )
  }
}

const enhance = compose(
  withScreenWidthWatching,
  withStyles(styles)
)

AdOnPause.propTypes = {
  adOnPauseParams: PropTypes.shape({
    adUnitPath: PropTypes.string.isRequired,
    videoContentId: PropTypes.string.isRequired,
    videoCmsId: PropTypes.string.isRequired,
    ppid: PropTypes.string.isRequired,
    targetings: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })).isRequired
  }).isRequired,
  screenWidth: PropTypes.number.isRequired
}

export default enhance(AdOnPause)
