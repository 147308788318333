import Color from 'color'
import color from '../shared/color'

export default {
  primary: color.neonGreen,
  black: color.black,
  white: color.white,
  dark: Color(color.black).lighten(0.05).toString(),
  light: Color(color.white).darken(0.05).toString()
}
