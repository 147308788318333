import React from 'react'
import PropTypes from 'prop-types'

class PromiseResolver extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = { error: null, loading: true, data: null }

  componentDidMount() {
    const { getPromise } = this.props
    return getPromise()
      .then(data => this.setState({
        loading: false,
        data
      }))
      .catch(error => {
        this.setState({
          loading: false,
          error
        })
      })
  }

  render() {
    const { children } = this.props

    return children(this.state)
  }
}

PromiseResolver.propTypes = {
  children: PropTypes.func.isRequired,
  getPromise: PropTypes.func.isRequired
}

export default PromiseResolver
