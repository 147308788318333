import React from 'react'
import { path, prop, compose } from 'ramda'
import AuthQuery from '../../lib/auth-query'
import NextEpisodeInfoQuery from './next-episode-info.gql'
import NextEpisodeCountdown from './next-episode-countdown'
import { StateConsumer } from '../../state'

import { convertData } from '../../utils/episode-selection'
import { shouldShowBanner } from './utils/should-show-banner'
import { getDetailsUrl } from '../../../../lib/utils'
import MoreLikeThis from './more-like-this'

const getNextEpisode = compose(
  prop('nextEpisode'),
  convertData
)

const getShowPageUrl = compose(
  getDetailsUrl,
  path(['data', 'contentItem'])
)

class Banner extends React.PureComponent {
  constructor(props) {
    super(props)

    this.dismissBanner = this.dismissBanner.bind(this)

    this.state = {
      isShown: true
    }
  }

  dismissBanner() {
    this.setState({ isShown: false })
  }

  render() {
    const { isShown } = this.state
    return (
      <StateConsumer>
        {({
          getMetaData,
          duration,
          currentTime,
          isContinueWatchingShown,
          contentId: contentIdFromState,
          getStreams,
          values,
          isTrailer,
          saveCurrentPosition,
          isPlaying
        }) => {
          if (isTrailer) return null

          const countdownTime = values.PLAYER_AUTO_PLAY_COUNTDOWN
          const countdownThreshold = values.PLAYER_AUTO_PLAY_THRESHOLD
          const shouldRenderBanner = shouldShowBanner(
            getMetaData,
            duration,
            currentTime,
            isContinueWatchingShown,
            countdownThreshold,
            countdownTime
          )

          let displayCountdownTime = countdownTime

          if (shouldRenderBanner) {
            saveCurrentPosition('STOP')

            const roundedCurrentTime = Math.floor(currentTime)
            if (duration - roundedCurrentTime < displayCountdownTime) {
              displayCountdownTime = duration - roundedCurrentTime
            }
          }

          return (
            (shouldRenderBanner && isShown)
              ? (
                <AuthQuery
                  query={NextEpisodeInfoQuery}
                  variables={{ contentId: contentIdFromState }}
                >
                  {episodeInfo => {
                    if (episodeInfo.loading || episodeInfo.error) return null
                    const episodeData = prop('data')(episodeInfo)
                    const isMovie = !path(['contentItem', 'series'])(episodeData)
                    const showpageUrl = getShowPageUrl(episodeInfo)

                    if (isMovie) {
                      return <MoreLikeThis showpageUrl={showpageUrl} />
                    }

                    const nextEpisode = getNextEpisode(episodeData)
                    if (!nextEpisode) {
                      return <MoreLikeThis showpageUrl={showpageUrl} />
                    }

                    return (
                      <NextEpisodeCountdown
                        nextEpisode={nextEpisode}
                        currentEpisodeInfo={convertData(episodeData).current}
                        getStreams={getStreams}
                        countdownTime={displayCountdownTime}
                        dismissBanner={this.dismissBanner}
                        isPlaying={isPlaying}
                      />
                    )
                  }}
                </AuthQuery>
              )
              : null
          )
        }}
      </StateConsumer>
    )
  }
}

export default Banner
