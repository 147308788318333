import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'

import { setupBcPlayer } from './setup-player'

const styles = theme => {
  return {
    player: {
      backgroundColor: theme.color.black,
      height: '100%',
      width: '100vw',
      position: 'fixed',
      '& > video': {
        height: '100%',
        width: '100%'
      }
    },
    textTrack: {
      '& *': {
        background: ['none', '!important'],
        fontFamily: ['"GothamRounded"', '!important'],
        textShadow: [[theme.color.black, '2px', '2px', 0]]
      }
    },
    hide: {
      display: ['none', '!important']
    }
  }
}

class Core extends React.PureComponent {
  constructor(props) {
    super(props)
    this.createPlayer = this.createPlayer.bind(this)
  }

  componentWillUnmount() {
    this.player.dispose()
  }

  createPlayer() {
    const { anchor, classes, onBCPlayerError } = this.props

    // get player instance
    const player = setupBcPlayer(
      anchor,
      {
        rootClassName: classes.player,
        textTrackClassName: classes.textTrack,
        errorDisplayClassName: classes.hide
      },
      onBCPlayerError
    )

    return player
  }

  render() {
    const { children } = this.props
    this.player = this.createPlayer()
    return children({ player: this.player })
  }
}

Core.propTypes = {
  anchor: PropTypes.instanceOf(Element).isRequired,
  onBCPlayerError: PropTypes.func.isRequired
}

export default withStyles(styles)(Core)
