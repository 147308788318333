import React from 'react'
import PropTypes from 'prop-types'

import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'
import SettingsTable from './settings-table'

class SettingsPanel extends React.PureComponent {
  render() {
    const {
      showQualityUpgradePopup
    } = this.props
    return (
      <ControlConsumer>
        {({ setQuality }) => {
          return (
            <StateConsumer>
              {({
                playbackQualityOptions,
                isSettingsPanelOpen,
                currentQuality,
                toggleSettingsPanelOpen
              }) => {
                return (
                  <SettingsTable
                    {...{
                      playbackQualityOptions,
                      isSettingsPanelOpen,
                      currentQuality,
                      toggleSettingsPanelOpen,
                      setQuality
                    }}
                    showQualityUpgradePopup={showQualityUpgradePopup}
                  />
                )
              }}
            </StateConsumer>
          )
        }}
      </ControlConsumer>
    )
  }
}

SettingsPanel.propTypes = {
  showQualityUpgradePopup: PropTypes.func.isRequired
}

export default SettingsPanel
