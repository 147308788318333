import {
  distinctUntilChanged,
  throttleTime,
  mapTo,
  skipUntil
} from 'rxjs/operators'
import { timer } from 'rxjs'
import { getCurrentTime } from './position-saving-shared'

export const CONTINUE_POSITION_SAVE_INTERVAL_SECOND = 30

export const positionSavingContinue = scheduler => [
  getCurrentTime,
  distinctUntilChanged(),
  throttleTime((CONTINUE_POSITION_SAVE_INTERVAL_SECOND - 1) * 1000, scheduler),
  // We dont want racing condition for `START`/`RESUME` against `CONTINUE` event
  // So we will skip any `CONTINUE` event in the first 1 second
  skipUntil(timer(1000)),
  mapTo(1)
]
