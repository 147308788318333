export const wrapper = {
  padding: [[24, 32]],
  pointerEvents: 'auto',
  borderRadius: 3,
  display: 'flex',
  flexDirection: 'column',
  width: 460,
  transform: 'scale(1)',
  opacity: 1,
  transition: [
    ['transform', '200ms', 'ease-in'],
    ['opacity', '200ms', 'ease-in']
  ]
}

export const button = {
  fontFamily: 'GothamRounded',
  fontSize: 16,
  fontWeight: 500,
  padding: [[12, 36]],
  borderRadius: 3,

  '&:first-of-type': {
    marginRight: 6
  },

  '&:last-of-type': {
    marginLeft: 6
  }
}

export const hide = {
  transform: 'scale(0)',
  opacity: 0
}

export const title = {
  fontFamily: 'GothamRounded',
  fontSize: 25,
  fontWeight: 500,
  textAlign: 'center'
}

export const control = {
  marginTop: 40,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
}
