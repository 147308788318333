import React from 'react'
import { Link } from 'react-router-dom'

import { NextEpisodeQuery } from '../../../utils/query'
import NextEpisodeButton from './next-episode-button'
import { ControlConsumer } from '../../../control'

class NextEpisode extends React.PureComponent {
  render() {
    return (
      <NextEpisodeQuery>
        {({ data, loading, error }) => {
          // TODO error handling?
          if (loading || error) return <NextEpisodeButton />

          const { isTrailerOrMovie, id } = data
          // don't render the button for trailer and movie
          if (isTrailerOrMovie) return null

          // if there's no episode disable the button
          if (!id) {
            return <NextEpisodeButton disabled />
          }

          // other wise render the button
          return (
            <ControlConsumer>
              {({ setShouldUseCuePoint }) => {
                return (
                  <Link to={id} onClick={setShouldUseCuePoint}>
                    <NextEpisodeButton />
                  </Link>
                )
              }}
            </ControlConsumer>
          )
        }}
      </NextEpisodeQuery>
    )
  }
}

export default NextEpisode
