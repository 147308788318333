import React from 'react'
import withStyles from 'react-jss'
import color from 'color'

import { ControlConsumer } from '../../../control'
import { StateConsumer } from '../../../state'
import { Button } from '../../shared/button'
import RewindIcon from './rewind-icon'

const transition = prop => [prop, 'ease-out', '200ms']
const REWIND_SECONDS = 20

const styles = theme => {
  return {
    wrapper: {
      position: 'relative',
      height: 48,
      width: 48,
      margin: [0, 12]
    },
    icon: {
      fill: theme.color.white,
      transition: [transition('fill')],
      filter: `drop-shadow(1px 1px 1px ${color(theme.color.black)
        .alpha(0.3)
        .toString()})`
    }
  }
}
class Rewind extends React.PureComponent {
  render() {
    return (
      <StateConsumer>
        {({ currentTime, getSetters }) => {
          return (
            <ControlConsumer>
              {({ seekTo }) => {
                let target = currentTime - REWIND_SECONDS
                target = target < 0 ? 0 : target
                const { setCurrentTime } = getSetters()

                return (
                  <Button
                    onClick={() => {
                      seekTo(target)
                      setCurrentTime(target)
                    }}
                    icon={<RewindIcon />}
                  />
                )
              }}
            </ControlConsumer>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(Rewind)
