import { path, slice } from 'ramda'
import { format, parseISO } from 'date-fns'
import { isTitle, isSeries, isVideoExtra } from '../../../../lib/content'

export const videoGenre = contentItem => {
  let genre
  if (isTitle(contentItem)) {
    genre = path(['genres', '0'])(contentItem)
  } else if (isVideoExtra(contentItem)) {
    genre = path(['contentItems', '0', 'genres'])(contentItem)[0]
  } else {
    genre = path(['series', 'genres', '0'])(contentItem)
  }
  return genre
}

export const videoAdditionalGenre = contentItem => {
  let additionalGenre

  if (isTitle(contentItem)) {
    additionalGenre = slice(1, Infinity, path(['genres'])(contentItem))
  } else if (isVideoExtra(contentItem)) {
    additionalGenre = slice(
      1,
      Infinity,
      path(['contentItems', '0', 'genres'])(contentItem)
    )
  } else {
    additionalGenre = slice(
      1,
      Infinity,
      path(['series', 'genres'])(contentItem)
    )
  }
  return additionalGenre.length === 0 ? '' : additionalGenre.join(' ')
}

export const videoPublicationDate = contentItem => {
  let publicationDate
  if (isVideoExtra(contentItem)) {
    const startDate = path(['contentItems', '0', 'start'])(contentItem)
    publicationDate = format(parseISO(startDate), 'yyyy-MM-dd')
  } else {
    publicationDate = format(parseISO(contentItem.start), 'yyyy-MM-dd')
  }
  return publicationDate
}

export const videoEndDate = contentItem => {
  let endDate
  if (isVideoExtra(contentItem)) {
    const contentEndDate = path(['contentItems', '0', 'end'])(contentItem)
    endDate = format(parseISO(contentEndDate), 'yyyy-MM-dd')
  } else {
    endDate = format(parseISO(contentItem.end), 'yyyy-MM-dd')
  }
  return endDate
}

export const contentRating = contentItem => {
  let rating
  if (isTitle(contentItem)) {
    rating = path(['rating', 'rating'])(contentItem).toLowerCase()
  } else if (isVideoExtra(contentItem)) {
    rating = path(['contentItems', '0', 'rating', 'rating'])(
      contentItem
    ).toLowerCase()
  } else {
    rating = path(['series', 'rating', 'rating'])(contentItem).toLowerCase()
  }
  return rating
}

export const targetAudience = contentItem => {
  const rating = contentRating(contentItem)
  if (rating === 'g' || rating === 'pg') return 'kids'
  return 'adult'
}

export const isSvodTrailer = contentItem => {
  return isSeries(contentItem.contentItems[0])
}
