import { path } from 'ramda'
import { isTitle, isEpisode, isVideoExtra } from '../../../../lib/content'
import {
  seriesTitleHouseId,
  seasonHouseId,
  episodeHouseId
} from '../../../../lib/analytics/ga'
import {
  videoGenre,
  videoAdditionalGenre,
  videoPublicationDate,
  videoEndDate,
  contentRating,
  targetAudience,
  isSvodTrailer
} from './utils'

const getOnLoadPayload = data => {
  let payload
  const { title, contentItem, contentType } = data
  const videoContentLength = Math.ceil(data.duration / 60)

  const basePayload = {
    showTitle: title,
    videoGenre: videoGenre(contentItem),
    videoAdditionalGenre: videoAdditionalGenre(contentItem),
    videoPublicationDate: videoPublicationDate(contentItem),
    videoEndDate: videoEndDate(contentItem),
    videoContentType: contentType.toLowerCase(),
    videoContentLength,
    targetAudience: targetAudience(contentItem),
    contentRating: contentRating(contentItem)
  }

  if (isEpisode(contentItem)) {
    payload = {
      ...basePayload,
      seriesTitleHouseId: seriesTitleHouseId(contentItem),
      seasonHouseId: seasonHouseId(contentItem),
      episodeHouseId: episodeHouseId(contentItem),
      episodeNumber: contentItem.episodeNumber,
      seasonNumber: contentItem.seasonNumber,
      videoCategory: 'tv'
    }
  }

  if (isTitle(contentItem)) {
    payload = {
      ...basePayload,
      movieId: contentItem.ldId,
      videoCategory: 'movie'
    }
  }

  if (isVideoExtra(contentItem)) {
    payload = {
      ...basePayload,
      videoCategory: 'trailer',
      videoContentType: isSvodTrailer(contentItem)
        ? 'svod trailer'
        : 'tvod trailer'
    }

    const contentId = isSvodTrailer(contentItem)
      ? 'seriesTitleHouseId'
      : 'movieId'
    payload[contentId] = path(['contentItems', '0', 'ldId'])(contentItem)
  }

  return payload
}

export default getOnLoadPayload
