import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'react-apollo'

// Reference:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-mutation-options
// https://www.apollographql.com/docs/react/essentials/mutations.html#api

function Adapter({ children, mutate, result }) {
  return children({ mutate, result })
}

class Mutation extends React.PureComponent {
  render() {
    const { children, mutation, ...options } = this.props
    const EnhancedWrapper = graphql(mutation, { options, skip: options.skip })(Adapter)
    return <EnhancedWrapper>{children}</EnhancedWrapper>
  }
}

Mutation.propTypes = {
  mutation: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default Mutation
