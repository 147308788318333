import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import theme from '../../../../../theme'
import { E2E_SELECTORS } from '../../../../../e2e-selectors'
import { LINEAR_ADS_TYPE } from '../../../constants'

const styles = () => {
  return {
    marker: {
      position: 'absolute',
      height: '100%',
      width: '5px',
      backgroundColor: theme.yellow
    }
  }
}

const MidrollMarkers = ({
  classes,
  ads,
  duration,
  isPlayingAd
}) => {
  return (
    <>
      {
        !isPlayingAd && ads && ads.map(ad => {
          const position = ((ad.startTimeInContent / duration) * 100).toFixed(1)

          // Only display marker for unwatched mid roll ads
          if (ad.watched || ad.type !== 'mid') {
            return null
          }

          return (
            <div
              className={classes.marker}
              style={{
                left: `${position}%`
              }}
              data-lbx-e2e={E2E_SELECTORS.VIDEO_MIDROLL_MARKER}
            />
          )
        })
      }
    </>
  )
}

MidrollMarkers.propTypes = {
  ads: PropTypes.arrayOf(PropTypes.shape(LINEAR_ADS_TYPE)),
  duration: PropTypes.number.isRequired,
  isPlayingAd: PropTypes.bool.isRequired
}

export default withStyles(styles)(MidrollMarkers)
