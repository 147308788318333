import React from 'react'
import withStyles from 'react-jss'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { E2E_SELECTORS } from '../../../../../e2e-selectors'

import { styleBuilder } from './button-styles'

const styles = theme => {
  const buttonStyle = styleBuilder(theme)
  return {
    ...buttonStyle,
    hide: {
      opacity: 0,
      pointerEvents: 'none',
      transform: 'scale(0.3)'
    }
  }
}

function ToggleButton({
  classes, icon1, icon2, onClick, isIcon1
}) {
  return (
    <div className={classes.wrapper} data-lbx-e2e={E2E_SELECTORS.PLAY_PAUSE_TOGGLE_BUTTON}>
      <button
        onClick={onClick}
        className={classnames(classes.button, {
          [classes.hide]: isIcon1
        })}
      >
        {React.cloneElement(icon1, {
          className: classnames(icon1.props.className, classes.icon)
        })}
      </button>

      <button
        onClick={onClick}
        className={classnames(classes.button, {
          [classes.hide]: !isIcon1
        })}
      >
        {React.cloneElement(icon2, {
          className: classnames(icon2.props.className, classes.icon)
        })}
      </button>
    </div>
  )
}

ToggleButton.propTypes = {
  icon1: PropTypes.element.isRequired,
  icon2: PropTypes.element.isRequired,
  isIcon1: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

ToggleButton.defaultProps = {
  onClick: () => {}
}

export default withStyles(styles)(ToggleButton)
