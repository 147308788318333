const getPlayPayload = data => {
  const { title } = data
  const payload = {
    event: 'trackEvent',
    'eventDetails.category': 'video watch',
    'eventDetails.label': window.location.href,
    timestampPlayStart: new Date().getTime(),
    showTitle: title
  }
  return type => {
    if (type === 'init') {
      payload['eventDetails.action'] = 'auto play'
    } else {
      payload['eventDetails.action'] = 'manual play'
    }
    return payload
  }
}

export default getPlayPayload
