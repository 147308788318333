import { getSkipIntroStartTime } from './get-skip-intro-start-time'
import { getSkipIntroEndTime } from './get-skip-intro-end-time'
import { PLAYER_CONTROLLER_DURATION } from '../../constants'

export function shouldShowSkipIntro(
  getMetaData,
  currentTime,
  isUiHidden
) {
  const skipIntroStartTime = getSkipIntroStartTime(getMetaData)
  const skipIntroEndTime = getSkipIntroEndTime(getMetaData)

  // should not show if does not have either skip_intro_start or skip_intro_end
  if (isNaN(skipIntroStartTime) || isNaN(skipIntroEndTime)) return false

  // it automatically dismisses itself after PLAYER_CONTROLLER_DURATION seconds
  // and it should be dismissed at the same time as the player controls being dismiss
  if (isUiHidden && currentTime > skipIntroStartTime + PLAYER_CONTROLLER_DURATION / 1000) return false

  // should show when the current time is between skip_intro_start and skip_intro_end cue points
  return currentTime >= skipIntroStartTime && currentTime < skipIntroEndTime
}
