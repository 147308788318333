const VIDEO_CODEC = 'video/mp4;codecs="avc1.640028"'
const DRM = [
  {
    name: 'WIDEVINE_1',
    priority: 2,
    keySystem: 'com.widevine.alpha',
    robustness: 'HW_SECURE_ALL',
    strategy: 'navigator'
  },
  {
    name: 'WIDEVINE_1',
    priority: 2,
    keySystem: 'com.widevine.alpha',
    robustness: 'HW_SECURE_DECODE',
    strategy: 'navigator'
  },
  {
    name: 'WIDEVINE_3',
    priority: 3,
    keySystem: 'com.widevine.alpha',
    robustness: 'SW_SECURE_DECODE',
    strategy: 'navigator'
  },
  {
    name: 'WIDEVINE_3',
    priority: 3,
    keySystem: 'com.widevine.alpha',
    robustness: 'SW_SECURE_CRYPTO',
    strategy: 'navigator'
  },
  {
    name: 'PLAYREADY_SL3000',
    priority: 2,
    keySystem: 'com.microsoft.playready',
    robustness: '3000',
    strategy: 'navigator'
  },
  {
    name: 'PLAYREADY_SL3000',
    priority: 2,
    keySystem: 'com.microsoft.playready.recommendation',
    robustness: '3000',
    strategy: 'navigator'
  },
  {
    name: 'PLAYREADY_SL2000',
    priority: 3,
    keySystem: 'com.microsoft.playready',
    robustness: '2000',
    strategy: 'navigator'
  },
  {
    name: 'PLAYREADY_SL2000',
    priority: 3,
    keySystem: 'com.microsoft.playready.recommendation',
    robustness: '2000',
    strategy: 'navigator'
  },
  {
    name: 'FAIRPLAY',
    priority: 1,
    keySystem: 'com.apple.fps.1_0',
    robustness: '',
    strategy: 'webkit'
  },
  {
    name: 'FAIRPLAY',
    priority: 1,
    keySystem: 'com.apple.fps.2_0',
    robustness: '',
    strategy: 'webkit'
  }
]

/**
 * Gets the DRM system supported by the browser
 */
export const getDRMSystem = async () => {
  const promises = DRM.map(testDrm)
  const results = await Promise.all(promises)

  const sortedResults = results
    .filter(result => result.supported)
    .sort((a, b) => a.priority - b.priority)

  return sortedResults.length > 0 ? sortedResults[0].name : 'Unknown'
}

const testDrm = async drm => {
  const { strategy } = drm

  switch (strategy) {
    case 'navigator':
      return testNavigatorDrm(drm)
    case 'webkit':
      return testWebkitDrm(drm)
    default:
      throw new Error(`Unknwon DRM testing strategy: ${strategy}`)
  }
}

const testNavigatorDrm = async drm => {
  const { keySystem, robustness } = drm
  const config = getMediaConfig(robustness)

  try {
    await navigator.requestMediaKeySystemAccess(keySystem, config)
    return { ...drm, supported: true }
  } catch (error) {
    return { ...drm, supported: false }
  }
}

const testWebkitDrm = drm => {
  return new Promise(resolve => {
    const { keySystem } = drm

    if (
      window.WebKitMediaKeys &&
      window.WebKitMediaKeys.isTypeSupported(keySystem, VIDEO_CODEC)
    ) {
      resolve({ ...drm, supported: true })
      return
    }

    resolve({ ...drm, supported: false })
  })
}

const getMediaConfig = robustness => {
  return [
    {
      initDataTypes: ['cenc'],
      videoCapabilities: [
        {
          robustness,
          contentType: VIDEO_CODEC
        }
      ]
    }
  ]
}
