import {
  path, find, compose, prop
} from 'ramda'

const ADULT_KEY = 'PLAYER_CONTINUE_WATCHING_THRESHOLD_ADULT_MINUTES'
const KIDS_KEY = 'PLAYER_CONTINUE_WATCHING_THRESHOLD_KIDS_MINUTES'

const getContinueWatchingThresholdByKey = key => compose(
  parseFloat,
  prop('value'),
  find(value => value.key === key),
  path(['values'])
)

export const getAdultThreshold = getContinueWatchingThresholdByKey(ADULT_KEY)
export const getKidsThreshold = getContinueWatchingThresholdByKey(KIDS_KEY)
