import React from 'react'
import withStyles from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { styleBuilder } from '../../shared/button/button-styles'
import VolumeUpIcon from './volume-up-icon'
import VolumeDownIcon from './volume-down-icon'
import VolumeOffIcon from './volume-off-icon'

const styles = theme => {
  const { wrapper, button, icon } = styleBuilder(theme)
  return {
    wrapper: {
      ...wrapper,
      flex: ['0', '0', '24px'],
      marginRight: 15
    },
    button: {
      ...button,
      padding: 0
    },
    icon: {
      ...icon,
      position: 'absolute',
      left: 0,
      opacity: 1,
      transition: [['opacity', 'linear', '100ms']]
    },
    iconDown: {
      left: -2,
      composes: '$icon'
    },
    hide: {
      opacity: 0
    }
  }
}
class VolumeButton extends React.PureComponent {
  constructor(props) {
    super(props)
    this.toggleIsVolumeMute = this.toggleIsVolumeMute.bind(this)
  }

  toggleIsVolumeMute() {
    const { isVolumeMute, setIsVolumeMute } = this.props
    setIsVolumeMute(!isVolumeMute)
  }

  render() {
    const { classes, volume, isVolumeMute } = this.props
    return (
      <div className={classes.wrapper}>
        <div className={classes.button} onClick={this.toggleIsVolumeMute}>
          <VolumeUpIcon
            className={classNames(classes.icon, {
              [classes.hide]: volume < 0.5
            })}
          />
          <VolumeDownIcon
            className={classNames(classes.iconDown, {
              [classes.hide]: volume >= 0.5 || volume === 0
            })}
          />
          <VolumeOffIcon
            className={classNames(classes.icon, {
              [classes.hide]: !isVolumeMute || volume > 0
            })}
          />
        </div>
      </div>
    )
  }
}

VolumeButton.propTypes = {
  volume: PropTypes.number.isRequired,
  setIsVolumeMute: PropTypes.func.isRequired,
  isVolumeMute: PropTypes.bool.isRequired
}

export default withStyles(styles)(VolumeButton)
