import React from 'react'
import withStyles from 'react-jss'
import color from 'color'

const styles = theme => {
  return {
    wrapper: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      zIndex: theme.zIndex.uiPlaceholder
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      height: 64,
      alignItems: 'center',
      margin: [[0, 10]]
    },
    body: {
      flexGrow: 1
    },
    footer: {
      height: 48
    },
    block: {
      margin: 4,
      backgroundColor: color(theme.color.white)
        .alpha(0.1)
        .toString(),
      height: 15,
      animation: [['2s', 'infinite', 'linear']],
      animationName: '$pulse'
    },
    '@keyframes pulse': {
      0: {
        backgroundColor: color(theme.color.white)
          .alpha(0.1)
          .toString()
      },
      '50%': {
        backgroundColor: color(theme.color.white)
          .alpha(0.05)
          .toString()
      },
      '100%': {
        backgroundColor: color(theme.color.white)
          .alpha(0.1)
          .toString()
      }
    },
    progressBar: {
      composes: '$block',
      height: 3,
      margin: [[0, 6]],
      marginTop: -7
    }
  }
}

class UiPlaceholder extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.wrapper}>
        <div className={classes.header} />
        <div className={classes.body} />
        <div className={classes.footer}>
          <div className={classes.progressBar} />
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(UiPlaceholder)
