import React from 'react'
import { getWatchUrl } from '../../../lib/utils'
import { isVideoExtra } from '../../../lib/content'
import { history } from '../../../store'

const { Consumer: ChromecastConsumer, Provider } = React.createContext()

class ChromecastCore extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      ...props
    }
    this.onConnect = this.onConnect.bind(this)
  }

  componentDidMount() {
    document.body.addEventListener('chromecast:connect', this.onConnect)
  }

  componentWillUnmount() {
    document.body.removeEventListener('chromecast:connect', this.onConnect)
  }

  onConnect() {
    const contentItem = { ...this.state.contentItem, id: this.state.contentId }
    const watchUrl = getWatchUrl(contentItem)
    this.state.maybeInitializeCastSender(watchUrl, contentItem)

    if (isVideoExtra(contentItem) || !this.state.showPageUrl) {
      history.goBack()
    } else {
      history.push(this.state.showPageUrl)
    }
  }

  render() {
    const { children } = this.props
    return <Provider value={this.state}>{children}</Provider>
  }
}

export default ChromecastCore
export { ChromecastConsumer }
