import React from 'react'
import classnames from 'classnames'
import withStyles from 'react-jss'
import color from 'color'

import { StateConsumer } from '../../../state'
import SettingsIcon from './settings-icon'
import { buttonStyleBuilder } from '../../shared/button'

const styles = theme => {
  const activeLabel = {
    color: theme.color.black,
    backgroundColor: theme.color.primary,
    borderColor: theme.color.black
  }
  const buttonStyle = buttonStyleBuilder(theme)

  return {
    wrapper: {
      position: 'relative'
    },
    activeIcon: {
      fill: theme.color.primary
    },
    labelContainer: {
      position: 'absolute',
      left: -12,
      bottom: 10,
      width: 38,
      display: 'flex',
      justifyContent: 'flex-end'
    },
    label: {
      transition: [
        ['color', 'ease-out', '200ms'],
        ['border', 'ease-out', '200ms'],
        ['background', 'ease-out', '200ms']
      ],
      border: [[1, 'solid', theme.color.white]],
      backgroundColor: color(theme.color.black)
        .alpha(0.7)
        .toString(),
      color: theme.color.white,
      padding: 2,
      fontSize: 8, // TODO change into dynamic one
      fontWeight: 800
    },
    activeLabel: {
      ...activeLabel
    },
    iconWrapper: buttonStyle.wrapper,
    iconButton: {
      ...buttonStyle.button,
      left: 7,
      width: 48,
      '&:active': {
        transform: 'scale(0.8)'
      },
      '&:hover': {
        ...buttonStyle.button['&:hover'],
        '& $label': activeLabel
      }
    },
    icon: buttonStyle.icon
  }
}

class SettingsButton extends React.PureComponent {
  render() {
    const { classes } = this.props

    return (
      <StateConsumer>
        {({
          toggleSettingsPanelOpen,
          isSettingsPanelOpen,
          currentQuality,
          isSafari
        }) => {
          // Hide the button when using Safari because the data saver function can’t work on Safari
          if (isSafari) return null
          return (
            <div className={classes.wrapper} onClick={toggleSettingsPanelOpen}>
              <div className={classes.iconWrapper}>
                <button className={classes.iconButton}>
                  <SettingsIcon
                    className={classnames(classes.icon, {
                      [classes.activeIcon]: isSettingsPanelOpen
                    })}
                  />
                  <div className={classes.labelContainer}>
                    <span
                      className={classnames(classes.label, {
                        [classes.activeLabel]: isSettingsPanelOpen
                      })}
                    >
                      {currentQuality.label.toUpperCase()}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(SettingsButton)
