import {
  path, compose, find, ifElse, isNil, reduce, maxBy
} from 'ramda'
import { playbackQualityStore } from './storage'

// implemented according to
// https://github.com/videojs/videojs-contrib-quality-levels
export function selectSource(player, selectedBitrate) {
  const levels = player.qualityLevels()

  for (let index = 0; index < levels.length; index += 1) {
    const level = levels[index]

    // should select the lowest source when source all higher then selected
    if (index === 0 || level.bitrate <= selectedBitrate) {
      level.enabled = true
    } else {
      level.enabled = false
    }
  }

  return levels
}

export const getPlaybackQualityOptionsFromAppConfig = path([
  'playbackQualityOptions',
  'wifi'
])

const getDefaultQuality = compose(
  find(option => option.isDefault),
  getPlaybackQualityOptionsFromAppConfig
)

export const getDefaultQualityWithMaxDefault = ifElse(
  compose(
    isNil,
    getDefaultQuality
  ),
  compose(
    reduce(maxBy(option => option.bitrate), { bitrate: 0 }),
    getPlaybackQualityOptionsFromAppConfig
  ),
  getDefaultQuality
)

export const getSDQuality = compose(
  find(option => option.label === 'SD'),
  getPlaybackQualityOptionsFromAppConfig
)

export const findOptionFromStoreId = find(
  option => option.id === playbackQualityStore.get()
)

export const readDefaultQualityFromAppConfig = appConfig => {
  return (
    findOptionFromStoreId(getPlaybackQualityOptionsFromAppConfig(appConfig)) ||
    getDefaultQualityWithMaxDefault(appConfig)
  )
}
