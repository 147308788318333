import { SeriesOverlay, MovieOverlay } from '../overlay'

export const getOverlayComponent = tile => {
  switch (tile.contentItem.__typename) {
    case 'Title':
      return MovieOverlay
    case 'Series':
      return SeriesOverlay
    default:
      console.error(
        `
        ----------------------------------------------------
        unknown overlay type, ${tile.contentItem.__typename}
        please check \`getOverlayComponent\` in \`src/app/components/guest-carousel/utils.js\`.
        resolve this issue BEFORE release!
        ----------------------------------------------------`
      )
      return 'div'
  }
}
