import React from 'react'
import color from 'color'
import withStyles from 'react-jss'
import classnames from 'classnames'

import Query from '../../lib/query'
import EpisodeSelectorEpisodeInfoQuery from './episode-selector-episode-info-query.gql'
import EpisodeSelectorPlaybackInfoQuery from './episode-selector-playback-info-query.gql'
import { StateConsumer } from '../../state'
import {
  transformSeasonData,
  getEpisodeIds
} from './utils/transform-episode-info-data'
import { buildPlaybackInfoMap } from './utils/transform-playback-info-data'
import EpisodeSelectorUi from './episode-selector-ui'

const styles = theme => {
  return {
    overlay: {
      backgroundColor: color(theme.color.black)
        .alpha(0.65)
        .toString(),
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: theme.zIndex.overlay,
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', 'ease', '500ms']]
    },
    open: {
      opacity: 1,
      pointerEvents: 'auto'
    }
  }
}

class EpisodeSelector extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <StateConsumer>
        {({ contentId, isTrailer }) => {
          if (isTrailer) return null
          return (
            <Query
              query={EpisodeSelectorEpisodeInfoQuery}
              variables={{ contentId }}
            >
              {episodeInfo => {
                // TODO Error handling?
                if (episodeInfo.loading || episodeInfo.error) return null
                if (episodeInfo.data.contentItem.__typename !== 'Episode') {
                  return null
                }

                const episodeIds = getEpisodeIds(episodeInfo.data)

                // Need handling this error as well
                if (!episodeIds || episodeIds.length === 0) return null
                return (
                  <StateConsumer>
                    {({ isEpisodeSelectorOpen }) => {
                      if (!isEpisodeSelectorOpen) return null
                      return (
                        <Query
                          query={EpisodeSelectorPlaybackInfoQuery}
                          variables={{ contentItemIds: episodeIds }}
                          fetchPolicy="network-only"
                        >
                          {playbackInfo => {
                            // TODO Error handling?
                            if (playbackInfo.loading || playbackInfo.error) {
                              return null
                            }

                            const playbackInfoMap = buildPlaybackInfoMap(
                              playbackInfo.data
                            )

                            const seasons = transformSeasonData(
                              episodeInfo.data,
                              playbackInfoMap
                            )
                            return (
                              <div
                                className={classnames(classes.overlay, {
                                  [classes.open]: isEpisodeSelectorOpen
                                })}
                              >
                                <EpisodeSelectorUi seasons={seasons} />
                              </div>
                            )
                          }}
                        </Query>
                      )
                    }}
                  </StateConsumer>
                )
              }}
            </Query>
          )
        }}
      </StateConsumer>
    )
  }
}

export default withStyles(styles)(EpisodeSelector)
