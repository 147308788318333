import React from 'react'
import withStyles from 'react-jss'
import { parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'

import PlaybackInfo from './playback-info'
import ComingSoon from './coming-soon'

const styles = {
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  }
}

class EpisodeInfo extends React.PureComponent {
  render() {
    const { episode, classes } = this.props

    if (episode.available) {
      return (
        <StateConsumer>
          {({
            contentId,
            duration,
            currentTime,
            setIsEpisodeSelectorOpen
          }) => {
            if (contentId === episode.id) {
              // <Link onClick={...}/> doesn't work here
              // it will cause the page to render.
              // And cause unexpected result.
              // so we return <div> to attach onClick.

              const numberMinutesRemaining =
                Math.floor((duration - currentTime) / 60) || 0
              const numberMinutesWatched =
                Math.floor(currentTime / 60) || 0

              const currentPlaybackInfo = {
                numberMinutesRemaining,
                numberMinutesWatched,
                position: currentTime || 0,
                status:
                  numberMinutesRemaining === 0 &&
                  numberMinutesWatched === 0
                    ? 'NOT_STARTED'
                    : 'IN_PROGRESS'
              }

              return (
                <ControlConsumer>
                  {({
                    play,
                    pause,
                    disableKeydownListener,
                    enableKeydownListener
                  }) => {
                    <div
                      className={classes.wrapper}
                      onClick={() => setIsEpisodeSelectorOpen(
                        play,
                        pause,
                        disableKeydownListener,
                        enableKeydownListener
                      )}
                    >
                      <PlaybackInfo
                        wrapperClassName={classes.wrapper}
                        playbackInfo={currentPlaybackInfo}
                      />
                    </div>
                  }}
                </ControlConsumer>
              )
            }

            return (
              <ControlConsumer>
                {({ setShouldNotUseCuePoint }) => {
                  const onClick = () => {
                    setShouldNotUseCuePoint()
                  }
                  return (
                    <Link
                      to={`${episode.id}`}
                      onClick={onClick}
                    >
                      <PlaybackInfo
                        wrapperClassName={classes.wrapper}
                        playbackInfo={episode.playbackInfo}
                      />
                    </Link>
                  )
                }}
              </ControlConsumer>
            )
          }}
        </StateConsumer>
      )
    }

    if (episode.isComingSoon) {
      return (
        <ComingSoon
          wrapperClassName={classes.wrapper}
          startDate={parseISO(episode.start)}
        />
      )
    }

    return null
  }
}

EpisodeInfo.propTypes = {
  episode: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isComingSoon: PropTypes.bool.isRequired,
    available: PropTypes.bool.isRequired,
    start: PropTypes.string.isRequired,
    playbackInfo: PropTypes.oneOfType([PropTypes.object])
  }).isRequired
}

export default withStyles(styles)(EpisodeInfo)
