import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

import IconPlayCircle from './icon-play-circle'
import IconPlayProgress from './icon-play-progress'
import IconReplay from './icon-replay'
import getTimeString from './get-time-string'

const styles = theme => {
  return {
    wrapper: {
      flexDirection: 'column'
    },
    icon: {
      fill: theme.color.white,
      height: 64,
      width: 'auto',
      filter: theme.shadow.filterShadow(theme.color.black).primary
    },
    timeDisplay: {
      fontSize: 16,
      textShadow: theme.shadow.textShadow(theme.color.black).primary
    },
    watch: {
      fontSize: 20
    }
  }
}

function PlaybackInfo({ playbackInfo, classes, wrapperClassName }) {
  const { numberMinutesRemaining, numberMinutesWatched } = playbackInfo
  const time = getTimeString(numberMinutesRemaining)
  const totalTime = numberMinutesWatched + numberMinutesRemaining

  switch (playbackInfo.status) {
    case 'IN_PROGRESS': {
      const progress = numberMinutesWatched / totalTime
      return (
        <div className={classnames(wrapperClassName, classes.wrapper)}>
          <IconPlayProgress className={classes.icon} progress={progress} />
          <div className={classes.timeDisplay}>
            {time}
            {' '}
            remaining
          </div>
        </div>
      )
    }

    case 'FINISHED':
      return (
        <div className={classnames(wrapperClassName, classes.wrapper)}>
          <IconReplay className={classes.icon} />
          <div className={classes.timeDisplay}>Watch again</div>
          <div className={classes.timeDisplay}>{time}</div>
        </div>
      )

    // use 'NOT_STARTED' as default scenario
    default:
      return (
        <div className={classnames(wrapperClassName, classes.wrapper)}>
          <IconPlayCircle className={classes.icon} />
          <div className={classnames(classes.timeDisplay, classes.watch)}>
            Watch
          </div>
          <div className={classes.timeDisplay}>{time}</div>
        </div>
      )
  }
}

PlaybackInfo.propTypes = {
  playbackInfo: PropTypes.shape({
    status: PropTypes.string.isRequired,
    numberMinutesRemaining: PropTypes.number.isRequired,
    numberMinutesWatched: PropTypes.number.isRequired
  }).isRequired,
  wrapperClassName: PropTypes.string.isRequired
}

export default withStyles(styles)(PlaybackInfo)
