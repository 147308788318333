import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'
import classnames from 'classnames'

import SettingOption from './setting-option'

const styles = theme => {
  return {
    wrapper: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      margin: [[0, 12, 12, 0]],
      padding: [[8, 0]],
      backgroundColor: color(theme.color.black)
        .alpha(0.6)
        .toString(),
      borderRadius: 10,
      boxShadow: theme.shadow.boxShadow(theme.color.black).primary,
      color: theme.color.white,
      opacity: 1,
      transform: [['scale(1)', 'translate3d(0,0,0)']],
      transition: [['opacity', '300ms', 'ease'], ['transform', '180ms', 'ease']]
    },
    hide: {
      opacity: 0,
      transform: [['scale(0.5)', 'translate3d(200px, 60px, 0)']],
      pointerEvents: 'none'
    },
    disabled: {
      pointerEvents: 'none'
    },
    table: {
      width: '100%',
      height: '100%',
      borderCollapse: 'collapse'
    }
  }
}

class SettingsTable extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleOnClick = this.handleOnClick.bind(this)
    this.setDisable = this.setDisable.bind(this)

    this.state = {
      selectedOption: props.currentQuality,
      isDisabled: false
    }
  }

  componentDidUpdate(props) {
    if (props.currentQuality !== this.props.currentQuality) {
      this.props.toggleSettingsPanelOpen()
    }
  }

  handleOnClick(option) {
    this.setState({ selectedOption: option }, () => this.props.setQuality(option).then(() => {
      this.setState({ isDisabled: false })
    }))
  }

  setDisable() {
    this.setState({ isDisabled: true })
  }

  render() {
    const {
      classes,
      isSettingsPanelOpen,
      playbackQualityOptions,
      showQualityUpgradePopup
    } = this.props

    return (
      <div
        className={classnames(classes.wrapper, {
          [classes.hide]: !isSettingsPanelOpen,
          [classes.disabled]: this.state.isDisabled
        })}
        onClick={evt => {
          evt.stopPropagation()
        }}
        onDoubleClick={evt => {
          evt.stopPropagation()
        }}
      >
        <table className={classes.table}>
          <tbody>
            {playbackQualityOptions.wifi.map(option => {
              return (
                <SettingOption
                  option={option}
                  isActive={option.id === this.state.selectedOption.id}
                  key={option.id}
                  setDisable={this.setDisable}
                  onClick={() => this.handleOnClick(option)}
                  showQualityUpgradePopup={showQualityUpgradePopup}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

const QualityOptionPropType = PropTypes.shape({
  id: PropTypes.string.isRequired
})

SettingsTable.propTypes = {
  isSettingsPanelOpen: PropTypes.bool.isRequired,
  playbackQualityOptions: PropTypes.shape({
    wifi: PropTypes.arrayOf(QualityOptionPropType.isRequired).isRequired
  }).isRequired,
  currentQuality: QualityOptionPropType.isRequired,
  toggleSettingsPanelOpen: PropTypes.func.isRequired,
  setQuality: PropTypes.func.isRequired,
  showQualityUpgradePopup: PropTypes.func
}

export default withStyles(styles)(SettingsTable)
