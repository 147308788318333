import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { equals } from 'ramda'

// This is a workaround to enable us to continue player upgrade.
// It should be removed after https://tdv-ott.atlassian.net/browse/LBX-1057

function Adapter({ children, data }) {
  // When the query is skipped, data will be nil
  if (!data) return children({ data })

  const { error, loading, ...other } = data
  return children({ data: other, error, loading })
}

class Query extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      !equals(this.props.query, nextProps.query) ||
      !equals(this.props.variables, nextProps.variables) ||
      !equals(this.props.shouldUpdate, nextProps.shouldUpdate)
    )
  }

  render() {
    const { children, query, ...options } = this.props
    const EnhancedWrapper = graphql(query, { options, skip: options.skip })(Adapter)
    return <EnhancedWrapper>{children}</EnhancedWrapper>
  }
}

Query.propTypes = {
  query: PropTypes.oneOfType([PropTypes.object]).isRequired, // eslint-disable-line
  variables: PropTypes.oneOfType([PropTypes.object]),
  shouldUpdate: PropTypes.bool
}

Query.defaultProps = {
  variables: undefined,
  shouldUpdate: false
}

export default Query
