import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import color from 'color'

import { TOOLTIP_WIDTH } from './constants'

const styles = theme => {
  return {
    wrapper: {
      position: 'absolute',
      marginTop: '-34px',
      opacity: 0,
      pointerEvents: 'none',
      transition: [['opacity', 'ease', '300ms']],
      width: TOOLTIP_WIDTH,
      padding: [[4, 6]],
      borderRadius: 4,
      backgroundColor: color(theme.color.black)
        .alpha(0.3)
        .toString(),
      textAlign: 'center',
      willChange: 'transform'
    },
    show: {
      opacity: 1
    }
  }
}

class Tooltip extends React.PureComponent {
  // eslint-disable-next-line no-undef
  state = { xPos: 0 }

  render() {
    const {
      classes, mousePosX, isShown, children
    } = this.props

    return (
      <div
        className={classnames(classes.wrapper, {
          [classes.show]: isShown
        })}
        style={{ transform: `translateX(${mousePosX}px)` }}
      >
        {children}
      </div>
    )
  }
}

Tooltip.propTypes = {
  mousePosX: PropTypes.number.isRequired,
  isShown: PropTypes.bool.isRequired
}

export default withStyles(styles)(Tooltip)
