import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import Modal from '../../modal'
import styles from './streaming-limit-popup.css'

function StreamingLimitPopup({ message }) {
  const history = useHistory()

  const onDismiss = () => {
    if (history.location.key) {
      // Back to previous page
      history.goBack()
    } else {
      // In a new tab, back to home page
      history.push('/')
    }
  }

  return (
    <Modal
      noRouting
    >
      <div className={styles.contentBox}>
        <div className={styles.contentFrame}>
          <div className={styles.text}>{message}</div>
          <div className={styles.buttonGroup}>
            <button
              onClick={onDismiss}
              className={classNames(styles.button, styles.closeButton)}
              data-e2e="streaming-limit-close-button"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

StreamingLimitPopup.propTypes = {
  message: PropTypes.string
}

StreamingLimitPopup.defaultProps = {
  message: ''
}

export default StreamingLimitPopup
