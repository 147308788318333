import React from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { apm } from '@elastic/apm-rum'
import Query from '../query'
import TokenQuery from './token.gql'

const getToken = path(['account', 'session', 'token'])

function withAuth(Graphql) {
  const WithAuthGraphql = props => {
    const { children } = props
    let token = localStorage.getItem('token') || sessionStorage.getItem('token')
    const updatedContext = props.context || {}
    updatedContext.headers = updatedContext.headers || {}

    if (token) {
      updatedContext.headers.authorization = `bearer ${token}`
      return (
        <Graphql {...props} context={updatedContext}>
          {children}
        </Graphql>
      )
    }

    return (
      <Query
        query={TokenQuery}
        fetchPolicy="cache-only"
        skip={token}
        shouldUpdate={props.shouldUpdate}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return null
          }

          if (error) {
            // TODO: error handling?
            console.error(error)
            return null
          }

          token = getToken(data)
          if (token) {
            updatedContext.headers.authorization = `bearer ${token}`
          } else {
            apm.captureError(new Error('Player auth token not defined'))
          }
          return (
            <Graphql {...props} context={updatedContext}>
              {children}
            </Graphql>
          )
        }}
      </Query>
    )
  }

  WithAuthGraphql.displayName = `WithAuthGraphql${Graphql.displayName}`

  WithAuthGraphql.propTypes = {
    context: PropTypes.oneOfType([PropTypes.object]),
    shouldUpdate: PropTypes.bool
  }

  WithAuthGraphql.defaultProps = {
    context: {},
    shouldUpdate: false
  }

  return WithAuthGraphql
}

export default withAuth
