const transition = prop => [prop, 'ease-out', '200ms']

// TODO: prefixing those ones?
export const styleBuilder = theme => {
  return {
    wrapper: {
      position: 'relative',
      height: 48,
      width: 48
    },
    button: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 1,
      pointerEvents: 'auto',
      transition: [transition('opacity'), transition('transform')],
      background: 'transparent',
      border: 'none',
      '&:hover': {
        '&>$icon': {
          fill: theme.color.primary
        }
      },
      '&:focus': {
        outline: 'none'
      }
    },
    icon: {
      fill: theme.color.white,
      transition: [transition('fill')],
      filter: theme.shadow.filterShadow(theme.color.black).primary
    }
  }
}
