import React from 'react'
import withStyles from 'react-jss'

import { StateConsumer } from '../../state'
import { ControlConsumer } from '../../control'
import { GaConsumer } from '../../ga/ga-core'
import PauseButton from './pause-button'
import SettingsPanel from './settings-panel'
import { E2E_SELECTORS } from '../../../../e2e-selectors'
import PlanUpgradePopup from '../../../modals/plan-upgrade-popup/plan-upgrade-popup'

const styles = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    order: 1
  }
}

function Body({ classes }) {
  const showPopup = (pause, setIsQualityUpgradePopupShown) => {
    pause()
    setIsQualityUpgradePopupShown(true)
  }

  const onPopupDismiss = (play, emitPlayDataLayerEvent, setIsQualityUpgradePopupShown) => {
    play()
    emitPlayDataLayerEvent()
    setIsQualityUpgradePopupShown(false)
  }

  const onPopupConfirm = (pause) => {
    pause()
  }

  return (
    <StateConsumer>
      {({
        isFullscreen,
        isPlaying,
        isSettingsPanelOpen,
        toggleSettingsPanelOpen,
        setIsVolumeBarVisible,
        appConfig,
        setIsQualityUpgradePopupShown,
        isQualityUpgradePopupShown
      }) => (
        <ControlConsumer>
          {({
            enterFullscreen, exitFullscreen, play, pause
          }) => {
            return (
              <GaConsumer>
                {({ emitPlayDataLayerEvent }) => (
                  <div
                    className={classes.wrapper}
                    data-lbx-e2e={E2E_SELECTORS.VIDEO_BODY}
                    onClick={() => {
                      if (!isQualityUpgradePopupShown) {
                        if (isSettingsPanelOpen) {
                          toggleSettingsPanelOpen()
                          return
                        }
                        if (isPlaying) {
                          pause()
                        } else {
                          play()
                          emitPlayDataLayerEvent()
                        }
                        setIsVolumeBarVisible(false)
                      }
                    }}
                    onDoubleClick={() => {
                      if (!isQualityUpgradePopupShown) {
                        if (isFullscreen) {
                          exitFullscreen()
                        } else {
                          enterFullscreen()
                        }
                      }
                    }}
                  >
                    <PauseButton />
                    {isQualityUpgradePopupShown && (
                      <PlanUpgradePopup
                        appConfig={appConfig}
                        isQualityUpgradePopupShown={isQualityUpgradePopupShown}
                        onPopupDismiss={() => onPopupDismiss(play, emitPlayDataLayerEvent, setIsQualityUpgradePopupShown)}
                        onPopupConfirm={() => onPopupConfirm(pause)}
                      />
                    )}
                    <SettingsPanel showQualityUpgradePopup={() => showPopup(pause, setIsQualityUpgradePopupShown)} />
                  </div>
                )}
              </GaConsumer>
            )
          }}
        </ControlConsumer>
      )}
    </StateConsumer>
  )
}

export default withStyles(styles)(Body)
