import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import classnames from 'classnames'

import DownIcon from './down-icon'
import { StateConsumer } from '../../../state'
import { ControlConsumer } from '../../../control'

const styles = theme => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme.color.white,
      marginLeft: 4,
      padding: [[0, 4, 0, 10]],
      height: 36,
      cursor: 'pointer',
      transition: [
        ['background', 'ease', '300ms'],
        ['boxShadow', 'ease', '300ms'],
        ['color', 'ease', '300ms']
      ],

      '&:hover': {
        color: theme.color.primary,
        '&>$upperCrossIcon': {
          fill: theme.color.primary,
          transform: `rotate(0)`
        }
      }
    },
    active: {
      color: [[theme.color.black], '!important'],
      backgroundColor: theme.color.primary,
      boxShadow: theme.shadow.boxShadow(theme.color.black).primary,

      '&>$upperCrossIcon': {
        transform: [[`translateY(-3px)`], '!important'],
        fill: [[theme.color.black], '!important'],
        filter: [['none'], '!important']
      },

      '&>$lowerCrossIcon': {
        opacity: 1,
        transform: [['rotate(180deg)', 'translateX(24px)', 'translateY(-2px)']]
      },

      '&>$text': {
        textShadow: [['none'], '!important']
      }
    },
    text: {
      marginRight: 4,
      textShadow: theme.shadow.textShadow(theme.color.black).primary
    },
    upperCrossIcon: {
      fill: theme.color.white,
      filter: theme.shadow.filterShadow(theme.color.black).primary,
      transition: [['transform', 'ease', '300ms'], ['fill', 'ease', '300ms']],
      transform: `rotate(-90deg)`
    },
    overlay: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'red'
    },
    lowerCrossIcon: {
      opacity: 0,
      fill: theme.color.black,
      transition: [
        ['transform', 'ease', '300ms'],
        ['opacity', 'ease', '100ms']
      ],
      transform: [['rotate(180deg)', 'translateX(24px)', 'translateY(-6px)']],
      marginRight: -24
    }
  }
}

class EpisodeSelectorToggleUi extends React.PureComponent {
  render() {
    const { classes, seasonEpisodeNumber } = this.props
    return (
      <StateConsumer>
        {({ setIsEpisodeSelectorOpen, isEpisodeSelectorOpen }) => {
          return (
            <ControlConsumer>
              {({
                play,
                pause,
                disableKeydownListener,
                enableKeydownListener
              }) => {
                return (
                  <div
                    className={classnames(classes.wrapper, {
                      [classes.active]: isEpisodeSelectorOpen
                    })}
                    onClick={() => setIsEpisodeSelectorOpen(
                      play,
                      pause,
                      disableKeydownListener,
                      enableKeydownListener
                    )}
                  >
                    <span className={classes.text}>{seasonEpisodeNumber}</span>
                    <DownIcon className={classes.upperCrossIcon} />
                    <DownIcon className={classes.lowerCrossIcon} />
                  </div>
                )
              }}
            </ControlConsumer>
          )
        }}
      </StateConsumer>
    )
  }
}

EpisodeSelectorToggleUi.propTypes = {
  seasonEpisodeNumber: PropTypes.string.isRequired
}

export default withStyles(styles)(EpisodeSelectorToggleUi)
