import React from 'react'
import classnames from 'classnames'
import withStyles from 'react-jss'
import color from 'color'
import { Link } from 'react-router-dom'
import { StateConsumer } from '../state'
import { ControlConsumer } from '../control'

import ModalWrapper from './modal-wrapper'
import Timer from '../utils/timer'
import { NextEpisodeQuery, ShowPageQuery } from '../utils/query'
import {
  wrapper, button, hide, title, control
} from './shared-styles'

const styles = theme => {
  return {
    wrapper: {
      extend: wrapper,
      background: theme.color.white,
      boxShadow: theme.shadow.boxShadow(theme.color.black)
    },
    hide: {
      extend: hide
    },
    title: {
      extend: title
    },
    control: {
      extend: control
    },
    primary: {},
    button: {
      extend: button,
      border: [
        [
          1,
          'solid',
          color(theme.color.white)
            .darken(0.2)
            .toString()
        ]
      ],
      color: theme.color.black,
      cursor: 'pointer',
      '&$primary': {
        background: theme.color.primary,
        border: 'none'
      },

      '&:hover': {
        background: color(theme.color.primary)
          .lighten(0.2)
          .toString()
      }
    }
  }
}

class ContinueWatching extends React.PureComponent {
  render() {
    const { classes } = this.props

    return (
      <NextEpisodeQuery>
        {({ data, loading, error }) => {
          if (loading || error) return null

          const { isTrailerOrMovie, id } = data
          // render nothing when trailer, movie, or no next episode.
          if (isTrailerOrMovie || !id) return null

          return (
            <StateConsumer>
              {({ isContinueWatchingShown: isShown }) => {
                return (
                  <ControlConsumer>
                    {({ redirectToEpisodeById }) => {
                      return (
                        <ModalWrapper isShown={isShown}>
                          <div
                            className={classnames(classes.wrapper, {
                              [classes.hide]: !isShown
                            })}
                          >
                            <h3 className={classes.title}>
                              Are you still watching?
                            </h3>
                            <div className={classes.control}>
                              <button
                                className={classnames(
                                  classes.button,
                                  classes.primary
                                )}
                                onClick={() => {
                                  Timer.reset()
                                  redirectToEpisodeById(id)
                                }}
                              >
                                Yes, continue
                              </button>
                              <ShowPageQuery>
                                {({
                                  data: showPageData,
                                  loading: showPageLoading,
                                  error: showPageError
                                }) => {
                                  if (showPageLoading || showPageError) {
                                    return null
                                  }
                                  const { content } = showPageData
                                  return (
                                    <Link
                                      to={content.path}
                                      className={classes.button}
                                    >
                                      No thank you
                                    </Link>
                                  )
                                }}
                              </ShowPageQuery>
                            </div>
                          </div>
                        </ModalWrapper>
                      )
                    }}
                  </ControlConsumer>
                )
              }}
            </StateConsumer>
          )
        }}
      </NextEpisodeQuery>
    )
  }
}

export default withStyles(styles)(ContinueWatching)
