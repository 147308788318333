import React from 'react'
import PropTypes from 'prop-types'

import Query from '../../lib/query'
import showPageQuery from './showpage-query.gql'
import { getContentData, getEpisodeRating } from '../get-showpage'
import { StateConsumer } from '../../state'

function ShowPageQuery({ children, contentId }) {
  if (contentId) {
    return (
      <Query query={showPageQuery} variables={{ contentId }}>
        {passToChildren(children)}
      </Query>
    )
  }
  return (
    <StateConsumer>
      {({ contentId: contentIdFromState }) => {
        return (
          <Query
            query={showPageQuery}
            variables={{ contentId: contentIdFromState }}
          >
            {passToChildren(children)}
          </Query>
        )
      }}
    </StateConsumer>
  )
}

ShowPageQuery.propTypes = {
  contentId: PropTypes.string
}

ShowPageQuery.defaultProps = {
  contentId: null
}

function passToChildren(children) {
  return ({ data, loading, error }) => {
    if (loading || error) {
      return children({ data: null, loading, error })
    }

    const content = getContentData(data)
    const episodeRating = getEpisodeRating(data)

    return children({
      data: { content, episodeRating }
    })
  }
}

export default ShowPageQuery
