function getContentType(contentItem, isTrailer) {
  // Episode or SVOD Movie = "SVOD"
  // Episode or SVOD Movie Trailer = "SVOD Trailer"
  // TVOD Movie = "TVOD"
  // TVOD Movie Trailer = "TVOD Trailer"
  let type = contentItem.productType ?? 'SVOD'
  if (isTrailer) {
    type = `${type} Trailer`
  }

  return type
}

export default getContentType
