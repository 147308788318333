import React from 'react'
import PropTypes from 'prop-types'
import withStyles from 'react-jss'
import { format } from 'date-fns'
import classnames from 'classnames'

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    fontWeight: 500,
    marginTop: 18,
    boxShadow: theme.shadow.boxShadow(theme.color.black).primary
  },
  text: {
    textTransform: 'uppercase',
    padding: [[6, 9]],
    width: 90,
    color: theme.color.black,
    background: theme.color.primary,
    textAlign: 'center',
    marginBottom: 12
  },
  date: {
    textShadow: theme.shadow.textShadow(theme.color.black).primary
  }
})

function ComingSoon({ classes, wrapperClassName, startDate }) {
  return (
    <div className={classnames(wrapperClassName, classes.wrapper)}>
      <div className={classes.text}>coming soon</div>
      <div className={classes.date}>{format(startDate, 'MMMM do')}</div>
    </div>
  )
}

ComingSoon.propTypes = {
  wrapperClassName: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired
}

export default withStyles(styles)(ComingSoon)
