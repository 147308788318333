import React from 'react'
import LoadingSpinnerUi from './loading-spinner-ui'

import { StateConsumer } from '../../state'

class LoadingSpinner extends React.PureComponent {
  render() {
    return (
      <StateConsumer>
        {({ isInitialLoadingDone, isLoading }) => {
          return (
            <LoadingSpinnerUi
              isInitial={!isInitialLoadingDone}
              isShown={isLoading}
            />
          )
        }}
      </StateConsumer>
    )
  }
}

export default LoadingSpinner
