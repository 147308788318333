import React from 'react'

import { ControlConsumer } from '../../../control'
import { StateConsumer } from '../../../state'
import { GaConsumer } from '../../../ga/ga-core'

import PlayIcon from './play-icon'
import PauseIcon from './pause-icon'
import { ToggleButton } from '../../shared/button'

function PlayToggle({ classes }) {
  return (
    <StateConsumer>
      {({ isPlaying, isLoading }) => {
        return (
          <ControlConsumer>
            {({ play, pause }) => {
              return (
                <GaConsumer>
                  {({ emitPlayDataLayerEvent }) => (
                    <ToggleButton
                      icon1={<PlayIcon />}
                      icon2={<PauseIcon />}
                      isIcon1={isPlaying && !isLoading}
                      onClick={
                        isPlaying && !isLoading
                          ? pause
                          : () => {
                            play()
                            emitPlayDataLayerEvent()
                          }
                      }
                    />
                  )}
                </GaConsumer>
              )
            }}
          </ControlConsumer>
        )
      }}
    </StateConsumer>
  )
}

export default PlayToggle
