import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import { IMAGE_MARGIN } from '../constants'

const PADDING_BOTTOM = 10
const LINE_HEIGHT = 30

const styles = {
  wrapper: {
    marginTop: 22,
    height: LINE_HEIGHT + PADDING_BOTTOM,
    width: '100%',
    overflow: 'hidden'
  },
  scrollContainer: {
    willChange: 'transform',
    overflowY: 'hidden',
    overflow: 'scroll',
    boxSizing: 'content-box',
    whiteSpace: 'nowrap',
    padding: [[0, IMAGE_MARGIN]],
    paddingBottom: PADDING_BOTTOM,
    height: 48
  }
}

function calculateOffset({
  blockWidth = 164, count, windowWidth, percentX
}) {
  const totalWidth = blockWidth * count + IMAGE_MARGIN * 2
  const maxOffset = totalWidth - windowWidth
  return maxOffset * percentX
}

class FocusTrackingCarousel extends React.PureComponent {
  constructor(props) {
    super(props)
    this.scrollContainerRef = React.createRef()

    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.scroll = this.scroll.bind(this)

    this.state = {
      offset: calculateOffset({
        count: props.count,
        windowWidth: window.innerWidth,
        percentX: props.activeIndex / props.count
      })
    }
  }

  componentDidMount() {
    this.scroll()
  }

  handleMouseMove(evt) {
    // TODO we need to observe window sizing.
    const { count } = this.props
    const windowWidth = window.innerWidth

    // calculate block width
    const childrenCount = React.Children.count(this.props.children)
    const containerWidth = this.scrollContainerRef.current.scrollWidth
    const blockWidth = containerWidth / childrenCount

    const offset = calculateOffset({
      blockWidth,
      count,
      windowWidth,
      percentX: evt.clientX / windowWidth
    })
    this.setState({ offset })
  }

  scroll() {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollLeft = this.state.offset
    }
  }

  render() {
    const { classes, children } = this.props

    this.scroll()

    return (
      <div className={classes.wrapper} onMouseMove={this.handleMouseMove}>
        <div className={classes.scrollContainer} ref={this.scrollContainerRef}>
          {children}
        </div>
      </div>
    )
  }
}

FocusTrackingCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired
}

export default withStyles(styles)(FocusTrackingCarousel)
