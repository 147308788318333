import PropTypes from 'prop-types'

export const ACCOUNT_ID = process.env.BC_ACCOUNT_ID
export const PLAYER_ID = process.env.BC_PLAYER_ID
export const POLICY_KEY = process.env.BC_PLAYER_POLICY_KEY

export const INTERVALS = {
  QUALITY_LEVEL_CHECK: 1000,
  TEXT_TRACK_EXIST_CHECK: 200,
  NEXT_EPISODE_COUNTDOWN: 1000,
  REFRESH: 500
}

export const PLAYER_RATING_DURATION = 8000
export const PLAYER_CONTROLLER_DURATION = 5000

export const PLAY_MIDROLL_TIME_OFFSET = 1
export const SKIP_MIDROLL_TIME_OFFSET = 0.1

export const LINEAR_ADS_TYPE = PropTypes.shape({
  type: PropTypes.string.isRequired,
  startTimeInContent: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  watched: PropTypes.bool.isRequired
})
