import {
  GPT_IFRAME_CONTAINER_ID,
  AD_ON_PAUSE_WIDTH,
  AD_ON_PAUSE_HEIGHT
} from './constants'

if (typeof window !== 'undefined') {
  // Ensure we can interact with the GPT command array
  window.googletag = window.googletag || { cmd: [] }

  // Prepare GPT to display ads
  window.googletag.cmd.push(() => {
    // Disable initial load, to precisely control when ads are requested
    window.googletag.pubads().disableInitialLoad()

    // Enable SRA and services.
    window.googletag.pubads().enableSingleRequest()
    window.googletag.enableServices()
  })
}

export const defineAdOnPause = (adOnPauseParams) => {
  // Register the slot with GPT when the component is loaded
  window.googletag.cmd.push(() => {
    // Loop thru the targeting key/value pairs
    adOnPauseParams.targetings.forEach(target => {
      window.googletag.pubads().setTargeting(target.key, target.value)
    })
    window.googletag
      .pubads()
      .setVideoContent(adOnPauseParams.videoContentId, adOnPauseParams.videoCmsId)
    window.googletag.pubads().setPublisherProvidedId(adOnPauseParams.ppid)

    window.googletag
      .defineSlot(adOnPauseParams.adUnitPath, [AD_ON_PAUSE_WIDTH, AD_ON_PAUSE_HEIGHT], GPT_IFRAME_CONTAINER_ID)
      .addService(window.googletag.pubads())
  })
}

export const destroyAdOnPause = () => {
  window.googletag.cmd.push(() => {
    // Destroy all slots
    window.googletag.destroySlots()
  })
}

export const clearAdOnPause = () => {
  window.googletag.cmd.push(() => {
    // Clear all slots
    window.googletag.pubads().clear()
  })
}

export const requestAdOnPause = (setIsAdOnPauseShown) => {
  window.googletag.cmd.push(() => {
    // Refresh fetches a new ad for each slot
    window.googletag.pubads().refresh()

    // Wait for slot render ended and then set state isAdOnPauseShown
    window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
      setIsAdOnPauseShown(!event.isEmpty)
    })
  })
}

// Determine responsive width and height values for an ad on pause container
export const getAoPSize = (screenWidth) => {
  const scale = screenWidth / AD_ON_PAUSE_WIDTH

  return {
    transform: `scale(${scale})`,
    transformOrigin: screenWidth < AD_ON_PAUSE_WIDTH ? 'left' : 'center'
  }
}

// Determine whether the AoP should render
export const shouldShowAoP = (adOnPauseParams, isKid) => {
  if (isKid) return false
  if (!adOnPauseParams) return false
  if (adOnPauseParams.targetings.find(param => param.key === 'iskids' && param.value === 'true')) return false

  return true
}
