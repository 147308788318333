import React from 'react'
import AuthQuery from '../../lib/auth-query'
import accountQuery from './account-query.gql'

class AccountQuery extends React.PureComponent {
  render() {
    const { children } = this.props
    return <AuthQuery query={accountQuery}>{children}</AuthQuery>
  }
}

export default AccountQuery
