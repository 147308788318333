import React from 'react'
import withStyles from 'react-jss'
import PropTypes from 'prop-types'
import color from 'color'
import { E2E_SELECTORS } from '../../../../../e2e-selectors'

const styles = theme => {
  return {
    bar: {
      position: 'absolute',
      top: 0,
      height: '100%',
      backgroundColor: color(theme.color.white)
        .alpha(0.3)
        .toString(),
      transition: [['width', 'ease-out', '300ms']]
    }
  }
}

function Buffered({ classes, percent }) {
  return (
    <div
      className={classes.bar}
      style={{
        width: `${percent * 100}%`
      }}
      data-lbx-e2e={E2E_SELECTORS.VIDEO_BUFFERED_PROGRESS}
    />
  )
}

Buffered.propTypes = {
  percent: PropTypes.number.isRequired
}

export default withStyles(styles)(Buffered)
